import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonDatetime,
  IonGrid,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import { format, addDays, subDays } from "date-fns";

import { getPatient } from "../../store/doctor/treatment/actions";

import api from "../../services/api";

import Main from "../../components/Main";
import HospitalSearch from "../../components/Legacy/Search/Hospital";

import dateToString from "../../helpers/Legacy/dateToString";
import patientsControl from "../../shared/PatientsControl";

const sessionName = process.env.REACT_APP_SESSION_NAME;

interface PatientControl {
  id: number;
  date: string;
  control: string;
  doctor_id: number;
  doctor_name: string;
  patient_id: number;
  patient_name: string;
  hospital_id: number;
  hospital_name: string;
  location: string;
  bed: string;
}

interface DispatchProps {
  getPatient(id: number): void;
}

const DoctorPatientsControl: React.FC<DispatchProps> = ({ getPatient }) => {
  const [hospital, setHospital] = useState<any>(
    JSON.parse(
      localStorage.getItem(`@${sessionName}:Hospital`) ||
        JSON.stringify({ value: "", label: "" })
    )
  );
  const [patientControl, setPatientControl] = useState<string>(
    localStorage.getItem(`@${sessionName}:PatientControl`) || "All"
  );
  const [from, setFrom] = useState<string>(
    localStorage.getItem(`@${sessionName}:From`) || ""
  );
  const [to, setTo] = useState<string>(
    localStorage.getItem(`@${sessionName}:To`) || ""
  );

  const [data, setData] = useState<PatientControl[]>([]);

  const changeHospital = (value: any) => {
    localStorage.setItem(`@${sessionName}:Hospital`, JSON.stringify(value));
    setHospital(value);
  };

  const getList = (fromDate: string, toDate: string) => {
    const hospital_id = hospital as { value: number; label: string };

    api
      .get<any>("doctor/patients_control", {
        params: {
          hospital_id: hospital_id.value,
          control: patientControl,
          from: fromDate,
          to: toDate,
        },
      })
      .then((response) => {
        setData(response.data);
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    getList(from, to);
  };

  return (
    <Main title="Control de Pacientes">
      <IonCard>
        <IonCardContent>
          <form onSubmit={handleSubmit}>
            <IonGrid>
              <IonRow>
                <HospitalSearch
                  size="12"
                  input={{
                    value: hospital,
                    onChange: (event) => changeHospital(event),
                  }}
                />
                <IonCol size="12">
                  <IonLabel
                    position="stacked"
                    style={{ color: "#000", fontWeight: 600 }}
                  >
                    Controle de Paciente
                  </IonLabel>
                  <IonSelect
                    value={patientControl}
                    onIonChange={(e) => {
                      setPatientControl(e.detail.value || "All");
                      localStorage.setItem(
                        `@${sessionName}:PatientControl`,
                        e.detail.value || "All"
                      );
                    }}
                    cancelText="Cancelar"
                    okText="Ok"
                    interface="action-sheet"
                    style={{
                      marginTop: "5px",
                      height: "42px",
                      width: "100%",
                      padding: "6px 12px",
                      color: "#555555",
                      backgroundColor: "#FFFFFF",
                      backgroundImage: "none",
                      borderColor: "hsl(0, 0%, 80%)",
                      borderRadius: "4px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      lineHeight: 2,
                    }}
                  >
                    <IonSelectOption value="All">Todos</IonSelectOption>
                    {patientsControl.map((patientControl) => (
                      <IonSelectOption
                        key={patientControl.value}
                        value={patientControl.value}
                      >
                        {patientControl.label}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonCol>
                <IonCol size="12">
                  <IonLabel
                    position="stacked"
                    style={{ color: "#000", fontWeight: 600 }}
                  >
                    De:
                  </IonLabel>
                  <IonDatetime
                    value={from}
                    onIonChange={(e) => {
                      setFrom(e.detail.value || "");
                      localStorage.setItem(
                        `@${sessionName}:From`,
                        e.detail.value || ""
                      );
                    }}
                    cancelText="Cancelar"
                    doneText="Ok"
                    displayFormat="DD/MM/YYYY"
                    min={
                      (!!to &&
                        format(subDays(new Date(to), 30), "yyyy-MM-dd")) ||
                      format(subDays(new Date(), 30), "yyyy-MM-dd")
                    }
                    max={to || format(new Date(), "yyyy-MM-dd")}
                    style={{
                      marginTop: "5px",
                      height: "42px",
                      width: "100%",
                      padding: "6px 12px",
                      color: "#555555",
                      backgroundColor: "#FFFFFF",
                      backgroundImage: "none",
                      borderColor: "hsl(0, 0%, 80%)",
                      borderRadius: "4px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      lineHeight: 2,
                    }}
                  />
                </IonCol>
                <IonCol size="12">
                  <IonLabel
                    position="stacked"
                    style={{ color: "#000", fontWeight: 600 }}
                  >
                    Até:
                  </IonLabel>
                  <IonDatetime
                    value={to}
                    onIonChange={(e) => {
                      setTo(e.detail.value || "");
                      localStorage.setItem(
                        `@${sessionName}:To`,
                        e.detail.value || ""
                      );
                    }}
                    cancelText="Cancelar"
                    doneText="Ok"
                    displayFormat="DD/MM/YYYY"
                    min={from || format(new Date(), "yyyy-MM-dd")}
                    max={
                      (!!from &&
                        format(addDays(new Date(from), 30), "yyyy-MM-dd")) ||
                      format(addDays(new Date(), 30), "yyyy-MM-dd")
                    }
                    style={{
                      marginTop: "5px",
                      height: "42px",
                      width: "100%",
                      padding: "6px 12px",
                      color: "#555555",
                      backgroundColor: "#FFFFFF",
                      backgroundImage: "none",
                      borderColor: "hsl(0, 0%, 80%)",
                      borderRadius: "4px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      lineHeight: 2,
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="6">
                  <IonButton type="submit" color="primary" expand="block">
                    Consultar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
      {data.map((values) => (
        <IonCard key={values.id}>
          <IonCardContent>
            <IonRow>
              <IonCol size="12">
                <IonLabel>Paciente: </IonLabel>
                <IonText>{values.patient_name}</IonText>
              </IonCol>
              <IonCol size="12">
                <IonLabel>Data: </IonLabel>
                <IonText>{dateToString(values.date)}</IonText>
              </IonCol>
              <IonCol size="12">
                <IonLabel>Controle de Paciente: </IonLabel>
                <IonText>{values.control}</IonText>
              </IonCol>
              <IonCol size="12">
                <IonLabel>Hospital: </IonLabel>
                <IonText>{values.hospital_name}</IonText>
              </IonCol>
              <IonCol size="12">
                <IonLabel>Localização: </IonLabel>
                <IonText>{values.location}</IonText>
              </IonCol>
              <IonCol size="12">
                <IonLabel>Leito: </IonLabel>
                <IonText>{values.bed}</IonText>
              </IonCol>
            </IonRow>
            <IonRow className="ion-margin-top">
              <IonCol size="6">
                <IonButton
                  color="primary"
                  expand="block"
                  onClick={() => getPatient(values.patient_id)}
                >
                  Prescrever
                </IonButton>
              </IonCol>
            </IonRow>
          </IonCardContent>
        </IonCard>
      ))}
    </Main>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getPatient }, dispatch);

export default connect(null, mapDispatchToProps)(DoctorPatientsControl);
