import React, { useState, useEffect } from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getList, clearList } from "../../../store/nurse/assistance/actions";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLabel,
} from "@ionic/react";

import Main from "../../../components/Main";
import HospitalSearch from "../../../components/Legacy/Search/Hospital";
import Checkbox from "../../../components/Legacy/Form/Checkbox";
import DateInput from "../../../components/Legacy/Form/DateInput";

import List from "./list";

function Assistance_Single(props) {
  const [hospital, setHospital] = useState(
    JSON.parse(localStorage.getItem("di_app_an_hospital")) || ""
  );
  const [type, setType] = useState(
    localStorage.getItem("di_app_an_type") || ""
  );
  const [myHospitals, setMyHospitals] = useState(
    JSON.parse(localStorage.getItem("di_app_an_myHospitals")) || false
  );
  const [from, setFrom] = useState(
    localStorage.getItem("di_app_an_from") || ""
  );
  const [to, setTo] = useState(localStorage.getItem("di_app_an_to") || "");

  useEffect(() => {
    const { clearList } = props;
    clearList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleConsult(e) {
    e.preventDefault();
    const { getList } = props;
    getList(hospital, myHospitals, type, from, to);
  }

  return (
    <Main title="Atendimentos">
      <IonCard>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <HospitalSearch
                input={{
                  value: hospital,
                  onChange: (value) => {
                    setHospital(value);
                    localStorage.setItem(
                      "di_app_an_hospital",
                      JSON.stringify(value)
                    );
                  },
                }}
                required
              />
              <Checkbox
                label="Meus Hospitais"
                input={{
                  value: myHospitals,
                  onChange: () => {
                    setMyHospitals(!myHospitals);
                    localStorage.setItem("di_app_an_myHospitals", !myHospitals);
                  },
                }}
              />
              <IonCol seize="12">
                <IonLabel style={{ color: "#000", fontWeight: 600 }}>
                  Tipo
                </IonLabel>
                <IonSelect
                  value={type}
                  interface="action-sheet"
                  cancelText="Cancelar"
                  onIonChange={(e) => {
                    setType(e.target.value);
                    localStorage.setItem("di_app_an_type", e.target.value);
                  }}
                  style={{
                    marginTop: "5px",
                    height: "42px",
                    width: "100%",
                    padding: "6px 12px",
                    color: "#555555",
                    backgroundColor: "#FFFFFF",
                    backgroundImage: "none",
                    borderColor: "hsl(0, 0%, 80%)",
                    borderRadius: "4px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    lineHeight: 2,
                  }}
                >
                  <IonSelectOption value="">Todos</IonSelectOption>
                  <IonSelectOption value="1">Realizado</IonSelectOption>
                  <IonSelectOption value="2">Alocado</IonSelectOption>
                  <IonSelectOption value="3">Alocar</IonSelectOption>
                </IonSelect>
              </IonCol>
              <DateInput
                label="De"
                input={{
                  value: from,
                  onChange: (value) => {
                    setFrom(value.split("T")[0]);
                    localStorage.setItem("di_app_an_from", value.split("T")[0]);
                  },
                }}
              />
              <DateInput
                label="Até"
                input={{
                  value: to,
                  onChange: (value) => {
                    setTo(value.split("T")[0]);
                    localStorage.setItem("di_app_an_to", value.split("T")[0]);
                  },
                }}
              />
            </IonRow>
          </IonGrid>
          <IonRow className="ion-margin-top">
            <IonCol size="6">
              <IonButton
                type="button"
                color="primary"
                expand="block"
                onClick={(e) => handleConsult(e)}
              >
                Consultar
              </IonButton>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>

      <List />
    </Main>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getList, clearList }, dispatch);
export default connect(null, mapDispatchToProps)(Assistance_Single);
