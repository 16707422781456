import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { useLocation } from "react-router-dom";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonText,
  IonSelectOption,
} from "@ionic/react";

import { RootState } from "../../store";
import {
  getList,
  clearList,
  update,
} from "../../store/doctor/assistance/actions";
import {
  Assistance,
  AssistanceForm,
} from "../../store/doctor/assistance/types";

import Main from "../../components/Main";
import DateInput from "../../components/Legacy/Form/DateInput";
import Select from "../../components/Legacy/Form/Select";
import dateToString from "../../helpers/Legacy/dateToString";
import If from "../../helpers/Legacy/if";

interface StateProps {
  assistances: Assistance[];
}

interface DispatchProps {
  getList(values: AssistanceForm): void;
  clearList(): void;
  update(values: Assistance): void;
}

type Props = StateProps & DispatchProps;

const Assistances: React.FC<Props & InjectedFormProps<AssistanceForm, Props>> =
  ({ handleSubmit, getList, clearList, update, assistances }) => {
    const location = useLocation();

    useEffect(() => {
      clearList();
    }, [location, clearList]);

    return (
      <Main title="Atendimentos">
        <IonCard>
          <IonCardContent>
            <form onSubmit={handleSubmit(getList)}>
              <IonGrid>
                <IonRow>
                  <Field name="from" component={DateInput} label="De" />
                  <Field name="to" component={DateInput} label="Até" />
                </IonRow>
                <IonRow className="ion-margin-top">
                  <IonCol size="6">
                    <IonButton type="submit" color="primary" expand="block">
                      Consultar
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </IonCardContent>
        </IonCard>
        {assistances.map((assistance) => {
          return (
            <IonCard
              key={assistance.id}
              className={`card-${assistance.situation?.toLowerCase()}`}
            >
              <IonCardHeader>
                <IonCardTitle>
                  Data: {dateToString(assistance.date)}
                </IonCardTitle>
                <IonCardTitle>Situação: {assistance.situation}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonRow>
                  <IonCol size="12">
                    <IonLabel>Id: </IonLabel>
                    <IonText>{assistance.id}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Hospital: </IonLabel>
                    <IonText>{assistance.hospital}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Paciente: </IonLabel>
                    <IonText>{assistance.patient}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Sitio: </IonLabel>
                    <IonText>{assistance.site}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Procedimento: </IonLabel>
                    <IonText>{assistance.proc}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Plano: </IonLabel>
                    <IonText>{assistance.agreement}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <Select
                    size="6"
                    label="Implantou Cateter?"
                    input={{
                      name: "catheter",
                      value: assistance.catheter,
                      onChange: (e: "N" | "S") =>
                        update({ ...assistance, catheter: e }),
                    }}
                  >
                    <IonSelectOption value="N">Não</IonSelectOption>
                    <IonSelectOption value="S">Sim</IonSelectOption>
                  </Select>
                  <If test={assistance.catheter === "S"}>
                    <Select
                      size="6"
                      label="Cateter Tipo"
                      input={{
                        name: "catheter_type",
                        value: assistance.catheter_type,
                        onChange: (e: "N" | "T") =>
                          update({ ...assistance, catheter_type: e }),
                      }}
                    >
                      <IonSelectOption value="N">Novo</IonSelectOption>
                      <IonSelectOption value="T">Troca</IonSelectOption>
                    </Select>
                    <Select
                      size="6"
                      label="Thenkoff"
                      input={{
                        name: "thenkoff",
                        value: assistance.thenkoff,
                        onChange: (e: "N" | "S") =>
                          update({ ...assistance, thenkoff: e }),
                      }}
                    >
                      <IonSelectOption value="N">Normal</IonSelectOption>
                      <IonSelectOption value="S">Thenkoff</IonSelectOption>
                    </Select>
                    <If test={assistance.catheter_type === "T"}>
                      <Select
                        size="6"
                        label="Motivo da Troca"
                        input={{
                          name: "catheter_reason",
                          value: assistance.catheter_reason,
                          onChange: (e: string) =>
                            update({ ...assistance, catheter_reason: e }),
                        }}
                      >
                        <IonSelectOption value="IN">Infecção</IonSelectOption>
                        <IonSelectOption value="OB">Obstrução</IonSelectOption>
                        <IonSelectOption value="SA">
                          Sangramento
                        </IonSelectOption>
                        <IonSelectOption value="BA">
                          Baixo Fluxo
                        </IonSelectOption>
                        <IonSelectOption value="RO">
                          Rotina Hospitalar
                        </IonSelectOption>
                        <IonSelectOption value="OU">Outros</IonSelectOption>
                      </Select>
                    </If>
                  </If>
                </IonRow>
                <IonRow>
                  <Select
                    size="6"
                    label="Diagnóstico"
                    input={{
                      name: "diagnosis",
                      value: assistance.diagnosis,
                      onChange: (e: string) =>
                        update({ ...assistance, diagnosis: e }),
                    }}
                  >
                    <IonSelectOption value="IRC">IRC</IonSelectOption>
                    <IonSelectOption value="IRA">IRA</IonSelectOption>
                    <IonSelectOption value="IRCA">IRC (A)</IonSelectOption>
                  </Select>
                </IonRow>
                <IonRow className="ion-margin-top">
                  <IonCol size="6">
                    <IonButton
                      type="button"
                      color="danger"
                      fill="outline"
                      expand="block"
                      onClick={() => update({ ...assistance, doctor: 0 })}
                    >
                      Não é meu
                    </IonButton>
                  </IonCol>
                  <IonCol size="6">
                    <IonButton
                      type="button"
                      color="danger"
                      expand="block"
                      onClick={() => update({ ...assistance, confirmed: "S" })}
                    >
                      Confirmar
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          );
        })}
      </Main>
    );
  };

const sessionKey = `${process.env.REACT_APP_SESSION_NAME}`;

const AssistancesForm = reduxForm<AssistanceForm, Props>({
  form: "assistances",
  initialValues: JSON.parse(
    `${localStorage.getItem(`${sessionKey}_doctor_assistances`)}`
  ),
})(Assistances);

const mapStateToProps = (state: RootState) => ({
  assistances: state.doctorAssistance.assistances,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getList, clearList, update }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AssistancesForm);
