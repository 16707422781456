import {
  Assistances,
  TechnicianAssistanceActionsTypes,
  GET_TECHNICIAN_ASSISTANCES,
  CLEAR_TECHNICIAN_ASSISTANCES,
} from "./types";

const initialState: Assistances = {
  assistances: [],
};

export function technicianAssistanceReducer(
  state = initialState,
  action: TechnicianAssistanceActionsTypes
): Assistances {
  switch (action.type) {
    case GET_TECHNICIAN_ASSISTANCES:
      return {
        ...state,
        assistances: action.payload,
      };
    case CLEAR_TECHNICIAN_ASSISTANCES:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
