interface PatientControl {
  value: number | string;
  label: string;
}

const patientsControl: PatientControl[] = [
  { value: "Conservador", label: "Conservador" },
  { value: "Em Avaliação", label: "Em Avaliação" },
];

export default patientsControl;
