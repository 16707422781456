import { Catheter } from "./types";
import { ThunkDispatch } from "redux-thunk";
import { initialize } from "redux-form";
import { showToast } from "../../../store/toast/actions";
import { ToastActionsTypes } from "../../../store/toast/types";
import ErrorHandler from "../../../helpers/Legacy/errorsHandler";
import catheters_schema from "../../../schemas/Legacy/catheters_schema";
import api from "../../../services/api";

export function create(values: Catheter) {
  return (dispatch: ThunkDispatch<{}, {}, ToastActionsTypes>) => {
    catheters_schema
      .validate(values)
      .then(async () => {
        await api
          .post("doctor/catheter", values)
          .then((response) => {
            dispatch(showToast("Dados salvos com sucesso", "success"));
            dispatch(init());
          })
          .catch((error) => {
            dispatch(ErrorHandler(error));
          });
      })
      .catch((err) => {
        dispatch(showToast(`${err.errors}`, "warning"));
      });
  };
}

function init() {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(initialize("catheter", {}));
  };
}
