import React from "react";
import { IonCol, IonItem, IonLabel, IonCheckbox } from "@ionic/react";

interface Props {
  input: {
    name: string;
    value: boolean;
    onChange(e: boolean): void;
  };
  label: string;
  readonly?: boolean;
  size?: string;
}

const Input: React.FC<Props> = (props) => {
  return (
    <IonCol size={props.size || "12"}>
      <IonItem lines="none">
        <IonCheckbox
          slot="start"
          name={props.input.name}
          checked={props.input.value ? true : false}
          disabled={props.readonly ? true : false}
          onIonChange={(e) => props.input.onChange(e.detail.checked)}
        />
        <IonLabel style={{ color: "#000", fontWeight: 600 }}>
          {props.label}
        </IonLabel>
      </IonItem>
    </IonCol>
  );
};

export default Input;
