export interface Prescription {
  id: number;
  situation?: string;
  patient:
    | string
    | {
        value: number;
        label: string;
      };
  date: string;
  new_patient: "S" | "N";
  hospital:
    | string
    | {
        value: number;
        label: string;
      };
  location: string;
  bed: string;
  proc: string;
  time: number;
  access: string;
  site: string;
  catheter: "S" | "N";
  catheter_type: "T" | "N";
  thenkoff: "S" | "N";
  catheter_reason: string;
  precaution: boolean;
  index: string;
  catheter_time: number;
  sessions: number;
  medical_report?: string;

  fluxo_dialisato?: number;
  fluxo_de_reposicao?: number;
  peso?: number;
  dose_dialise_prescrita?: number;
  covid?: "Y" | "N";
  patient_control?: string;
  mode?: string;
  kit?: string;
  capillary_type?: string;
  line_type?: string;
  anticoagulation?: string;
  initial_citrate?: string;
  initial_calcium?: string;
}

export interface Prescriptions {
  prescriptions: Prescription[];
  page: 1 | 2;
}

export const GET_DOCTOR_PRESCRIPTIONS = "GET_DOCTOR_PRESCRIPTIONS";
export const SET_DOCTOR_PRESCRIPTION_PAGE = "SET_DOCTOR_PRESCRIPTION_PAGE";

interface GetDoctorPrescriptionsActions {
  type: typeof GET_DOCTOR_PRESCRIPTIONS;
  payload: Prescription[];
}

interface SetDoctorPrescriptionPageActions {
  type: typeof SET_DOCTOR_PRESCRIPTION_PAGE;
  payload: Prescriptions["page"];
}

export type DoctorPrescriptionActionsTypes =
  | GetDoctorPrescriptionsActions
  | SetDoctorPrescriptionPageActions;
