import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonButton,
  isPlatform,
  IonItem,
} from "@ionic/react";
import { ScreenOrientation } from "@ionic-native/screen-orientation";

import { useAuth } from "../hooks/auth";
import { useToast } from "../hooks/toast";
import { useValidationErrors } from "../hooks/errors";
import api from "../services/api";

import Main from "../components/Main";

interface PasswordChangeResponseData {
  reset: boolean;
}

const Password: React.FC = () => {
  const { user, passwordChange } = useAuth();
  const { addToast } = useToast();
  const { getValidationErrors } = useValidationErrors();

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  useEffect(() => {
    const platform = isPlatform("mobileweb") || isPlatform("desktop");
    if (!platform) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password !== passwordConfirm) {
      addToast({ message: "As senhas não são iguais.", color: "warning" });
    } else {
      await api
        .put<PasswordChangeResponseData>(`${user.profile}/password`, {
          password: password,
          password_confirm: passwordConfirm,
        })
        .then((response) => {
          if (!!response.data) {
            passwordChange(response.data.reset);
          }
          addToast({ message: "Senha alterada.", color: "success" });
          setPassword("");
          setPasswordConfirm("");
        })
        .catch((error) => {
          getValidationErrors(error.response);
        });
    }
  };
  return (
    <Main title="Alterar Senha">
      <IonCard>
        <IonCardContent>
          <form onSubmit={handleSubmit}>
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonItem mode="md" lines="none">
                    <IonLabel position="stacked" style={{ fontSize: "20px" }}>
                      Senha:
                    </IonLabel>
                    <IonInput
                      type="password"
                      value={password}
                      onIonChange={(e) => setPassword(e.detail.value || "")}
                      autofocus
                      autocomplete="off"
                      autocapitalize="off"
                      autocorrect="off"
                    />
                  </IonItem>
                </IonCol>
                <IonCol size="12">
                  <IonItem mode="md" lines="none">
                    <IonLabel position="stacked" style={{ fontSize: "20px" }}>
                      Confirmar Senha:
                    </IonLabel>
                    <IonInput
                      type="password"
                      value={passwordConfirm}
                      onIonChange={(e) =>
                        setPasswordConfirm(e.detail.value || "")
                      }
                      autocomplete="off"
                      autocapitalize="off"
                      autocorrect="off"
                    />
                  </IonItem>
                </IonCol>
                <IonCol size="12" style={{ marginTop: "30px" }}>
                  <IonButton type="submit" color="primary" expand="block">
                    Alterar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
    </Main>
  );
};

export default Password;
