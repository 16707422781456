import {
  ProgressList,
  ProgressActionsTypes,
  GET_ADMINISTRATIVE_PROGRESS,
  CLEAR_ADMINISTRATIVE_PROGRESS,
} from "./types";

const initialState: ProgressList = {
  list: [],
};

export function administrativeProgressReducer(
  state = initialState,
  action: ProgressActionsTypes
): ProgressList {
  switch (action.type) {
    case GET_ADMINISTRATIVE_PROGRESS:
      return {
        ...state,
        list: action.payload,
      };
    case CLEAR_ADMINISTRATIVE_PROGRESS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
