import React, { createContext, useContext, useCallback } from "react";
import { ValidationError } from "yup";

import { useAuth } from "./auth";
import { useToast } from "./toast";

interface ValidationErrorsContextData {
  getValidationErrors(error: any): void;
}

const ValidationErrorsContext = createContext<ValidationErrorsContextData>(
  {} as ValidationErrorsContextData
);

const ValidationErrorsProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const { signOut } = useAuth();

  const getValidationErrors = useCallback(
    (error: any) => {
      if (!error) {
        addToast({ message: "Falha ao estabelecer conexão", color: "danger" });
      } else if (error instanceof ValidationError) {
        addToast({ message: error.errors[0], color: "warning" });
      } else if (error?.data?.error) {
        addToast({ message: error.data.error, color: "danger" });
      } else if (error?.data?.validator) {
        addToast({ message: error.data.validator, color: "warning" });
      }

      if (typeof error?.data?.valid === "boolean") {
        if (!error.data.valid) {
          signOut();
        }
      }
    },
    [addToast, signOut]
  );

  return (
    <ValidationErrorsContext.Provider value={{ getValidationErrors }}>
      {children}
    </ValidationErrorsContext.Provider>
  );
};

function useValidationErrors(): ValidationErrorsContextData {
  const context = useContext(ValidationErrorsContext);

  if (!context) {
    throw new Error(
      "useValidationErrors must be used within a ValidationErrorsProvider"
    );
  }

  return context;
}

export {
  ValidationErrorsContext,
  ValidationErrorsProvider,
  useValidationErrors,
};
