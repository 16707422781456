import React from "react";
import { IonCol, IonLabel, IonSelect } from "@ionic/react";

interface Props {
  input: {
    name: string;
    value: string;
    onChange(e: string | null | undefined): void;
  };
  label: string;
  placeholder?: string;
  selectedText?: string;
  size?: string;
  readOnly?: boolean;
}

const Select: React.FC<Props> = (props) => {
  return (
    <IonCol size={props.size || "12"}>
      <IonLabel position="stacked" style={{ color: "#000", fontWeight: 600 }}>
        {props.label}
      </IonLabel>
      <IonSelect
        name={props.input.name}
        value={props.input.value}
        onIonChange={(e) => props.input.onChange(e.detail.value)}
        cancelText="Cancelar"
        okText="Ok"
        placeholder={props.placeholder}
        selectedText={props.selectedText}
        disabled={props.readOnly ? true : false}
        interface="action-sheet"
        style={{
          marginTop: "5px",
          height: "42px",
          width: "100%",
          padding: "6px 12px",
          color: "#555555",
          backgroundColor: "#FFFFFF",
          backgroundImage: "none",
          borderColor: "hsl(0, 0%, 80%)",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "1px",
          lineHeight: 2,
        }}
      >
        {props.children}
      </IonSelect>
    </IonCol>
  );
};

export default Select;
