export interface MachineOrder {
  id: number;
  machine?: {
    value: number;
    label: string;
  };
  number: number;
  urgency: number;
  type: "H" | "O" | "T";
  status: "A" | "M" | "S";
  date: string;
  tried: "S" | "N";
  how_tried: string;
  fault: string;
  cause: string;
}

export interface MachineOrders {
  list: MachineOrder[];
}

export const GET_MACHINE_ORDERS = "GET_MACHINE_ORDERS";

interface GetMachineOrdersActions {
  type: typeof GET_MACHINE_ORDERS;
  payload: MachineOrder[];
}

export type MachineOrderActionsTypes = GetMachineOrdersActions;
