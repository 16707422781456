import api from "../../../services/api";
import ErrorHandler from "../../../helpers/Legacy/errorsHandler";

export function getList(hospital, my_hospitals, type, from, to) {
  return async (dispatch) => {
    await api
      .get("assistance_scheduled", { params: { from, to } })
      .then((response) => {
        dispatch({
          type: "ASSISTANCE_SCHEDULED_FETCHING",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function clearList() {
  return (dispatch) => {
    dispatch({ type: "ASSISTANCE_SCHEDULED_CLEAR", payload: false });
  };
}
