import React from "react";
import { IonToast } from "@ionic/react";
import { ToastMessage, useToast } from "../hooks/toast";

interface ToastProps {
  messages: ToastMessage[];
}

const Toast: React.FC<ToastProps> = ({ messages }) => {
  const { removeToast } = useToast();
  return (
    <>
      {messages.map((message) => (
        <IonToast
          key={message.id}
          isOpen={true}
          onDidDismiss={() => removeToast(message.id)}
          message={message.message}
          color={message.color}
          duration={3000}
          position="bottom"
          buttons={[
            {
              text: "Ok",
              role: "cancel",
            },
          ]}
        />
      ))}
    </>
  );
};

export default Toast;
