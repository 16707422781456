import React, { useRef, useEffect } from "react";
import {
  IonButtons,
  IonBackButton,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonIcon,
  IonBadge,
  useIonViewDidEnter,
  isPlatform,
} from "@ionic/react";
import { calendar, time } from "ionicons/icons";

import { useAuth } from "../hooks/auth";
import { useNotifications } from "../hooks/notifications";

interface Props {
  title: string;
  scroll?: unknown;
  back?: boolean;
  end?: any;
  footer?: any;
  children: any;
}

const Main: React.FC<Props> = ({
  title,
  scroll,
  back,
  end,
  footer,
  children,
}) => {
  const { user, appUpdated } = useAuth();
  const { inProgress, scheduled } = useNotifications();
  const contentRef = useRef<HTMLIonContentElement>(null);

  useEffect(() => {
    contentRef?.current?.scrollToTop(500);
  }, [scroll]);

  useIonViewDidEnter(() => {
    document.addEventListener("ionBackButton", (ev: any) => {
      ev.detail.register(10, () => {});
    });
  });

  return (
    <IonPage>
      <IonHeader mode="md">
        {!appUpdated && (
          <div
            style={{
              background: "var(--ion-color-danger)",
              color: "#fff",
              padding: `${isPlatform("ios") ? "40px" : "8px"} 8px 8px 8px`,
              fontWeight: 600,
            }}
          >
            Atualização Disponível
          </div>
        )}
        <IonToolbar mode="md">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButtons slot="end">
            {user?.profile === "technician" && (
              <>
                <IonItem
                  mode="md"
                  lines="none"
                  routerLink="/technician/scheduled_assists"
                >
                  <IonIcon
                    size="large"
                    slot="start"
                    color="light"
                    icon={calendar}
                  />
                  <IonBadge mode="md" color="secondary">
                    {scheduled}
                  </IonBadge>
                </IonItem>
                <IonItem
                  mode="md"
                  lines="none"
                  routerLink="/technician/assists_in_progress"
                >
                  <IonIcon
                    size="large"
                    slot="start"
                    color="light"
                    icon={time}
                  />
                  <IonBadge mode="md" color="warning">
                    {inProgress}
                  </IonBadge>
                </IonItem>
              </>
            )}
            {back && <IonBackButton />}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonToolbar mode="md">
        <IonTitle>{title}</IonTitle>
        <IonButtons slot="end">{end}</IonButtons>
      </IonToolbar>

      <IonContent scrollEvents={false} ref={contentRef} id="content">
        {children}
      </IonContent>

      {footer}
    </IonPage>
  );
};

export default Main;
