import {
  Assistances,
  Assistance,
  AssistanceActionsTypes,
  GET_ASSISTANCES,
  CLEAR_ASSISTANCES,
  UPDATE_ASSISTANCE,
  DELETE_ASSISTANCE
} from './types'

const initialState: Assistances = {
  assistances: []
}

export function doctorAssistanceReducer(
  state = initialState,
  action: AssistanceActionsTypes
): Assistances {
  switch (action.type) {
    case GET_ASSISTANCES:
      return {
        ...state,
        assistances: action.payload
      }
    case CLEAR_ASSISTANCES:
      return {
        ...state,
        ...initialState
      }
    case UPDATE_ASSISTANCE:
      return {
        ...state,
        assistances: state.assistances.map((assistance: Assistance) =>
          assistance.id === action.payload.id ? action.payload : assistance
        )
      }
    case DELETE_ASSISTANCE:
      return {
        ...state,
        assistances: state.assistances.filter(
          (assistance: Assistance) => assistance.id !== action.payload.id
        )
      }
    default:
      return state
  }
}
