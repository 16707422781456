import React, { useState } from "react";
import { IonIcon } from "@ionic/react";
import { refresh } from "ionicons/icons";
import {
  IonCard,
  IonCardContent,
  IonText,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonButton,
} from "@ionic/react";

import { useToast } from "../../../hooks/toast";
import { useValidationErrors } from "../../../hooks/errors";
import api from "../../../services/api";

import If from "../../../helpers/Legacy/if";
import dateToString from "../../../helpers/Legacy/dateToString";
import Input from "../../../components/Legacy/Form/Input";
import TimeInput from "../../../components/Legacy/Form/TimeInput";
import TechnicianSearch from "../../../components/Legacy/Search/TechnicianOld";
import destinations from "../../../shared/Destinations";

function AssistanceNursingCard(props) {
  const { successToast } = useToast();
  const { getValidationErrors } = useValidationErrors();
  const { update } = props;
  const { d = [] } = props;

  const [local, setLocal] = useState(d.quarto || "CTI");
  const [situation, setSituation] = useState(d.situation || "");
  const [bed, setBed] = useState(d.bed || "");
  const [technician1, setTechnician1] = useState(
    d.technician1 ? { value: d.technician1, label: d.technician1_name } : ""
  );
  const [technician2, setTechnician2] = useState(
    d.technician2 ? { value: d.technician2, label: d.technician2_name } : ""
  );
  const [technician3, setTechnician3] = useState(
    d.technician3 ? { value: d.technician3, label: d.technician3_name } : ""
  );

  const [tec1start, setTec1start] = useState(d.tec1start || "00:00:00");
  const [tec2start, setTec2start] = useState(d.tec2start || "00:00:00");
  const [tec3start, setTec3start] = useState(d.tec3start || "00:00:00");

  const [simultaneo, setSimultaneo] = useState(
    d.id_simultaneo ? d.id_simultaneo : ""
  );
  const [simultaneous, setSimultaneous] = useState([]);

  const [destination, setDestination] = useState(d.destination || "");

  function handleLocal(value) {
    setLocal(value);
    update(d.id, { local: value });
  }

  function handleBed(value) {
    setBed(value);
    update(d.id, { bed: value });
  }

  function handleTechnician1(value) {
    api
      .put(`assistance_nursing/${d.id}`, { technician: value.value })
      .then((resp) => {
        setTechnician1(value);
        setTec1start("00:00:00");
        setSituation(resp.data.situation);
        if (tec1start !== null) {
          setSituation("AGENDADO");
        }
      });
  }

  function handleTechnician2(value) {
    api
      .put(`assistance_nursing/${d.id}`, { technician2: value.value })
      .then((resp) => {
        setTechnician2(value);
        setTec2start("00:00:00");
        setSituation(resp.data.situation);
      });
  }

  function handleTechnician3(value) {
    api
      .put(`assistance_nursing/${d.id}`, { technician3: value.value })
      .then((resp) => {
        setTechnician3(value);
        setTec3start("00:00:00");
        setSituation(resp.data.situation);
      });
  }

  function handleStartTime1(value) {
    api
      .put(`assistance_nursing/${d.id}`, { start: value, tec1start: value })
      .then((resp) => {
        setTec1start(value);
        if (resp.data.situation === "AGENDADO") {
          setSituation("AGENDADO");
        }
      })
      .catch((err) => {});
  }

  function handleStartTime2(value) {
    api
      .put(`assistance_nursing/${d.id}`, { tec2start: value })
      .then((resp) => {
        setTec2start(value);
        if (resp.data.situation === "AGENDADO") {
          setSituation("AGENDADO");
        }
      })
      .catch((err) => {});
  }

  function handleStartTime3(value) {
    api.put(`assistance_nursing/${d.id}`, { tec3start: value }).then((resp) => {
      setTec3start(value);
      if (resp.data.situation === "AGENDADO") {
        setSituation("AGENDADO");
      }
    });
  }

  function handleSimultaneous(value) {
    update(d.id, { simultaneous: value });
    setSimultaneo(value);
  }

  async function loadSimultaneous(hospital, date, tec, id) {
    const request = await api.get(`assistance_nursing/simultaneous`, {
      params: { hospital, date, tec, id },
    });

    setSimultaneous(request.data || []);
  }

  async function changeDestination(newDestination) {
    await api
      .put(`shared/destination/${d.id}`, { destination: newDestination })
      .then((response) => {
        if (!!response.data?.destination) {
          setDestination(response.data.destination);
        }
        successToast();
      })
      .catch((error) => {
        getValidationErrors(error.response);
      });
    setDestination(newDestination);
  }

  function renderSimultaneous() {
    return simultaneous.map((d) => (
      <IonSelectOption value={d.id} key={d.id}>
        {d.id} - {d.name}
      </IonSelectOption>
    ));
  }

  function handleSituation(situation) {
    api.put(`assistance_nursing/${d.id}`, { situation }).then((resp) => {
      setSituation(resp.data.situation);
      if (resp.data.situation === "PENDENTE") {
        setTechnician1(resp.data.technician1 ? resp.data.technician1 : "");
        setTechnician2(resp.data.technician2 ? resp.data.technician2 : "");
        setTechnician3(resp.data.technician3 ? resp.data.technician3 : "");
        setTec1start(resp.data.tec1start);
        setTec2start(resp.data.tec2start);
        setTec3start(resp.data.tec3start);
        setSimultaneo(resp.data.id_simultaneo);
      }
    });
  }

  return (
    <IonCard
      className={`${
        technician1 === "" ? "card-danger" : `card-${situation.toLowerCase()}`
      } ${
        d.urgent && (situation === "PENDENTE" || situation === "AGENDADO")
          ? "urgent"
          : ""
      }`}
    >
      <IonCardContent>
        <IonRow>
          <IonCol size="12">
            <IonLabel>Data: </IonLabel>
            <IonText>{dateToString(d.dias)}</IonText>
          </IonCol>
          {situation === "REALIZADO" && (
            <>
              <IonCol size="12">
                <IonLabel>Hora de Início: </IonLabel>
                <IonText>{d.start}</IonText>
              </IonCol>
              <IonCol size="12">
                <IonLabel>Hora de Término: </IonLabel>
                <IonText>{d.end}</IonText>
              </IonCol>
            </>
          )}
          <IonCol size="12">
            <IonLabel>Id: </IonLabel>
            <IonText>{d.id}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Proc: </IonLabel>
            <IonText>{d.proc}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Situação: </IonLabel>
            <IonText>{situation}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Hospital: </IonLabel>
            <IonText>{d.hospital}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Paciente: </IonLabel>
            <IonText>{d.patient}</IonText>
          </IonCol>
        </IonRow>
        {situation !== "REALIZADO" ? (
          <IonRow>
            <IonCol size="12">
              <IonLabel>Localização:</IonLabel>
              <IonSelect
                placeholder="Selecione..."
                interface="action-sheet"
                cancelText="Cancelar"
                value={local}
                onIonChange={(e) => handleLocal(e.target.value)}
                style={{
                  marginTop: "5px",
                  height: "42px",
                  width: "100%",
                  padding: "6px 12px",
                  color: "#555555",
                  backgroundColor: "#FFFFFF",
                  backgroundImage: "none",
                  borderColor: "hsl(0, 0%, 80%)",
                  borderRadius: "4px",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  lineHeight: 2,
                }}
              >
                <IonSelectOption value="CTI">CTI</IonSelectOption>
                <IonSelectOption value="ENFERMARIA">ENFERMARIA</IonSelectOption>
                <IonSelectOption value="QUARTO">QUARTO</IonSelectOption>
                <IonSelectOption value="POI">POI</IonSelectOption>
                <IonSelectOption value="PÓS">PÓS</IonSelectOption>
                <IonSelectOption value="UCO">UCO</IonSelectOption>
                <IonSelectOption value="UCI">UCI</IonSelectOption>
                <IonSelectOption value="UCIC">UCIC</IonSelectOption>
                <IonSelectOption value="OUTROS">OUTROS</IonSelectOption>
              </IonSelect>
            </IonCol>

            <Input
              label="Leito:"
              input={{ value: bed, onChange: (value) => handleBed(value) }}
            />
          </IonRow>
        ) : (
          <IonRow>
            <IonCol size="12">
              <IonLabel>Localização: </IonLabel>
              <IonText>{local}</IonText>
            </IonCol>
            <IonCol size="12">
              <IonLabel>Leito: </IonLabel>
              <IonText>{bed}</IonText>
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          {situation !== "REALIZADO" ? (
            <TechnicianSearch
              label="Técnico 1:"
              input={{
                value: technician1,
                onChange: (value) => handleTechnician1(value),
              }}
              required
            />
          ) : (
            !!technician1 && (
              <IonCol size="12">
                <IonLabel>Técnico 1: </IonLabel>
                <IonText>{d.technician1_name}</IonText>
              </IonCol>
            )
          )}
          <If test={technician1 !== ""}>
            {situation !== "REALIZADO" ? (
              <TimeInput
                size="12"
                label="Hora de Início do Técnico 1: "
                input={{
                  value: tec1start,
                  onChange: (value) => handleStartTime1(value),
                }}
                required
              />
            ) : (
              <>
                <IonCol size="12">
                  <IonLabel>Hora de Início do Técnico 1: </IonLabel>
                  <IonText>{d.tec1start}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Hora de Término do Técnico 1: </IonLabel>
                  <IonText>{d.tec1end}</IonText>
                </IonCol>
              </>
            )}
          </If>
          <If test={technician1 !== ""}>
            {situation !== "REALIZADO" ? (
              <TechnicianSearch
                label="Técnico 2:"
                input={{
                  value: technician2,
                  onChange: (value) => handleTechnician2(value),
                }}
                required
              />
            ) : (
              !!technician2 && (
                <IonCol size="12">
                  <IonLabel>Técnico 2: </IonLabel>
                  <IonText>{d.technician2_name}</IonText>
                </IonCol>
              )
            )}
            <If test={technician2 !== ""}>
              {situation !== "REALIZADO" ? (
                <TimeInput
                  size="12"
                  label="Hora de Início do Técnico 2: "
                  input={{
                    value: tec2start,
                    onChange: (value) => handleStartTime2(value),
                  }}
                  required
                />
              ) : (
                <>
                  <IonCol size="12">
                    <IonLabel>Hora de Início do Técnico 2: </IonLabel>
                    <IonText>{d.tec2start}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Hora de Término do Técnico 2: </IonLabel>
                    <IonText>{d.tec2end}</IonText>
                  </IonCol>
                </>
              )}
            </If>
          </If>
          <If test={technician2 !== ""}>
            {situation !== "REALIZADO" ? (
              <TechnicianSearch
                label="Técnico 3:"
                input={{
                  value: technician3,
                  onChange: (value) => handleTechnician3(value),
                }}
                required
              />
            ) : (
              !!technician3 && (
                <IonCol size="12">
                  <IonLabel>Técnico 3: </IonLabel>
                  <IonText>{d.technician3_name}</IonText>
                </IonCol>
              )
            )}
            <If test={technician3 !== ""}>
              {situation !== "REALIZADO" ? (
                <TimeInput
                  size="12"
                  label="Hora de Início do Técnico 3: "
                  input={{
                    value: tec3start,
                    onChange: (value) => handleStartTime3(value),
                  }}
                  required
                />
              ) : (
                <>
                  <IonCol size="12">
                    <IonLabel>Hora de Início do Técnico 3: </IonLabel>
                    <IonText>{d.tec3start}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Hora de Término do Técnico 3: </IonLabel>
                    <IonText>{d.tec3end}</IonText>
                  </IonCol>
                </>
              )}
            </If>
          </If>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            {!technician1?.value ? (
              <>
                <IonLabel>Simultâneo</IonLabel>
                <p>Atribua um técnico primeiro!</p>
              </>
            ) : situation === "REALIZADO" ? (
              <>
                <IonLabel>Simultâneo</IonLabel>
                {d.id_simultaneo ? (
                  <p>
                    {d.id_simultaneo} - {d.patient_simultaneo}
                  </p>
                ) : (
                  <p>Não houve!</p>
                )}
              </>
            ) : (
              <>
                <IonLabel>Simultâneo</IonLabel>
                <IonButton
                  color="secondary"
                  type="button"
                  size="small"
                  onClick={() =>
                    loadSimultaneous(d.id_hospital, d.dias, d.tec, d.id)
                  }
                >
                  <IonIcon icon={refresh} size="small" />
                </IonButton>
                <IonSelect
                  placeholder="Selecione..."
                  interface="action-sheet"
                  cancelText="Cancelar"
                  value={simultaneo}
                  onIonChange={(e) => handleSimultaneous(e.target.value)}
                  style={{
                    marginTop: "5px",
                    height: "42px",
                    width: "100%",
                    padding: "6px 12px",
                    color: "#555555",
                    backgroundColor: "#FFFFFF",
                    backgroundImage: "none",
                    borderColor: "hsl(0, 0%, 80%)",
                    borderRadius: "4px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    lineHeight: 2,
                  }}
                >
                  {renderSimultaneous()}
                </IonSelect>
              </>
            )}
          </IonCol>
          <IonCol size="12">
            <IonLabel>Destino</IonLabel>
            <IonSelect
              value={destination}
              onIonChange={(event) => {
                changeDestination(event.detail.value);
              }}
              cancelText="Cancelar"
              okText="Ok"
              interface="action-sheet"
              style={{
                marginTop: "5px",
                height: "42px",
                width: "100%",
                padding: "6px 12px",
                color: "#555555",
                backgroundColor: "#FFFFFF",
                backgroundImage: "none",
                borderColor: "hsl(0, 0%, 80%)",
                borderRadius: "4px",
                borderStyle: "solid",
                borderWidth: "1px",
                lineHeight: 2,
              }}
            >
              {destinations.map((dest) => (
                <IonSelectOption key={dest.value} value={dest.value}>
                  {dest.label}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonCol>
        </IonRow>
        <IonRow className="ion-margin-top">
          <If
            test={
              situation !== "ANDAMENTO" &&
              situation !== "REALIZADO" &&
              situation !== "SEMRH"
            }
          >
            <IonCol size="12">
              <IonButton
                type="button"
                color="danger"
                expand="block"
                onClick={() => handleSituation("SEMRH")}
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                COLOCAR COMO SEM RH
              </IonButton>
            </IonCol>
          </If>
          {situation !== "PENDENTE" &&
            situation !== "REALIZADO" &&
            situation !== "ANDAMENTO" && (
              <IonCol size="12">
                <IonButton
                  type="button"
                  color="primary"
                  expand="block"
                  onClick={() => handleSituation("PENDENTE")}
                  style={{
                    whiteSpace: "pre-line",
                  }}
                >
                  COLOCAR COMO PENDENTE
                </IonButton>
              </IonCol>
            )}
          {situation !== "CANCELADO" &&
            situation !== "REALIZADO" &&
            situation !== "SUSPENSO" && (
              <IonCol size="12">
                <IonButton
                  type="button"
                  color="danger"
                  expand="block"
                  onClick={() => handleSituation("CANCELADO")}
                >
                  CANCELAR
                </IonButton>
              </IonCol>
            )}
        </IonRow>
        <IonRow className="ion-margin-top">
          <IonCol size="6">
            <IonButton
              type="button"
              routerLink={`/prescription/${d.id}`}
              color="primary"
              expand="block"
            >
              Prescrição
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow className="ion-margin-top">
          <If test={d.situation === "REALIZADO" || d.situation === "ANDAMENTO"}>
            <If test={d.annotations && d.annotations > 0 ? true : false}>
              <IonCol size="6">
                <IonButton
                  type="button"
                  routerLink={`/assists_annotations/${d.id}/${d.proc}`}
                  color="secondary"
                  expand="block"
                >
                  Anotações
                </IonButton>
              </IonCol>
            </If>
            <If test={d.evolution && d.evolution > 0 ? true : false}>
              <IonCol size="6">
                <IonButton
                  type="button"
                  routerLink={`/assists_evolutions/${d.id}`}
                  color="medium"
                  expand="block"
                >
                  Evolução
                </IonButton>
              </IonCol>
            </If>
          </If>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
}

export default AssistanceNursingCard;
