import React, { useEffect, useRef } from "react";
import {
  IonApp,
  IonPage,
  IonHeader,
  IonImg,
  IonContent,
  IonCardContent,
  IonRow,
  IonGrid,
  IonCol,
  IonSelectOption,
  IonButton,
  isPlatform,
} from "@ionic/react";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { useAuth, UserData } from "../hooks/auth";
import { useToast } from "../hooks/toast";
import { useValidationErrors } from "../hooks/errors";

import api from "../services/api";

import LogoImg from "../images/logo.png";

import Card from "../components/Card";
import Input from "../components/Form/Input";
import Select from "../components/Form/Select";

const sessionName = process.env.REACT_APP_SESSION_NAME;

interface SignInFormData {
  username: string;
  password: string;
  profile: string;
}

interface SignInResponseData {
  user: UserData;
  token: string;
}

const Login: React.FC = () => {
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const { getValidationErrors } = useValidationErrors();

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    const platform = isPlatform("mobileweb") || isPlatform("desktop");
    if (!platform) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }
  }, []);

  useEffect(() => {
    formRef?.current?.setData({
      username: localStorage.getItem(`@${sessionName}:username`) || "",
      profile: localStorage.getItem(`@${sessionName}:profile`) || "",
    });
  }, [formRef]);

  const handleSubmit: SubmitHandler = async (values: SignInFormData) => {
    const { username, password, profile } = values;

    if (!profile) {
      addToast({ message: "O campo perfil é obrigatório.", color: "warning" });
    } else {
      localStorage.setItem(`@${sessionName}:username`, username);
      localStorage.setItem(`@${sessionName}:profile`, profile);

      await api
        .post<SignInResponseData>(`login/${profile}`, {
          username,
          password,
        })
        .then((response) => {
          const { user, token } = response.data;
          signIn(user, token);
        })
        .catch((error) => {
          getValidationErrors(error.response);
        });
    }
  };

  return (
    <IonApp style={isPlatform("ios") ? { marginTop: "35px" } : {}}>
      <IonPage>
        <IonHeader
          mode="ios"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10%",
          }}
        >
          <IonImg
            src={LogoImg}
            alt="Logo"
            style={{ width: "60%", height: "auto" }}
          />
        </IonHeader>
        <IonContent scrollEvents={true}>
          <Card>
            <IonCardContent>
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={{ username: "dasdas" }}
              >
                <IonGrid>
                  <IonRow>
                    <Input
                      label="Usuário"
                      name="username"
                      type="text"
                      autoFocus
                      autoComplete="username"
                      autoCapitalize="off"
                      autoCorrect="off"
                    />
                    <Input
                      label="Senha"
                      name="password"
                      type="password"
                      autoComplete="off"
                      autoCapitalize="off"
                      autoCorrect="off"
                    />
                    <Select
                      label="Perfil"
                      name="profile"
                      style={{ marginTop: "30px" }}
                    >
                      <IonSelectOption value="doctor">Médico</IonSelectOption>
                      <IonSelectOption value="nurse">
                        Enfermagem
                      </IonSelectOption>
                      <IonSelectOption value="technician">
                        Técnico
                      </IonSelectOption>
                      <IonSelectOption value="administrative">
                        Administrativo
                      </IonSelectOption>
                    </Select>
                    <IonCol size="12" style={{ marginTop: "30px" }}>
                      <IonButton type="submit" color="primary" expand="block">
                        Entrar
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </Form>
            </IonCardContent>
          </Card>
        </IonContent>
      </IonPage>
    </IonApp>
  );
};

export default Login;
