import React, { useEffect, useRef } from "react";
import { IonCol, IonInput } from "@ionic/react";
import { AutocompleteTypes, TextFieldTypes } from "@ionic/core";
import { useField } from "@unform/core";

import FormLabel from "./FormLabel";

interface Props {
  size?: string;
  label: string;
  name: string;
  type?: TextFieldTypes;
  autoCapitalize?: string;
  autoComplete?: AutocompleteTypes;
  autoCorrect?: "on" | "off";
  autoFocus?: boolean;
  clearInput?: boolean;
  clearOnEdit?: boolean;
  inputMode?:
    | "search"
    | "text"
    | "email"
    | "tel"
    | "url"
    | "decimal"
    | "none"
    | "numeric";
  max?: string;
  maxLength?: number;
  min?: string;
  minLength?: number;
  step?: string;
  readOnly?: boolean;
  spellCheck?: boolean;
}

const Input: React.FC<Props> = ({
  size,
  label,
  name,
  type,
  autoCapitalize,
  autoComplete,
  autoCorrect,
  autoFocus,
  clearInput,
  clearOnEdit,
  inputMode,
  max,
  maxLength,
  min,
  minLength,
  step,
  readOnly,
  spellCheck,
  ...rest
}) => {
  const inputRef = useRef<HTMLIonInputElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        if (ref.current.value !== "" && ref.current.value !== null) {
          return ref.current.value;
        }
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <IonCol size={size || "12"}>
      <FormLabel>{label}</FormLabel>
      <IonInput
        mode="md"
        ref={inputRef}
        defaultValue={defaultValue}
        name={name}
        type={type}
        autocapitalize={autoCapitalize}
        autocomplete={autoComplete}
        autocorrect={autoCorrect}
        autofocus={autoFocus}
        clearInput={clearInput}
        clearOnEdit={clearOnEdit}
        inputmode={inputMode}
        max={max}
        maxlength={maxLength}
        min={min}
        minlength={minLength}
        step={step}
        disabled={readOnly}
        spellcheck={spellCheck}
        style={{
          marginTop: "5px",
          height: "42px",
          padding: "6px 12px",
          color: "#000",
          backgroundColor: "#FFF",
          backgroundImage: "none",
          borderColor: "hsl(0, 0%, 80%)",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "1px",
        }}
        {...rest}
      />
    </IonCol>
  );
};

export default Input;
