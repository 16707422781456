import React, { useEffect, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonDatetime,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  useIonViewDidEnter,
} from "@ionic/react";
import { refresh } from "ionicons/icons";
import { OptionTypeBase } from "react-select";
import { format, add, differenceInSeconds } from "date-fns";

import { useAuth } from "../../hooks/auth";
import { useValidationErrors } from "../../hooks/errors";
import { useNotifications } from "../../hooks/notifications";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";

import Main from "../../components/Main";
import SearchTechnician from "../../components/Legacy/Search/Technician";
import { interrupted_dialysis } from "../../shared/InterruptedDialysis";
import { getType } from "../../shared/Procedures";

interface AssistsInProgress {
  id: number;
  situation: string;
  patient_name: string;
  hospital_name: string;
  location: string;
  bed: string;
  time: number;
  date: string;
  start: string;
  end: string;
  doctor_name: string;
  machine: number;
  reverse_osmosis: number;
  home_choice: number;
  technician1_id: number;
  technician2_id: number;
  technician3_id: number;
  technician1_name: string;
  technician2_name: string;
  technician3_name: string;
  tec1start: string;
  tec2start: string;
  tec3start: string;
  tec1end: string;
  tec2end: string;
  tec3end: string;
  proc: string;
  ktv: number;
  medical_record: string | number;
  tec1open: 0 | 1;
  tec2open: 0 | 1;
  tec3open: 0 | 1;
  tec1close: 0 | 1;
  tec2close: 0 | 1;
  tec3close: 0 | 1;
  interrupted?: string;
}

const AssistsInProgressCard: React.FC<{
  values: AssistsInProgress;
  getList(): void;
}> = ({ values, getList }) => {
  const { user } = useAuth();
  const { addToast, successToast } = useToast();
  const { getValidationErrors } = useValidationErrors();
  const { getCountAssistances } = useNotifications();

  const [timeOut, setTimeOut] = useState("00:00:00");
  const [finalized, setFinalized] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [KtV, setKtV] = useState<number>(0);
  const [medicalRecord, setMedicalRecord] = useState<string | number>(
    values.medical_record || ""
  );

  const [wasInterrupted, setWasInterrupted] = useState<"Y" | "N">("N");
  const [interrupted, setInterrupted] = useState<string | null>(null);

  const [tec1end, setTec1End] = useState(values.tec1end || "00:00:00");
  const [tec2end, setTec2End] = useState(values.tec2end || "00:00:00");
  const [tec3end, setTec3End] = useState(values.tec3end || "00:00:00");

  const [technician2, setTechnician2] = useState<OptionTypeBase>(
    !!values.technician2_id && !!values.technician2_name
      ? { value: String(values.technician2_id), label: values.technician2_name }
      : { value: 0, label: "" }
  );
  const [technician3, setTechnician3] = useState<OptionTypeBase>(
    !!values.technician3_id && !!values.technician3_name
      ? { value: String(values.technician3_id), label: values.technician3_name }
      : { value: 0, label: "" }
  );

  useEffect(() => {
    setFinalized(false);
  }, [values]);

  useEffect(() => {
    const updateTimeOut = () => {
      const endDateTime = add(new Date(`${values.date}T${values.start}`), {
        hours: values.time,
      });

      const nowDateTime = new Date();

      const time = differenceInSeconds(endDateTime, nowDateTime);

      const pad = (num: number, size: number) => {
        return ("000" + num).slice(size * -1);
      };

      if (time > 0) {
        const hours = Math.floor(time / 60 / 60);
        const minutes = Math.floor(time / 60) % 60;
        const seconds = Math.floor(time - minutes * 60);

        setTimeOut(`${pad(hours, 2)}h:${pad(minutes, 2)}m:${pad(seconds, 2)}s`);
      } else {
        setTimeOut("00:00:00");
      }
    };
    updateTimeOut();
    const interval = setInterval(updateTimeOut, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [values.date, values.start, values.time]);

  const handleSubmit = async () => {
    if (finalized) {
      if (confirm) {
        let data = {} as AssistsInProgress;
        let valid = true;

        data.ktv = KtV;
        data.medical_record = medicalRecord;

        if (values.technician1_id === user.tech_id) {
          data.technician1_id = values.technician1_id;
          data.tec1end = tec1end;
          if (!!technician2.value) {
            data.technician2_id = technician2.value;
          }
        } else if (values.technician2_id === user.tech_id) {
          data.technician2_id = values.technician2_id;
          data.tec2end = tec2end;
          if (!!technician3.value) {
            data.technician3_id = technician3.value;
          }
        } else if (values.technician3_id === user.tech_id) {
          data.technician3_id = values.technician3_id;
          data.tec3end = tec3end;
        }

        if (wasInterrupted === "Y") {
          if (!interrupted) {
            addToast({
              message: "O campo motivo é obrigatório",
              color: "warning",
            });
            valid = false;
          } else {
            data.interrupted = interrupted;
          }
        }

        if (valid) {
          await api
            .put(`technician/assists_in_progress/${values.id}`, data)
            .then((_response) => {
              successToast();
              getList();
              getCountAssistances();
            })
            .catch((error) => {
              getValidationErrors(error.response);
            });
        }
      } else {
        setConfirm(true);
      }
    } else {
      setFinalized(true);
    }
  };

  return (
    <IonCard className="card-andamento">
      {timeOut !== "00:00:00" && (
        <IonCardHeader>
          <IonCardTitle style={{ padding: "10px" }}>
            <IonTitle>
              Tempo Restante: <br /> {timeOut}
            </IonTitle>
          </IonCardTitle>
        </IonCardHeader>
      )}
      <IonCardContent>
        <IonRow className="ion-align-items-end">
          <IonCol size="12">
            <IonLabel>Paciente: </IonLabel>
            <IonText>{values.patient_name}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Hospital: </IonLabel>
            <IonText>{values.hospital_name}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Localização: </IonLabel>
            <IonText>{values.location}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Leito: </IonLabel>
            <IonText>{values.bed}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Data: </IonLabel>
            <IonText>
              {!!values.date &&
                format(new Date(values.date + "T00:00:00"), "dd/MM/yyyy")}
            </IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Hora de Início: </IonLabel>
            <IonText>
              {!!values.start && values.start.substr(0, 5) + " "}
              <strong>
                {values.technician2_id === user.tech_id && !!values.tec2start
                  ? `(${values.tec2start.substr(0, 5)})`
                  : values.technician3_id === user.tech_id && !!values.tec3start
                  ? `(${values.tec3start.substr(0, 5)})`
                  : ""}
              </strong>
            </IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Hora de Término: </IonLabel>
            <IonText>
              {!!values.end && values.end.substr(0, 5) + " "}
              <strong>
                {values.technician2_id === user.tech_id && !!values.tec2end
                  ? `(${values.tec2end.substr(0, 5)})`
                  : values.technician3_id === user.tech_id && !!values.tec3end
                  ? `(${values.tec3end.substr(0, 5)})`
                  : ""}
              </strong>
            </IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Médico: </IonLabel>
            <IonText>{values.doctor_name}</IonText>
          </IonCol>
          {getType(values.proc) === "DP" ? (
            <IonCol size="12">
              <IonLabel>Home Choice: </IonLabel>
              <IonText>{values.home_choice}</IonText>
            </IonCol>
          ) : (
            <>
              <IonCol size="12">
                <IonLabel>Máquina: </IonLabel>
                <IonText>{values.machine}</IonText>
              </IonCol>
              <IonCol size="12">
                <IonLabel>Osmose: </IonLabel>
                <IonText>{values.reverse_osmosis}</IonText>
              </IonCol>
            </>
          )}
        </IonRow>
        <IonRow className="ion-align-items-end ion-margin-top">
          <IonCol size="6">
            <IonButton
              type="button"
              routerLink={`/prescription/${values.id}`}
              color="primary"
              expand="block"
            >
              Prescrição
            </IonButton>
          </IonCol>
          <IonCol size="6">
            <IonButton
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
              type="button"
              routerLink={`/technician/assists_intercurrences/${values.id}/${values.patient_name}`}
              color="danger"
              expand="block"
            >
              <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                Intercorrências
              </span>
            </IonButton>
          </IonCol>
          <IonCol size="6">
            <IonButton
              type="button"
              routerLink={`/technician/assists_annotations/${values.id}/${values.patient_name}/${values.proc}`}
              color="secondary"
              expand="block"
            >
              Anotações
            </IonButton>
          </IonCol>
          <IonCol size="6">
            <IonButton
              type="button"
              routerLink={`/technician/assists_evolutions/${values.id}/${values.patient_name}`}
              color="tertiary"
              expand="block"
            >
              Evolução
            </IonButton>
          </IonCol>
          <IonCol size="6">
            <IonButton
              type="button"
              routerLink={`/technician/assistances_stock/${values.id}/${values.patient_name}`}
              color="dark"
              expand="block"
            >
              Estoque
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow className="ion-align-items-end ion-margin-top">
          {finalized && (
            <>
              <IonCol size="6">
                <IonLabel position="stacked">Hora de Término:</IonLabel>
                {values.technician1_id === user.tech_id &&
                values.tec1open === 1 &&
                values.tec1close === 0 ? (
                  <IonDatetime
                    value={tec1end}
                    onIonChange={(event) =>
                      setTec1End(event.detail.value || "")
                    }
                    cancelText="Cancelar"
                    doneText="Ok"
                    pickerFormat="HH:mm"
                    displayFormat="HH:mm"
                  />
                ) : values.technician2_id === user.tech_id &&
                  values.tec2open === 1 &&
                  values.tec2close === 0 ? (
                  <IonDatetime
                    value={tec2end}
                    onIonChange={(event) =>
                      setTec2End(event.detail.value || "")
                    }
                    cancelText="Cancelar"
                    doneText="Ok"
                    pickerFormat="HH:mm"
                    displayFormat="HH:mm"
                  />
                ) : values.technician3_id === user.tech_id &&
                  values.tec3open === 1 &&
                  values.tec3close === 0 ? (
                  <IonDatetime
                    value={tec3end}
                    onIonChange={(event) =>
                      setTec3End(event.detail.value || "")
                    }
                    cancelText="Cancelar"
                    doneText="Ok"
                    pickerFormat="HH:mm"
                    displayFormat="HH:mm"
                  />
                ) : (
                  <></>
                )}
              </IonCol>
              <IonCol size="6">
                <IonLabel position="stacked">Diálise Interrompida?</IonLabel>
                <IonSelect
                  value={wasInterrupted}
                  onIonChange={(event) =>
                    setWasInterrupted(event.detail.value || "N")
                  }
                  cancelText="Cancelar"
                  okText="Ok"
                  interface="action-sheet"
                >
                  <IonSelectOption value="N">Não</IonSelectOption>
                  <IonSelectOption value="Y">Sim</IonSelectOption>
                </IonSelect>
              </IonCol>
              {wasInterrupted === "Y" && (
                <IonCol size="6">
                  <IonLabel position="stacked">Motivo:</IonLabel>
                  <IonSelect
                    value={interrupted}
                    onIonChange={(event) =>
                      setInterrupted(event.detail.value || null)
                    }
                    cancelText="Cancelar"
                    okText="Ok"
                    interface="action-sheet"
                  >
                    {interrupted_dialysis.map((values, index) => (
                      <IonSelectOption key={index} value={values.value}>
                        {values.label}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonCol>
              )}
              <IonCol size="6">
                <IonLabel position="stacked">Kt/V:</IonLabel>
                <IonInput
                  value={KtV}
                  onIonChange={(event) =>
                    setKtV(parseFloat(event.detail.value || "0"))
                  }
                  type="number"
                  max="999999.99"
                  step="0.01"
                  autocomplete="off"
                  autocorrect="off"
                  inputmode="decimal"
                />
              </IonCol>
              <IonCol size="6">
                <IonLabel position="stacked">Folha:</IonLabel>
                <IonInput
                  value={medicalRecord}
                  onIonChange={(event) =>
                    setMedicalRecord(event.detail.value || "")
                  }
                  type="number"
                  autocomplete="off"
                  autocorrect="off"
                  inputmode="numeric"
                />
              </IonCol>
              <IonCol size="12">
                {values.technician1_id === user.tech_id &&
                values.tec1open === 1 &&
                values.tec1close === 0 ? (
                  <SearchTechnician
                    size="12"
                    label="Próximo Técnico"
                    value={technician2}
                    onChange={(value) => setTechnician2(value)}
                  />
                ) : values.technician2_id === user.tech_id &&
                  values.tec2open === 1 &&
                  values.tec2close === 0 ? (
                  <SearchTechnician
                    size="12"
                    label="Próximo Técnico"
                    value={technician3}
                    onChange={(value) => setTechnician3(value)}
                  />
                ) : (
                  <></>
                )}
              </IonCol>
            </>
          )}
          <IonCol size="12">
            <IonButton
              type="button"
              color="danger"
              expand="block"
              onClick={() => handleSubmit()}
            >
              Finalizar
            </IonButton>
          </IonCol>
        </IonRow>
      </IonCardContent>
      <IonAlert
        isOpen={confirm}
        onDidDismiss={() => setConfirm(false)}
        message={`Deseja realmente finalizar a sessão do paciente ${values.patient_name}`}
        buttons={[
          {
            text: "Sim",
            handler: () => handleSubmit(),
          },
          {
            text: "Não",
            handler: () => setConfirm(false),
          },
        ]}
      />
    </IonCard>
  );
};

const TechnicianAssistsInProgress: React.FC = () => {
  const { getCountAssistances } = useNotifications();
  const [showLoading, setShowLoading] = useState(false);
  const [data, setData] = useState<AssistsInProgress[]>([]);

  const getList = async () => {
    setShowLoading(true);
    await api.get<any>(`technician/assists_in_progress`).then((response) => {
      setData(response.data);
    });
    setShowLoading(false);
    getCountAssistances();
  };

  useIonViewDidEnter(() => {
    getList();
  });

  const RefreshIcon = () => (
    <IonItem lines="none">
      <IonIcon
        icon={refresh}
        size="large"
        color="light"
        onClick={() => getList()}
      />
    </IonItem>
  );

  return (
    <Main title="Em Andamento" end={<RefreshIcon />}>
      {data.map((values) => (
        <AssistsInProgressCard
          key={values.id}
          values={values}
          getList={getList}
        />
      ))}
      <IonLoading
        mode="ios"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Carregando..."}
        duration={5000}
      />
    </Main>
  );
};

export default TechnicianAssistsInProgress;
