import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useLocation, useParams } from "react-router-dom";
import {
  IonCard,
  IonCardContent,
  IonRow,
  IonCol,
  IonLabel,
  IonText,
  IonItemDivider,
} from "@ionic/react";

import { RootState } from "../store";
import { show, clear } from "../store/prescription/actions";
import { Prescription as PrescriptionType } from "../store/prescription/types";

import Main from "../components/Main";
import If from "../helpers/Legacy/if";
import IfCol from "../helpers/Legacy/ifCol";
import dateToString from "../helpers/Legacy/dateToString";
import newPatientConverter from "../helpers/Legacy/newPatientConverter";
import catheterConverter from "../helpers/Legacy/catheterConverter";
import catheterTypeConverter from "../helpers/Legacy/catheterTypeConverter";
import thenkoffConverter from "../helpers/Legacy/thenkoffConverter";
import catheterReasonConverter from "../helpers/Legacy/catheterReasonConverter";
import precautionConverter from "../helpers/Legacy/precautionConverter";
import indexConverter from "../helpers/Legacy/indexConverter";
import { getType } from "../shared/Procedures";

interface StateProps {
  values: PrescriptionType;
}

interface DispatchProps {
  show(id: string): void;
  clear(): void;
}

interface ParamTypes {
  id: string;
}

type Props = StateProps & DispatchProps;

const Evolution: React.FC<Props> = ({ values, show, clear }) => {
  const location = useLocation();
  const { id } = useParams<ParamTypes>();

  useEffect(() => {
    show(id);
    return () => {
      clear();
    };
  }, [location, show, clear, id]);

  return (
    <Main title="Prescrição" back>
      <IonCard>
        <IonCardContent>
          <IonRow>
            <IonItemDivider className="ion-margin-bottom" color="primary">
              <IonLabel>Procedimento</IonLabel>
            </IonItemDivider>
            <IonCol size="12">
              <IonLabel>Paciente: </IonLabel>
              <IonText>{values.patient}</IonText>
            </IonCol>
            <IonCol size="12">
              <IonLabel>Data: </IonLabel>
              <IonText>{dateToString(values.date ? values.date : "")}</IonText>
            </IonCol>
            <IonCol size="12">
              <IonLabel>Paciente Novo? </IonLabel>
              <IonText>
                {newPatientConverter({ value: values.new_patient })}
              </IonText>
            </IonCol>
            <IonCol size="12">
              <IonLabel>Hospital: </IonLabel>
              <IonText>{values.hospital}</IonText>
            </IonCol>
            <IonCol size="12">
              <IonLabel>Localização: </IonLabel>
              <IonText>{values.location}</IonText>
            </IonCol>
            <IonCol size="12">
              <IonLabel>Leito: </IonLabel>
              <IonText>{values.bed}</IonText>
            </IonCol>
            <IonCol size="12">
              <IonLabel>Procedimento: </IonLabel>
              <IonText>{values.proc}</IonText>
            </IonCol>
            <IonCol size="12">
              <IonLabel>Tempo (horas): </IonLabel>
              <IonText>{values.time}</IonText>
            </IonCol>
            <IonCol size="12">
              <IonLabel>Acesso: </IonLabel>
              <IonText>{values.access}</IonText>
            </IonCol>
            <IonCol size="12">
              <IonLabel>Sitio: </IonLabel>
              <IonText>{values.site}</IonText>
            </IonCol>
            <IonCol size="12">
              <IonLabel>Implantou Cateter? </IonLabel>
              <IonText>{catheterConverter({ value: values.catheter })}</IonText>
            </IonCol>
            <If test={values.catheter === "S"}>
              <IonCol size="12">
                <IonLabel>Cateter Tipo: </IonLabel>
                <IonText>
                  {catheterTypeConverter({ value: values.catheter_type })}
                </IonText>
              </IonCol>
              <IonCol size="12">
                <IonLabel>Thenkoff: </IonLabel>
                <IonText>
                  {thenkoffConverter({ value: values.thenkoff })}
                </IonText>
              </IonCol>
              <If test={values.catheter_type === "T"}>
                <IonCol size="12">
                  <IonLabel>Motivo da Troca: </IonLabel>
                  <IonText>
                    {catheterReasonConverter({ value: values.catheter_reason })}
                  </IonText>
                </IonCol>
              </If>
            </If>
            <IonCol size="12">
              <IonLabel>Precaução: </IonLabel>
              <IonText>
                {precautionConverter({ value: values.precaution })}
              </IonText>
            </IonCol>
            <IonCol size="12">
              <IonLabel>Índice de Captação: </IonLabel>
              <IonText>{indexConverter({ value: values.index })}</IonText>
            </IonCol>
            <IonCol size="12">
              <IonLabel>Tempo de Permanência de Cateter: </IonLabel>
              <IonText>{values.catheter_time}</IonText>
            </IonCol>
            <IonCol size="12">
              <IonLabel>Nº de sessões de diálise: </IonLabel>
              <IonText>{values.sessions}</IonText>
            </IonCol>
            <If test={values.medical_report ? true : false}>
              <IonCol size="12">
                <IonLabel>Relatório Médico: </IonLabel>
                <IonText>{values.medical_report}</IonText>
              </IonCol>
            </If>
            <IonItemDivider
              className="ion-margin-top ion-margin-bottom"
              color="primary"
            >
              <IonLabel>Prescrição</IonLabel>
            </IonItemDivider>
            <If test={!!values.proc && getType(values.proc) === "DEFAULT"}>
              <IfCol
                size="12"
                value={values.fluxo_sangue}
                label="Fluxo de Sangue (ml/min)"
              ></IfCol>
              <IfCol
                size="12"
                value={values.fluxo_dialisato}
                label="Fluxo Dialisato (ml/min)"
              ></IfCol>
              <IfCol
                size="12"
                value={values.ultrafiltracao_hora}
                label="Ultrafiltração (ml/hora)"
              ></IfCol>
              <IfCol
                size="12"
                value={values.ultrafiltracao_min}
                label="UF Total"
              ></IfCol>
              <IfCol
                size="12"
                value={values.heparina}
                label="Heparina (unidade)"
              ></IfCol>
              <IfCol
                size="12"
                value={values.soro_fisiológico}
                label="Soro Fisiológico (ml/min)"
              ></IfCol>
              <IfCol
                size="12"
                value={values.selante_cateter}
                label="Tipo Selante do Cateter"
              ></IfCol>
              <IfCol size="12" value={values.kcl} label="KCL (SACHE)"></IfCol>
              <IfCol
                size="12"
                value={values.fosfato_de_sodio}
                label="Fosfato de Sódio (SACHE)"
              ></IfCol>
              <IfCol
                size="12"
                value={values.bicarbonato_prescricao}
                label="Bicarbonato"
              ></IfCol>
              <IfCol
                size="12"
                value={values.sodio_prescricao}
                label="Sódio"
              ></IfCol>
              <IfCol
                size="12"
                value={values.temperatura}
                label="Temperatura"
              ></IfCol>
              <IfCol size="12" value={values.kdigo} label="KDIGO"></IfCol>
            </If>
            <If test={!!values.proc && getType(values.proc) === "DP"}>
              <IfCol
                size="12"
                value={values.uf_total}
                label="UF Total (ml/min)"
              ></IfCol>
              <IfCol
                size="12"
                value={values.volume_total}
                label="Volume Total (ml)"
              ></IfCol>
              <IfCol
                size="12"
                value={values.volume_infusao}
                label="Volume de Infusão (ml)"
              ></IfCol>
              <IfCol
                size="12"
                value={values.volume_infusao_ultima}
                label="Volume da Última Infusão (ml)"
              ></IfCol>
              <IfCol
                size="12"
                value={values.tempo_permanencia}
                label="Tempo de Permanência"
              ></IfCol>
              <IfCol
                size="12"
                value={values.heparina}
                label="Heparina (unidade)"
              ></IfCol>
              <IfCol
                size="12"
                value={values.concetracao_bolsas}
                label="Concentração das Bolsas"
              ></IfCol>
            </If>
            <If test={!!values.proc && getType(values.proc) === "CONT"}>
              <IfCol size="12" value={values.kit} label="Kit"></IfCol>
              <IfCol
                size="12"
                value={values.capillary_type}
                label="Tipo de Capilar"
              ></IfCol>
              <IfCol
                size="12"
                value={values.line_type}
                label="Tipo de Linha"
              ></IfCol>
              <IfCol
                size="12"
                value={values.ultrafiltracao_min}
                label="UF"
              ></IfCol>
              <IfCol
                size="12"
                value={values.fluxo_sangue}
                label="Fluxo de Sangue (ml/min)"
              ></IfCol>
              <IfCol
                size="12"
                value={values.fluxo_dialisato}
                label="Fluxo Dialisato (ml/h)"
              ></IfCol>
              <If test={values.proc === "CVVHDF"}>
                <IfCol
                  size="12"
                  value={values.fluxo_de_reposicao}
                  label="Fluxo de Reposição (ml/h)"
                ></IfCol>
              </If>
              <IfCol size="12" value={values.peso} label="Peso (Kg)"></IfCol>
              <IfCol
                size="12"
                value={values.temperatura}
                label="Temperatura"
              ></IfCol>
              <IfCol
                size="12"
                value={values.anticoagulation}
                label="Anticoagulação"
              ></IfCol>
              <IfCol
                size="12"
                value={values.initial_citrate}
                label="Dose Inicial Citrato"
              ></IfCol>
              <IfCol
                size="12"
                value={values.initial_calcium}
                label="Dose Inicial Cálcio"
              ></IfCol>
            </If>
            <IfCol
              size="12"
              value={values.diagnostico}
              label="Diagnóstico"
            ></IfCol>
            <IfCol
              size="12"
              value={values.aminasvasoativas ? "Sim" : "Não"}
              label="Uso de aminasvasoativas"
            ></IfCol>
            <IfCol
              size="12"
              value={values.drogas_vasoativas}
              label="Quantidade (ml/hora)"
            ></IfCol>
            <IfCol
              size="12"
              value={values.quadro_clinico}
              label="Quadro Clínico"
            ></IfCol>
            <IfCol
              size="12"
              value={values.ventilacao_mecanica ? "Sim" : "Não"}
              label="Ventilação Mecânica"
            ></IfCol>
            <IfCol
              size="12"
              value={values.hemodicamente}
              label="Hemodicamente"
            ></IfCol>
            <IfCol
              size="12"
              value={values.diurese}
              label="Diurese (ml/24)"
            ></IfCol>
            <If test={values.bh ? true : false}>
              <IonCol size="12">
                <IonLabel>BH (ml/24): </IonLabel>
                <IonText>{`${values.bh_sinal} ${values.bh}`}</IonText>
              </IonCol>
            </If>

            <IfCol
              size="12"
              value={values.creatinina}
              label="Creatinina (mg/dl)"
            ></IfCol>
            <IfCol size="12" value={values.ureia} label="Ureia (mg/dl)"></IfCol>
            <IfCol
              size="12"
              value={values.potassio}
              label="Potássio (mEq/l)"
            ></IfCol>
            <IfCol
              size="12"
              value={values.fosforo}
              label="Fósforo (mg/dl)"
            ></IfCol>
            <IfCol
              size="12"
              value={values.calcio}
              label="Cálcio (mg/dl)"
            ></IfCol>
            <IfCol size="12" value={values.pcr} label="PCR (Unidade)"></IfCol>
            <IfCol size="12" value={values.sodio} label="Sódio (mEq/l)"></IfCol>
            <IfCol size="12" value={values.cloro} label="Cloro (mEq/l)"></IfCol>
            <IfCol size="12" value={values.ph} label="Ph"></IfCol>
            <IfCol
              size="12"
              value={values.bicarbonato}
              label="Bicarbonato (mEq/l)"
            ></IfCol>
            <IfCol size="12" value={values.be} label="BE (Unidade)"></IfCol>
            <IfCol
              size="12"
              value={values.hemoglobobina}
              label="Hemoglobobina (G/dl)"
            ></IfCol>
            <IfCol size="12" value={values.ht} label="HT (%)"></IfCol>
            <IfCol
              size="12"
              value={values.covid === "Y" ? "Sim" : "Não"}
              label="Covid"
            ></IfCol>
          </IonRow>
        </IonCardContent>
      </IonCard>
    </Main>
  );
};

const mapStateToProps = (state: RootState) => ({
  values: state.prescription,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ show, clear }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Evolution);
