import Yup from "../../services/Legacy/yup";

const catheters_schema = Yup.object().shape({
  patient: Yup.mixed().required().label("Paciente"),
  proc: Yup.string().required().label("Procedimento"),
  hospital: Yup.mixed().required().label("Hospital"),
  date: Yup.string().required().label("Data"),
});

export default catheters_schema;
