interface Props {
  value?: string;
  upper?: Boolean;
}

export default function (props: Props) {
  if (props.value === "I") {
    if (props.upper === true) {
      return "IMPROVÁVEL PARA DIÁLISE CRÔNICA";
    } else {
      return "Improvável para Diálise Crônica";
    }
  } else if (props.value === "P") {
    if (props.upper === true) {
      return "PROVÁVEL PARA DIÁLISE CRÔNICA";
    } else {
      return "Provável para Diálise Crônica";
    }
  } else if (props.value === "M") {
    if (props.upper === true) {
      return "JÁ EM PROGRAMA DE DIÁLISE CRÔNICA";
    } else {
      return "Já em Programa de Diálise Crônica";
    }
  } else if (props.value === "P") {
    if (props.upper === true) {
      return "PROVÁVEL PARA CONSULTÓRIO";
    } else {
      return "Provável para Consultório";
    }
  } else {
    return "";
  }
}
