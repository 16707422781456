import React, { useState, useEffect } from "react";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLabel,
  IonInput,
} from "@ionic/react";

import { useToast } from "../hooks/toast";
import { useAuth } from "../hooks/auth";
import { useValidationErrors } from "../hooks/errors";
import api from "../services/api";

import Main from "../components/Main";

const UserData: React.FC = () => {
  const { addToast } = useToast();
  const { getValidationErrors } = useValidationErrors();
  const { updateUserName } = useAuth();
  const [name, setName] = useState("");

  useEffect(() => {
    api.get<any>("user").then((response) => {
      if (response.data?.name) {
        setName(response.data.name);
      }
    });
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (name !== "") {
      await api
        .put<any>("user", { name })
        .then((response) => {
          if (response.data) {
            updateUserName(response.data);
            setName(response.data);
          }
          addToast({ message: "Dados salvos com sucesso", color: "success" });
        })
        .catch((error) => {
          getValidationErrors(error);
        });
    } else {
      addToast({ message: "Nome é obrigatório", color: "warning" });
    }
  };

  return (
    <Main title="Dados do Usuário">
      <IonCard>
        <IonCardContent>
          <form onSubmit={handleSubmit}>
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonLabel position="stacked">NOME</IonLabel>
                  <IonInput
                    type="text"
                    value={name}
                    onIonChange={(e) => setName(e.detail.value || "")}
                    autofocus
                    autocomplete="name"
                    autocapitalize="off"
                    autocorrect="off"
                    style={{
                      marginTop: "5px",
                      height: "42px",
                      width: "100%",
                      padding: "6px 12px",
                      color: "#555555",
                      backgroundColor: "#FFFFFF",
                      backgroundImage: "none",
                      borderColor: "hsl(0, 0%, 80%)",
                      borderRadius: "4px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      lineHeight: 2,
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="6">
                  <IonButton type="submit" color="primary" expand="block">
                    Salvar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
    </Main>
  );
};

export default UserData;
