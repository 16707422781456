import {
  AssistancesTakeOn,
  TechnicianTakeOnActionsTypes,
  GET_TECHNICIAN_TAKE_ON,
  CLEAR_TECHNICIAN_TAKE_ON,
} from "./types";

const initialState: AssistancesTakeOn = {
  assistances: [],
};

export function technicianTakeOnReducer(
  state = initialState,
  action: TechnicianTakeOnActionsTypes
): AssistancesTakeOn {
  switch (action.type) {
    case GET_TECHNICIAN_TAKE_ON:
      return {
        ...state,
        assistances: action.payload,
      };
    case CLEAR_TECHNICIAN_TAKE_ON:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
