import React, { useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonDatetime,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import { format, addDays, subDays, subYears } from "date-fns";

import api from "../../services/api";

import Main from "../../components/Main";

const sessionName = process.env.REACT_APP_SESSION_NAME;

interface ScoreDataType {
  assists: {
    id: number;
    technician: string;
    patient: string;
    agreement: string;
    procedure: string;
    score: number;
    hospital: string;
    date: string;
    start: string;
    end: string;
    time: string;
  }[];
  assists_count: number;
  score: number;
  time: string;
}

const TechnicianScore: React.FC = () => {
  const [from, setFrom] = useState<string>(
    localStorage.getItem(`@${sessionName}:TechnicianScore_From`) || ""
  );
  const [to, setTo] = useState<string>(
    localStorage.getItem(`@${sessionName}:TechnicianScore_To`) || ""
  );
  const [type, setType] = useState<string>(
    localStorage.getItem(`@${sessionName}:TechnicianScore_Type`) || "ALL"
  );

  const [data, setData] = useState<ScoreDataType>({} as ScoreDataType);

  const getList = () => {
    api
      .get<any>("technician/score", {
        params: {
          from,
          to,
          type,
        },
      })
      .then((response) => {
        setData(response.data);
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    getList();
  };

  return (
    <Main title="Pontuação">
      <IonCard>
        <IonCardContent>
          <form onSubmit={handleSubmit}>
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonLabel position="stacked">De:</IonLabel>
                  <IonDatetime
                    value={from}
                    onIonChange={(e) => {
                      setFrom(e.detail.value || "");
                      localStorage.setItem(
                        `@${sessionName}:TechnicianScore_From`,
                        e.detail.value || ""
                      );
                    }}
                    cancelText="Cancelar"
                    doneText="Ok"
                    displayFormat="DD/MM/YYYY"
                    min={
                      (!!to &&
                        format(subDays(new Date(to), 30), "yyyy-MM-dd")) ||
                      format(subYears(new Date(), 100), "yyyy-MM-dd")
                    }
                    max={to || format(new Date(), "yyyy-MM-dd")}
                    style={{
                      marginTop: "5px",
                      height: "42px",
                      width: "100%",
                      padding: "6px 12px",
                      color: "#555555",
                      backgroundColor: "#FFFFFF",
                      backgroundImage: "none",
                      borderColor: "hsl(0, 0%, 80%)",
                      borderRadius: "4px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      lineHeight: 2,
                    }}
                  />
                </IonCol>
                <IonCol size="12">
                  <IonLabel position="stacked">Até:</IonLabel>
                  <IonDatetime
                    value={to}
                    onIonChange={(e) => {
                      setTo(e.detail.value || "");
                      localStorage.setItem(
                        `@${sessionName}:TechnicianScore_To`,
                        e.detail.value || ""
                      );
                    }}
                    cancelText="Cancelar"
                    doneText="Ok"
                    displayFormat="DD/MM/YYYY"
                    min={from || format(new Date(), "yyyy-MM-dd")}
                    max={
                      (!!from &&
                        format(addDays(new Date(from), 30), "yyyy-MM-dd")) ||
                      format(addDays(new Date(), 30), "yyyy-MM-dd")
                    }
                    style={{
                      marginTop: "5px",
                      height: "42px",
                      width: "100%",
                      padding: "6px 12px",
                      color: "#555555",
                      backgroundColor: "#FFFFFF",
                      backgroundImage: "none",
                      borderColor: "hsl(0, 0%, 80%)",
                      borderRadius: "4px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      lineHeight: 2,
                    }}
                  />
                </IonCol>
                <IonCol size="12">
                  <IonLabel position="stacked">Tipo</IonLabel>
                  <IonSelect
                    value={type}
                    onIonChange={(e) => {
                      setType(e.detail.value || "ALL");
                      localStorage.setItem(
                        `@${sessionName}:TechnicianScore_Type`,
                        e.detail.value || "ALL"
                      );
                    }}
                    cancelText="Cancelar"
                    okText="Ok"
                    interface="action-sheet"
                    style={{
                      marginTop: "5px",
                      height: "42px",
                      width: "100%",
                      padding: "6px 12px",
                      color: "#555555",
                      backgroundColor: "#FFFFFF",
                      backgroundImage: "none",
                      borderColor: "hsl(0, 0%, 80%)",
                      borderRadius: "4px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      lineHeight: 2,
                    }}
                  >
                    <IonSelectOption value="ALL">Todos</IonSelectOption>
                    <IonSelectOption value="PA">Particular</IonSelectOption>
                    <IonSelectOption value="PU">Público</IonSelectOption>
                  </IonSelect>
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="6">
                  <IonButton type="submit" color="primary" expand="block">
                    Consultar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
      {!!data.assists && (
        <>
          <IonCard>
            <IonCardContent>
              <IonList>
                <IonListHeader mode="ios">Total</IonListHeader>
                <IonItem>
                  <IonLabel>Pontos: </IonLabel>
                  <IonText>{data.score}</IonText>
                </IonItem>
                <IonItem>
                  <IonLabel>Procedimentos: </IonLabel>
                  <IonText>{data.assists_count}</IonText>
                </IonItem>
                <IonItem>
                  <IonLabel>Horas: </IonLabel>
                  <IonText>{data.time}</IonText>
                </IonItem>
              </IonList>
            </IonCardContent>
          </IonCard>
          {data.assists.map((assist, index) => (
            <IonCard key={index}>
              <IonCardContent>
                <IonRow>
                  <IonCol size="12">
                    <IonLabel>Técnico: </IonLabel>
                    <IonText>{assist.technician}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Paciente: </IonLabel>
                    <IonText>{assist.patient}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Convênio: </IonLabel>
                    <IonText>{assist.agreement}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Procedimento: </IonLabel>
                    <IonText>{assist.procedure}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Pontos: </IonLabel>
                    <IonText>{assist.score}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Hospital: </IonLabel>
                    <IonText>{assist.hospital}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Data: </IonLabel>
                    <IonText>{assist.date}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Hora de Inicio: </IonLabel>
                    <IonText>{assist.start}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Hora de Fim: </IonLabel>
                    <IonText>{assist.end}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Total de Horas: </IonLabel>
                    <IonText>{assist.time}</IonText>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          ))}
        </>
      )}
    </Main>
  );
};

export default TechnicianScore;
