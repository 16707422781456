import {
  TechnicianTakeOnForm,
  TechnicianTakeOnActionsTypes,
  GET_TECHNICIAN_TAKE_ON,
  CLEAR_TECHNICIAN_TAKE_ON,
} from "./types";
import { ThunkDispatch } from "redux-thunk";
import { ToastActionsTypes } from "../../../store/toast/types";
import { showToast } from "../../../store/toast/actions";
import ErrorHandler from "../../../helpers/Legacy/errorsHandler";
import api from "../../../services/api";

const sessionKey = `${process.env.REACT_APP_SESSION_NAME}`;
export function getList(values: TechnicianTakeOnForm) {
  localStorage.setItem(
    `${sessionKey}_technician_assistances_take_on`,
    JSON.stringify(values)
  );
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      TechnicianTakeOnActionsTypes | ToastActionsTypes
    >
  ) => {
    await api
      .get<any>("technician/assistances_take_on", {
        params: {
          hospital: values.hospital.value,
        },
      })
      .then((response) => {
        dispatch({ type: GET_TECHNICIAN_TAKE_ON, payload: response.data });
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function takeOn(id: number) {
  return async (dispatch: ThunkDispatch<{}, {}, ToastActionsTypes>) => {
    await api
      .put(`technician/assistances_take_on/${id}`)
      .then((response) => {
        dispatch(showToast("Dados salvos com sucesso", "success"));
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function clearList(): TechnicianTakeOnActionsTypes {
  return {
    type: CLEAR_TECHNICIAN_TAKE_ON,
    payload: undefined,
  };
}
