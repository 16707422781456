import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from "react";

import { useAuth } from "./auth";
import api from "../services/api";

export interface NotificationsContextData {
  scheduled: number;
  inProgress: number;
  getCountAssistances(): void;
}

interface CountAssistancesRequestData {
  scheduled: number;
  in_progress: number;
}

const NotificationsContext = createContext<NotificationsContextData>(
  {} as NotificationsContextData
);

const NotificationsProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const [scheduled, setScheduled] = useState(0);
  const [inProgress, setInProgress] = useState(0);

  const getCountAssistances = useCallback(async () => {
    await api
      .get<CountAssistancesRequestData>("technician/count_assistances")
      .then((response) => {
        setScheduled(response.data?.scheduled || 0);
        setInProgress(response.data?.in_progress || 0);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(function () {
      if (user?.profile === "technician") {
        getCountAssistances();
      }
    }, 60000);
    if (user?.profile === "technician") {
      getCountAssistances();
    }
    return () => clearInterval(interval);
  }, [user, getCountAssistances]);

  return (
    <NotificationsContext.Provider
      value={{
        scheduled,
        inProgress,
        getCountAssistances,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

function useNotifications(): NotificationsContextData {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      "useNotifications must be used within an NotificationsProvider"
    );
  }

  return context;
}

export { NotificationsProvider, useNotifications };
