import Yup from "../../services/Legacy/yup";
import { getType } from "../../shared/Procedures";

const assists_annotations_schema = Yup.object().shape({
  procedure: Yup.string().notRequired().label("procedimento"),
  dialysate_flow: Yup.number().when("procedure", {
    is: (value: string) => getType(value) !== "CONT",
    then: Yup.number().required().label("Fluxo Dialisato(ml/min)"),
  }),
  blood_flow: Yup.number().when("procedure", {
    is: (value: string) => getType(value) !== "CONT",
    then: Yup.number().required().label("Fluxo Sanguíneo(ml/min)"),
  }),
  serum: Yup.number().when("procedure", {
    is: (value: string) => getType(value) !== "CONT",
    then: Yup.number().required().label("Soro(ml)"),
  }),
  venous_pressure: Yup.number().when("procedure", {
    is: (value: string) => getType(value) !== "CONT",
    then: Yup.number().required().label("P.Ven(mmHg)"),
  }),
  arterial_pressure: Yup.number().when("procedure", {
    is: (value: string) => getType(value) !== "CONT",
    then: Yup.number().required().label("P.Art(mmHg)"),
  }),
  heparin: Yup.number()
    .when("procedure", {
      is: (value: string) => getType(value) !== "CONT",
      then: Yup.number().required().label("Solução de Heparina(ml/h)"),
    })
    .when("procedure", {
      is: (value: string) => getType(value) === "CONT",
      then: Yup.number().label("Heparina"),
    }),
  tax: Yup.number().when("procedure", {
    is: (value: string) => getType(value) !== "CONT",
    then: Yup.number().required().label("Tax(ºC)"),
  }),
  fc: Yup.number().when("procedure", {
    is: (value: string) => getType(value) !== "CONT",
    then: Yup.number().required().label("FC(bpm)"),
  }),
  tmp: Yup.number().when("procedure", {
    is: (value: string) => getType(value) === "CONT",
    then: Yup.number().required().label("TMP"),
  }),
  tf: Yup.number().when("procedure", {
    is: (value: string) => getType(value) === "CONT",
    then: Yup.number().required().label("TF"),
  }),
  pv: Yup.number().when("procedure", {
    is: (value: string) => getType(value) === "CONT",
    then: Yup.number().required().label("PV"),
  }),
  pbe: Yup.number().when("procedure", {
    is: (value: string) => getType(value) === "CONT",
    then: Yup.number().required().label("PBE"),
  }),
  pa: Yup.string()
    .when("procedure", {
      is: (value: string) => getType(value) !== "CONT",
      then: Yup.string().required().label("PA(mmHg)"),
    })
    .when("procedure", {
      is: (value: string) => getType(value) === "CONT",
      then: Yup.string().required().label("PA(mmHg)"),
    }),
  calcium: Yup.number().when("procedure", {
    is: (value: string) => getType(value) === "CONT",
    then: Yup.number().label("Cálcio"),
  }),
  citrate: Yup.number().when("procedure", {
    is: (value: string) => getType(value) === "CONT",
    then: Yup.number().label("Citrato"),
  }),
  sf: Yup.number().when("procedure", {
    is: (value: string) => getType(value) === "CONT",
    then: Yup.number().label("SF 0,9%"),
  }),
  partial_balance: Yup.number().when("procedure", {
    is: (value: string) => getType(value) === "CONT",
    then: Yup.number().required().label("Balanço Parcial"),
  }),
  time: Yup.string().required("hora"),
});

export default assists_annotations_schema;
