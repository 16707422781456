export interface Treatment {
  id: number;
  date: string;
  patient_id: number;
  patient: string;
  destination: string;
  event: string;
}

export interface Treatments {
  treatments: Treatment[];
}

export interface TreatmentForm {
  hospital: {
    value: number;
    label: string;
  };
}

export const GET_TREATMENTS = "GET_TREATMENTS";
export const CLEAR_TREATMENTS = "CLEAR_TREATMENTS";
export const UPDATE_TREATMENT = "UPDATE_TREATMENT";

interface GetTreatmentsActions {
  type: typeof GET_TREATMENTS;
  payload: Treatment[];
}

interface ClearTreatmentsActions {
  type: typeof CLEAR_TREATMENTS;
  payload: undefined;
}

interface UpdateTreatmentActions {
  type: typeof UPDATE_TREATMENT;
  payload: Treatment;
}

export type TreatmentActionsTypes =
  | GetTreatmentsActions
  | ClearTreatmentsActions
  | UpdateTreatmentActions;
