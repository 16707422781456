interface Props {
  test: boolean
  children: any
}

function If(props: Props) {
  if (props.test) {
    return props.children
  } else {
    return false
  }
}

export default If
