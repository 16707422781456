import {
  Treatments,
  Treatment,
  TreatmentActionsTypes,
  GET_TREATMENTS,
  CLEAR_TREATMENTS,
  UPDATE_TREATMENT,
} from "./types";

const initialState: Treatments = {
  treatments: [],
};

export function doctorTreatmentReducer(
  state = initialState,
  action: TreatmentActionsTypes
): Treatments {
  switch (action.type) {
    case GET_TREATMENTS:
      return {
        ...state,
        treatments: action.payload,
      };
    case CLEAR_TREATMENTS:
      return {
        ...state,
        ...initialState,
      };
    case UPDATE_TREATMENT:
      return {
        ...state,
        treatments: state.treatments.map((treatment: Treatment) =>
          treatment.id === action.payload.id ? action.payload : treatment
        ),
      };
    default:
      return state;
  }
}
