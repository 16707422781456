export interface Prescription {
  patient?: string;
  date?: string;
  new_patient?: "N" | "S";
  hospital?: string;
  location?: string;
  bed?: string;
  proc?: string;
  time?: number;
  access?: string;
  site?: string;
  catheter?: "N" | "S";
  catheter_type?: "N" | "T";
  thenkoff?: "N" | "S";
  catheter_reason?: string;
  precaution?: boolean;
  index?: string;
  catheter_time?: number;
  sessions?: number;
  medical_report?: string;
  fluxo_sangue?: number;
  fluxo_dialisato?: number;
  ultrafiltracao_hora?: number;
  ultrafiltracao_min?: number;
  heparina?: number;
  soro_fisiológico?: number;
  selante_cateter?: string;
  kcl?: number;
  fosfato_de_sodio?: number;
  bicarbonato_prescricao?: number;
  sodio_prescricao?: number;
  temperatura?: number;
  kdigo?: number;
  uf_total?: number;
  volume_total?: number;
  volume_infusao?: number;
  volume_infusao_ultima?: number;
  tempo_permanencia?: number;
  concetracao_bolsas?: string;
  fluxo_de_reposicao?: number;
  peso?: number;
  dose_dialise_prescrita?: number;
  dose_de_citrato?: number;
  diagnostico?: string;
  aminasvasoativas?: boolean;
  drogas_vasoativas?: number;
  quadro_clinico?: string;
  ventilacao_mecanica?: boolean;
  hemodicamente?: string;
  diurese?: number;
  bh_sinal?: string;
  bh?: number;
  creatinina?: number;
  ureia?: number;
  potassio?: number;
  fosforo?: number;
  calcio?: number;
  pcr?: number;
  sodio?: number;
  cloro?: number;
  ph?: number;
  bicarbonato?: number;
  be?: number;
  hemoglobobina?: number;
  ht?: number;
  covid?: "Y" | "N";
  mode?: string;
  kit?: string;
  capillary_type?: string;
  line_type?: string;
  anticoagulation?: string;
  initial_citrate?: string;
  initial_calcium?: string;
}

export const GET_PRESCRIPTION = "GET_PRESCRIPTION";
export const CLEAR_PRESCRIPTION = "CLEAR_PRESCRIPTION";

interface GetPrescriptionActions {
  type: typeof GET_PRESCRIPTION;
  payload: Prescription;
}

interface ClearPrescriptionActions {
  type: typeof CLEAR_PRESCRIPTION;
  payload: undefined;
}

export type PrescriptionActionsTypes =
  | GetPrescriptionActions
  | ClearPrescriptionActions;
