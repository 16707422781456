import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  formValueSelector,
} from "redux-form";
import { useLocation } from "react-router-dom";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonText,
  IonSelectOption,
} from "@ionic/react";
import { caretBack, caretForward, readerOutline } from "ionicons/icons";

import { RootState } from "../../store";
import {
  getList,
  clearList,
  update,
  show,
  suspend,
  assume,
  repeat,
} from "../../store/doctor/procedure/actions";
import {
  Procedure as ProcedureType,
  ProcedureForm as FormType,
} from "../../store/doctor/procedure/types";

import { useAuth } from "../../hooks/auth";

import Main from "../../components/Main";
import HospitalSearch from "../../components/Legacy/Search/Hospital";
import Checkbox from "../../components/Legacy/Form/Checkbox";
import DateInput from "../../components/Legacy/Form/DateInput";
import Select from "../../components/Legacy/Form/Select";
import If from "../../helpers/Legacy/if";
import dateToString from "../../helpers/Legacy/dateToString";

interface StateProps {
  procedures: ProcedureType[];
  proceduresForm: FormType;
}

interface DispatchProps {
  getList(values: FormType): void;
  clearList(): void;
  update(values: ProcedureType): void;
  show(id: number): void;
  suspend(id: number): void;
  assume(id: number): void;
  repeat(id: number): void;
}

type Props = StateProps & DispatchProps;

const Procedures: React.FC<Props & InjectedFormProps<FormType, Props>> = ({
  handleSubmit,
  getList,
  clearList,
  procedures,
  proceduresForm,
  change,
  update,
  show,
  suspend,
  assume,
  repeat,
}) => {
  const { user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    clearList();
  }, [location, clearList]);

  function handlePrevious() {
    let yesterday = new Date(proceduresForm.date);
    yesterday.setDate(yesterday.getDate() - 1);
    change("date", yesterday.toDateString());
    proceduresForm.date = yesterday.toDateString();
    getList(proceduresForm);
  }

  function handleNext() {
    let tomorrow = new Date(proceduresForm.date);
    tomorrow.setDate(tomorrow.getDate() + 1);
    change("date", tomorrow.toDateString());
    proceduresForm.date = tomorrow.toDateString();
    getList(proceduresForm);
  }

  return (
    <Main title="Procedimentos">
      <IonCard>
        <IonCardContent>
          <form onSubmit={handleSubmit(getList)}>
            <IonGrid>
              <IonRow>
                <Field name="hospital" component={HospitalSearch} required />
                <Field
                  name="all"
                  component={Checkbox}
                  label="Todos os Médicos"
                />
                <Field name="situation" component={Select} label="Situação">
                  <IonSelectOption value="">TODAS</IonSelectOption>
                  <IonSelectOption value="AGENDADO">AGENDADO</IonSelectOption>
                  <IonSelectOption value="ANDAMENTO">ANDAMENTO</IonSelectOption>
                  <IonSelectOption value="CANCELADO">CANCELADO</IonSelectOption>
                  <IonSelectOption value="PENDENTE">PENDENTE</IonSelectOption>
                  <IonSelectOption value="REALIZADO">REALIZADO</IonSelectOption>
                  <IonSelectOption value="S_MAQUINA">
                    SEM MÁQUINA
                  </IonSelectOption>
                  <IonSelectOption value="SEMRH">SEM RH</IonSelectOption>
                  <IonSelectOption value="SUSPENSO">SUSPENSO</IonSelectOption>
                  <IonSelectOption value="VALIDADO">VALIDADO</IonSelectOption>
                </Field>
                <Field name="date" component={DateInput} label="Data" />
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="3">
                  <IonButton
                    type="button"
                    onClick={handlePrevious}
                    color="primary"
                    expand="block"
                    disabled={!!proceduresForm.date ? false : true}
                  >
                    <IonIcon size="small" icon={caretBack} />
                  </IonButton>
                </IonCol>
                <IonCol size="6">
                  <IonButton type="submit" color="primary" expand="block">
                    Consultar
                  </IonButton>
                </IonCol>
                <IonCol size="3">
                  <IonButton
                    type="button"
                    onClick={handleNext}
                    color="primary"
                    expand="block"
                    disabled={!!proceduresForm.date ? false : true}
                  >
                    <IonIcon size="small" icon={caretForward} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
      {procedures.map((procedure) => {
        return (
          <IonCard
            key={procedure.id}
            className={`card-${procedure.situation?.toLowerCase()}`}
          >
            <IonCardHeader>
              <IonCardTitle>
                <IonRow>
                  <IonCol> Situação: {procedure.situation}</IonCol>
                  {!!procedure.medical_report && (
                    <IonCol
                      size="2"
                      style={{
                        textAlign: "right",
                        fontWeight: "bold",
                        color: "#000000",
                      }}
                    >
                      <IonIcon icon={readerOutline} />
                    </IonCol>
                  )}
                </IonRow>
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonRow>
                <IonCol size="12">
                  <IonLabel>Data: </IonLabel>
                  <IonText>{dateToString(procedure.date!)}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Hospital: </IonLabel>
                  <IonText>{procedure.hospital}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Paciente: </IonLabel>
                  <IonText>{procedure.patient}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Técnico: </IonLabel>
                  <IonText>{procedure.technician}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Médico: </IonLabel>
                  <IonText>{procedure.doctor}</IonText>
                </IonCol>
                <Select
                  label="Destino"
                  input={{
                    name: "destination",
                    value: procedure.destination!,
                    onChange: (e: string) =>
                      update({ ...procedure, destination: e }),
                  }}
                >
                  <IonSelectOption value="Em tratamento">
                    Em tratamento
                  </IonSelectOption>
                  <IonSelectOption value="Alta Nefro">
                    Alta Nefro
                  </IonSelectOption>
                  <IonSelectOption value="Alta Hospitalar">
                    Alta Hospitalar
                  </IonSelectOption>
                  <IonSelectOption value="Alta encaminhado para o programa">
                    Alta encaminhado para o programa
                  </IonSelectOption>
                  <IonSelectOption value="Transferência">
                    Transferência
                  </IonSelectOption>
                  <IonSelectOption value="Óbito">Óbito</IonSelectOption>
                </Select>
                <Select
                  label="Evento"
                  input={{
                    name: "event",
                    value: procedure.event!,
                    onChange: (e: string) => update({ ...procedure, event: e }),
                  }}
                >
                  <IonSelectOption value="Suspenso">Suspenso</IonSelectOption>
                  <IonSelectOption value="em Avaliação">
                    em Avaliação
                  </IonSelectOption>
                  <IonSelectOption value="Dias Alternados">
                    Dias Alternados
                  </IonSelectOption>
                  <IonSelectOption value="Diário">Diário</IonSelectOption>
                  <IonSelectOption value="Conservador">
                    Conservador
                  </IonSelectOption>
                </Select>
                <If
                  test={
                    procedure.situation === "ANDAMENTO" ||
                    procedure.situation === "REALIZADO"
                  }
                >
                  <IonCol size="12">
                    <IonLabel>Hora de Início: </IonLabel>
                    <IonText>{procedure.start}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Hora de Fim: </IonLabel>
                    <IonText>{procedure.end}</IonText>
                  </IonCol>
                </If>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="6">
                  <If
                    test={
                      procedure.situation !== "REALIZADO" &&
                      procedure.doctor_id === user.user_id
                    }
                  >
                    <IonButton
                      type="button"
                      onClick={() => show(procedure.id)}
                      color={`${
                        procedure.prescription === null ? "danger" : "primary"
                      }`}
                      expand="block"
                    >
                      Preencher
                    </IonButton>
                  </If>
                  <If
                    test={
                      procedure.situation === "REALIZADO" ||
                      procedure.doctor_id !== user.user_id
                    }
                  >
                    <IonButton
                      type="button"
                      routerLink={`/prescription/${procedure.id}`}
                      color="primary"
                      expand="block"
                    >
                      Prescrição
                    </IonButton>
                  </If>
                </IonCol>
                <IonCol size="6">
                  <If
                    test={
                      procedure.situation !== "SUSPENSO" &&
                      procedure.situation !== "CANCELADO" &&
                      procedure.situation !== "REALIZADO"
                    }
                  >
                    <IonButton
                      type="button"
                      onClick={() => suspend(procedure.id)}
                      color="danger"
                      expand="block"
                    >
                      Suspender
                    </IonButton>
                  </If>
                </IonCol>
                <IonCol size="6">
                  <If test={procedure.prescription !== null}>
                    <IonButton
                      type="button"
                      onClick={() => repeat(procedure.id)}
                      color="secondary"
                      expand="block"
                    >
                      Repetir
                    </IonButton>
                  </If>
                </IonCol>
                <IonCol size="6">
                  <If
                    test={
                      procedure.situation !== "REALIZADO" &&
                      procedure.doctor_id !== user.user_id
                    }
                  >
                    <IonButton
                      type="button"
                      onClick={() => assume(procedure.id)}
                      color="warning"
                      expand="block"
                    >
                      Assumir
                    </IonButton>
                  </If>
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <If
                  test={
                    procedure.situation === "REALIZADO" ||
                    procedure.situation === "ANDAMENTO"
                  }
                >
                  <If
                    test={
                      procedure.annotations && procedure.annotations > 0
                        ? true
                        : false
                    }
                  >
                    <IonCol size="6">
                      <IonButton
                        type="button"
                        routerLink={`/assists_annotations/${procedure.id}/${procedure.proc}`}
                        color="primary"
                        expand="block"
                      >
                        Anotações
                      </IonButton>
                    </IonCol>
                  </If>
                  <If
                    test={
                      procedure.evolution && procedure.evolution > 0
                        ? true
                        : false
                    }
                  >
                    <IonCol size="6">
                      <IonButton
                        type="button"
                        routerLink={`/assists_evolutions/${procedure.id}`}
                        color="medium"
                        expand="block"
                      >
                        Evolução
                      </IonButton>
                    </IonCol>
                  </If>
                </If>
              </IonRow>
            </IonCardContent>
          </IonCard>
        );
      })}
    </Main>
  );
};

const sessionKey = `${process.env.REACT_APP_SESSION_NAME}`;

const ProceduresForm = reduxForm<FormType, Props>({
  form: "procedures",
  initialValues: JSON.parse(
    `${localStorage.getItem(`${sessionKey}_doctor_procedures`)}`
  ),
})(Procedures);

const selector = formValueSelector("procedures");

const mapStateToProps = (state: RootState) => ({
  procedures: state.doctorProcedure.procedures,
  proceduresForm: selector(state, "hospital", "all", "situation", "date"),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getList, clearList, update, show, suspend, assume, repeat },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProceduresForm);
