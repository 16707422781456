import {
  ProgressForm,
  ProgressActionsTypes,
  GET_ADMINISTRATIVE_PROGRESS,
  CLEAR_ADMINISTRATIVE_PROGRESS,
} from "./types";
import { ThunkDispatch } from "redux-thunk";
import ErrorHandler from "../../../helpers/Legacy/errorsHandler";
import api from "../../../services/api";

const sessionKey = `${process.env.REACT_APP_SESSION_NAME}`;
export function getList(values: ProgressForm) {
  localStorage.setItem(
    `${sessionKey}_administrative_progress`,
    JSON.stringify(values)
  );
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .get("administrative/progress", {
        params: {
          hospital: values.hospital?.value,
        },
      })
      .then((response) => {
        dispatch({ type: GET_ADMINISTRATIVE_PROGRESS, payload: response.data });
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function clearList(): ProgressActionsTypes {
  return {
    type: CLEAR_ADMINISTRATIVE_PROGRESS,
    payload: undefined,
  };
}
