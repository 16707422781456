import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  formValueSelector,
} from "redux-form";
import { useLocation } from "react-router-dom";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLabel,
  IonImg,
} from "@ionic/react";

import Main from "../../components/Main";
import Input from "../../components/Legacy/Form/Input";
import If from "../../helpers/Legacy/if";

import { Doctor as DoctorTypes } from "../../store/doctor/user/types";
import { show, update } from "../../store/doctor/user/actions";
import { RootState } from "../../store";

interface StateProps {
  signature: string;
}

interface DispatchProps {
  show(): void;
  update(values: DoctorTypes): void;
}

type Props = StateProps & DispatchProps;

const DoctorData: React.FC<Props & InjectedFormProps<{}, Props>> = ({
  handleSubmit,
  show,
  update,
  signature,
}) => {
  const location = useLocation();

  useEffect(() => {
    show();
  }, [location, show]);

  return (
    <Main title="Dados do Médico">
      <IonCard>
        <IonCardContent>
          <form onSubmit={handleSubmit(update)}>
            <IonGrid>
              <IonRow>
                <Field
                  name="name"
                  type="text"
                  component={Input}
                  label="Nome"
                  autoCapitalize="on"
                  autoComplete="off"
                />
                <Field
                  name="cnpj"
                  type="text"
                  component={Input}
                  label="CNPJ"
                  autoComplete="off"
                />
                <Field
                  name="company"
                  type="text"
                  component={Input}
                  label="Empresa"
                  autoCapitalize="on"
                  autoComplete="off"
                />
                <Field
                  name="username"
                  type="text"
                  component={Input}
                  label="Login"
                  readOnly
                  autoComplete="off"
                />
                <Field
                  name="crm"
                  type="text"
                  component={Input}
                  label="CRM"
                  autoComplete="off"
                />
                <IonCol size="12">
                  <IonLabel>Assinatura</IonLabel>
                  <If test={signature ? true : false}>
                    <IonImg
                      src={signature}
                      alt="Assinatura"
                      style={{ width: "auto", height: "100px" }}
                    />
                  </If>
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="6">
                  <IonButton type="submit" color="primary" expand="block">
                    Salvar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
    </Main>
  );
};

const selector = formValueSelector("doctor");

const DoctorDataForm = reduxForm<{}, Props>({
  form: "doctor",
})(DoctorData);

const mapStateToProps = (state: RootState) => ({
  signature: selector(state, "signature"),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ show, update }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DoctorDataForm);
