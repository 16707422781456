import Yup from "../../services/Legacy/yup";
import { getType } from "../../shared/Procedures";

const prescription_schema = Yup.object().shape({
  // medical_report: Yup.string()
  //   .nullable()
  //   .when('sessions', {
  //     is: (sessions: any) =>
  //       parseInt(sessions) === 0 || (parseInt(sessions) + 1) % 5 === 0,
  //     then: Yup.string().when('proc', {
  //       is: (proc: any) => proc !== 'CVVHD' && proc !== 'CVVHDF',
  //       then: Yup.string().required().label('Relatório Médico')
  //     })
  //   })
  //   .when('sessions', {
  //     is: (sessions: any) =>
  //       parseInt(sessions) === 0 || (parseInt(sessions) + 1) % 3 === 0,
  //     then: Yup.string().when('proc', {
  //       is: (proc: any) =>
  //         proc === 'CVVHD' ||
  //         proc === 'CVVHDF' ||
  //         proc.substr(0, 27) === 'PACOTE HEMODIÁLISE CONTÍNUA',
  //       then: Yup.string().required().label('Relatório Médico')
  //     })
  //   }),
  index: Yup.string().required().label("Índice de Captação"),
  precaution: Yup.boolean().required().label("Precaução"),
  catheter_reason: Yup.string()
    .nullable()
    .when("catheter_type", {
      is: (value: any) => value === "T",
      then: Yup.string().required().label("Motivo da Troca"),
    }),
  thenkoff: Yup.string()
    .nullable()
    .when("catheter", {
      is: (value: any) => value === "S",
      then: Yup.string().when("proc", {
        is: (proc: any) => getType(proc) === "DP",
        then: Yup.string().required().label("Thenkoff"),
      }),
    }),
  catheter_type: Yup.string()
    .nullable()
    .when("catheter", {
      is: (value: any) => value === "S",
      then: Yup.string().required().label("Cateter Tipo"),
    }),
  catheter: Yup.string().required().label("Implantou Cateter?"),
  site: Yup.string()
    .nullable()
    .when("access", {
      is: (value: any) => value !== "TENCKHOFF",
      then: Yup.string().required().label("Sitio"),
    }),
  access: Yup.string().required().label("Acesso"),
  time: Yup.number().min(1).required().label("Tempo"),
  // proc: Yup.string().required().label('Procedimento'),
  bed: Yup.string().required().label("Leito"),
  location: Yup.string().required().label("Localização"),
  hospital: Yup.object().shape({
    value: Yup.number().required("Hospital").label("Hospital"),
  }),
  new_patient: Yup.string().required().label("Paciente Novo?"),
  date: Yup.string().required().label("Data"),
  patient: Yup.object().shape({
    value: Yup.number().required().label("Paciente"),
  }),
});

export default prescription_schema;
