import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  formValueSelector,
} from "redux-form";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSelectOption,
} from "@ionic/react";

import { RootState } from "../../store";
import { create } from "../../store/driver/machines/actions";

import Main from "../../components/Main";
import Input from "../../components/Legacy/Form/Input";
import Select from "../../components/Legacy/Form/Select";
import HomeCareSearch from "../../components/Legacy/Search/HomeCare";
import UserSearch from "../../components/Legacy/Search/User";
import Textarea from "../../components/Legacy/Form/Textarea";
import DateInput from "../../components/Legacy/Form/DateInput";
import TimeInput from "../../components/Legacy/Form/TimeInput";
import If from "../../helpers/Legacy/if";

interface StateProps {
  formSelector: {
    machine_type?: "H" | "T" | "C";
    external_damages?: "N" | "Y";
  };
}

interface DispatchProps {
  create(values: any): void;
}

type Props = StateProps & DispatchProps;

const DriverChecklist: React.FC<Props & InjectedFormProps<{}, Props>> = ({
  handleSubmit,
  formSelector,
  create,
}) => {
  return (
    <Main title="Máquina (Checklist)">
      <IonCard>
        <IonCardContent>
          <form onSubmit={handleSubmit(create)}>
            <IonGrid>
              <IonRow className="ion-align-items-end">
                <Field
                  name="source"
                  component={HomeCareSearch}
                  label="Origem"
                  required
                />
                <Field
                  name="destination"
                  component={HomeCareSearch}
                  label="Destino"
                  required
                />
                <Field
                  name="serial"
                  component={Input}
                  label="Número de Série"
                  required
                  readOnly
                />
                <Field
                  name="reason"
                  component={Select}
                  label="Motivo da Retirada"
                  required
                >
                  <IonSelectOption value="P">Planejada</IonSelectOption>
                  <IonSelectOption value="C">Corretiva</IonSelectOption>
                  <IonSelectOption value="D">Demanda</IonSelectOption>
                  <IonSelectOption value="T">
                    Tranferencia entre unidades/hospitais
                  </IonSelectOption>
                </Field>
                <Field
                  name="machine_type"
                  component={Select}
                  label="Tipo de equipamento"
                  readOnly
                >
                  <IonSelectOption value="H">Máquina HD</IonSelectOption>
                  <IonSelectOption value="T">Osmose Portátil</IonSelectOption>
                  <IonSelectOption value="C">Home Choice</IonSelectOption>
                </Field>
                <If test={formSelector.machine_type === "H"}>
                  <Field
                    size="6"
                    name="water_hose"
                    component={Select}
                    label="Mangueira de Água"
                    required
                  >
                    <IonSelectOption value="N">Não</IonSelectOption>
                    <IonSelectOption value="Y">Sim</IonSelectOption>
                  </Field>
                  <Field
                    size="6"
                    name="sewer_hose"
                    component={Select}
                    label="Mangueira de Esgoto"
                    required
                  >
                    <IonSelectOption value="N">Não</IonSelectOption>
                    <IonSelectOption value="Y">Sim</IonSelectOption>
                  </Field>
                  <Field
                    size="6"
                    name="serum_support"
                    component={Select}
                    label="Suporte de Soro/Medicação"
                    required
                  >
                    <IonSelectOption value="N">Não</IonSelectOption>
                    <IonSelectOption value="Y">Sim</IonSelectOption>
                  </Field>
                  <Field
                    size="6"
                    name="power_extension"
                    component={Select}
                    label="Extensão de Energia"
                    required
                  >
                    <IonSelectOption value="N">Não</IonSelectOption>
                    <IonSelectOption value="Y">Sim</IonSelectOption>
                  </Field>
                  <Field
                    size="6"
                    name="pump_crank"
                    component={Select}
                    label="Manivela da Bomba de Sangue"
                    required
                  >
                    <IonSelectOption value="N">Não</IonSelectOption>
                    <IonSelectOption value="Y">Sim</IonSelectOption>
                  </Field>
                  <Field
                    size="6"
                    name="bag_holder"
                    component={Select}
                    label="Suporte da Bolsa da Balança"
                    required
                  >
                    <IonSelectOption value="N">Não</IonSelectOption>
                    <IonSelectOption value="Y">Sim</IonSelectOption>
                  </Field>
                  <Field
                    size="6"
                    name="capillary_support"
                    component={Select}
                    label="Suporte do Capilar"
                    required
                  >
                    <IonSelectOption value="N">Não</IonSelectOption>
                    <IonSelectOption value="Y">Sim</IonSelectOption>
                  </Field>
                  <Field
                    size="6"
                    name="clamp"
                    component={Select}
                    label="Braçadeira"
                    required
                  >
                    <IonSelectOption value="N">Não</IonSelectOption>
                    <IonSelectOption value="Y">Sim</IonSelectOption>
                  </Field>
                </If>
                <If test={formSelector.machine_type === "T"}>
                  <Field
                    size="6"
                    name="faucet_hose"
                    component={Select}
                    label="Mangueira Torneira/Osmose"
                    required
                  >
                    <IonSelectOption value="N">Não</IonSelectOption>
                    <IonSelectOption value="Y">Sim</IonSelectOption>
                  </Field>
                  <Field
                    size="6"
                    name="machine_hose"
                    component={Select}
                    label="Mangueira Osmose/Máquina"
                    required
                  >
                    <IonSelectOption value="N">Não</IonSelectOption>
                    <IonSelectOption value="Y">Sim</IonSelectOption>
                  </Field>
                  <Field
                    size="6"
                    name="drain_hose"
                    component={Select}
                    label="Mangueira do Dreno"
                    required
                  >
                    <IonSelectOption value="N">Não</IonSelectOption>
                    <IonSelectOption value="Y">Sim</IonSelectOption>
                  </Field>
                </If>
                <Field
                  size="6"
                  name="quick_connector"
                  component={Select}
                  label="Conector de Engate Rápido"
                  required
                >
                  <IonSelectOption value="N">Não</IonSelectOption>
                  <IonSelectOption value="Y">Sim</IonSelectOption>
                </Field>
                <Field
                  size="6"
                  name="disinfection_control"
                  component={Select}
                  label="Ficha de Controle de Desinfecção"
                  required
                >
                  <IonSelectOption value="N">Não</IonSelectOption>
                  <IonSelectOption value="Y">Sim</IonSelectOption>
                </Field>
                <Field
                  size="6"
                  name="clean_equipment"
                  component={Select}
                  label="Equipamento Limpo"
                  required
                >
                  <IonSelectOption value="N">Não</IonSelectOption>
                  <IonSelectOption value="Y">Sim</IonSelectOption>
                </Field>
                <Field
                  size="6"
                  name="external_damages"
                  component={Select}
                  label="Danos Externos Aparente:"
                  required
                >
                  <IonSelectOption value="N">Não</IonSelectOption>
                  <IonSelectOption value="Y">Sim</IonSelectOption>
                </Field>
                <If test={formSelector.external_damages === "Y"}>
                  <Field
                    name="damages"
                    component={Textarea}
                    label="Quais Danos?"
                  />
                </If>
                <Field name="obs" component={Textarea} label="Observação" />
                <Field
                  name="received_by"
                  component={UserSearch}
                  label="Nome do responsável Recebedor"
                  required
                />
                <Field
                  size="6"
                  name="received_at_date"
                  component={DateInput}
                  label="Data"
                  required
                />
                <Field
                  size="6"
                  name="received_at_time"
                  component={TimeInput}
                  label="Hora"
                  required
                />
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="6">
                  <IonButton type="submit" color="primary" expand="block">
                    Salvar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
    </Main>
  );
};

const DriverChecklistForm = reduxForm<{}, Props>({
  form: "driver_machine_checklist",
})(DriverChecklist);

const selector = formValueSelector("driver_machine_checklist");

const mapStateToProps = (state: RootState) => ({
  formSelector: selector(state, "machine_type", "external_damages"),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ create }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverChecklistForm);
