import React, { useCallback, useEffect, useState } from "react";
import { IonCol, IonLabel } from "@ionic/react";
import Select, { Props as SelectProps } from "react-select";

import api from "../../../services/api";

interface Props {
  size?: string;
  label: string;
  value: SelectProps;
  onChange(value: SelectProps): void;
}

const SearchInput: React.FC<Props> = ({
  size,
  label,
  value,
  onChange,
  ...rest
}) => {
  const [technicians, setTechnicians] = useState([]);

  const searchTechnicians = useCallback((searchValue?: string | null) => {
    api
      .get<any>("search/technician", {
        params: { search: searchValue },
      })
      .then((resp) => {
        setTechnicians(resp.data);
      });
  }, []);

  useEffect(() => {
    searchTechnicians();
  }, [searchTechnicians]);

  return (
    <IonCol size={size || "12"}>
      <IonLabel position="stacked">{label}</IonLabel>
      <Select
        style={{ width: "100%" }}
        placeholder=""
        classNamePrefix="select"
        value={value}
        onChange={(value) => {
          if (value) {
            onChange(value);
          } else {
            onChange({} as SelectProps);
          }
        }}
        onInputChange={(value) => searchTechnicians(value)}
        noOptionsMessage={() => "Nenhuma opção"}
        simpleValue
        options={technicians}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        {...rest}
      />
    </IonCol>
  );
};

export default SearchInput;
