import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { IonCol, IonLabel, IonDatetime } from "@ionic/react";

interface Props {
  name: string;
  label?: string;
  size?: string;
  readOnly?: boolean;
}

const Input: React.FC<Props> = ({ name, label, size, readOnly, ...rest }) => {
  const inputRef = useRef<HTMLIonDatetimeElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <IonCol size={size || "12"}>
      <IonLabel position="stacked">{label}</IonLabel>
      <IonDatetime
        ref={inputRef}
        name={name}
        defaultValue={defaultValue}
        cancelText="Cancelar"
        doneText="Ok"
        pickerFormat="HH:mm"
        displayFormat="HH:mm"
        readonly={readOnly ? true : false}
        {...rest}
        style={{
          marginTop: "5px",
          height: "42px",
          width: "100%",
          padding: "6px 12px",
          color: "#555555",
          backgroundColor: "#FFFFFF",
          backgroundImage: "none",
          borderColor: "hsl(0, 0%, 80%)",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "1px",
          lineHeight: 2,
        }}
      />
    </IonCol>
  );
};

export default Input;
