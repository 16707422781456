import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useLocation } from "react-router-dom";
import {
  IonCard,
  IonCardContent,
  IonRow,
  IonCol,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonText,
  IonButton,
  IonGrid,
  IonSelectOption,
  IonSelect,
  useIonViewDidEnter,
} from "@ionic/react";

import { RootState } from "../../store";
import { getList, clearList, show } from "../../store/driver/machines/actions";
import { Machine } from "../../store/driver/machines/types";

import Main from "../../components/Main";
import dateToString from "../../helpers/Legacy/dateToString";
import api from "../../services/api";

interface StateProps {
  list: Machine[];
}

interface DispatchProps {
  getList(): void;
  clearList(): void;
  show(values: any): void;
}

type Props = StateProps & DispatchProps;

interface Car {
  id: number;
  label: string;
}

const sessionName = process.env.REACT_APP_SESSION_NAME;

const DriverMachines: React.FC<Props> = ({
  list,
  getList,
  clearList,
  show,
}) => {
  const location = useLocation();

  const [cars, setCars] = useState<Car[]>([]);
  const [carPlate, setCarPlate] = useState<number>(0);

  useIonViewDidEnter(() => {
    getList();
  }, [location, getList]);

  useIonViewDidEnter(async () => {
    await api.get<any>("driver/cars").then((response) => {
      setCars(response.data);
      setCarPlate(
        parseInt(localStorage.getItem(`@${sessionName}:carPlate`) || "0")
      );
    });
  }, []);

  const CarPlateChange = (newCarPlate: number) => {
    localStorage.setItem(`@${sessionName}:carPlate`, String(newCarPlate));
    setCarPlate(newCarPlate);
    getList();
  };

  return (
    <Main title="Máquinas">
      <IonCard>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <IonLabel position="stacked">Placa do Carro</IonLabel>
                <IonSelect
                  value={carPlate}
                  onIonChange={(event) => CarPlateChange(event.detail.value)}
                  cancelText="Cancelar"
                  okText="Ok"
                  interface="action-sheet"
                  style={{
                    marginTop: "5px",
                    height: "42px",
                    width: "100%",
                    padding: "6px 12px",
                    color: "#555555",
                    backgroundColor: "#FFFFFF",
                    backgroundImage: "none",
                    borderColor: "hsl(0, 0%, 80%)",
                    borderRadius: "4px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    lineHeight: 2,
                  }}
                >
                  <IonSelectOption value={0}>TODOS</IonSelectOption>
                  {cars.map((car) => (
                    <IonSelectOption key={car.id} value={car.id}>
                      {car.label}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
      {list
        .filter((values) => values.car_plate === carPlate || carPlate === 0)
        .map((values) => {
          return (
            <IonCard key={values.machine_id}>
              <IonCardHeader>
                <IonCardTitle>Número: {values.number}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonRow>
                  <IonCol size="12">
                    <IonLabel>Data de Movimentação: </IonLabel>
                    <IonText>{dateToString(values.date)}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Serial: </IonLabel>
                    <IonText>{values.serial}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Tipo: </IonLabel>
                    <IonText>
                      {values.machine_type === "H"
                        ? "Máquina HD"
                        : values.machine_type === "T"
                        ? "Osmose Portátil"
                        : values.machine_type === "C"
                        ? "Home Choice"
                        : ""}
                    </IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Origem: </IonLabel>
                    <IonText>{values.source?.label}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Destino: </IonLabel>
                    <IonText>{values.destination?.label}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-margin-top">
                  <IonCol>
                    <IonButton
                      type="button"
                      onClick={() => show(values)}
                      routerLink="/driver/machine/checklist"
                      color="primary"
                      expand="block"
                    >
                      Confirmar
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          );
        })}
    </Main>
  );
};

const mapStateToProps = (state: RootState) => ({
  list: state.driver_machine.list,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getList, clearList, show }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DriverMachines);
