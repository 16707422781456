import { Doctor } from "./types";
import { initialize } from "redux-form";
import { ThunkDispatch } from "redux-thunk";
import { showToast } from "../../toast/actions";
import ErrorHandler from "../../../helpers/Legacy/errorsHandler";
import api from "../../../services/api";

export function show() {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .get("doctor")
      .then((response) => {
        dispatch(initialize("doctor", response.data));
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function update(values: Doctor) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .put("doctor", values)
      .then((response) => {
        dispatch(showToast("Dados salvos com sucesso", "success"));
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}
