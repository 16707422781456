import {
  PrescriptionActionsTypes,
  GET_PRESCRIPTION,
  CLEAR_PRESCRIPTION,
} from "./types";
import { ThunkDispatch } from "redux-thunk";
import ErrorHandler from "../../helpers/Legacy/errorsHandler";
import { ToastActionsTypes } from "../toast/types";
import api from "../../services/api";

export function show(id: string) {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      PrescriptionActionsTypes | ToastActionsTypes
    >
  ) => {
    await api
      .get<any>(`prescription/${id}`)
      .then((response) => {
        dispatch({ type: GET_PRESCRIPTION, payload: response.data });
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function clear(): PrescriptionActionsTypes {
  return {
    type: CLEAR_PRESCRIPTION,
    payload: undefined,
  };
}
