import Yup from "../../services/Legacy/yup";

const patients_control_schema = Yup.object().shape({
  bed: Yup.string().required().label("leito"),
  location: Yup.string().required().label("localização"),
  hospital_id: Yup.number().required().label("hospital"),
  control: Yup.string().required().label("controle de paciente"),
  date: Yup.string().required().label("data"),
  patient_id: Yup.number().required().label("paciente"),
});

export default patients_control_schema;
