export interface Procedure {
  id: number;
  situation: string;
  proc: string;
  date: string;
  hospital: string;
  patient: string;
  technician: string;
  doctor_id: number;
  doctor: string;
  destination: string;
  event: string;
  prescription: number;
  annotations: number;
  evolution: number;
  start: string;
  end: string;
  medical_report: string;
}

export interface Procedures {
  procedures: Procedure[];
}

export interface ProcedureForm {
  hospital: {
    value: number;
    label: string;
  };
  all: boolean;
  situation: string;
  date: string;
}

export const GET_PROCEDURES = "GET_PROCEDURES";
export const CLEAR_PROCEDURES = "CLEAR_PROCEDURES";
export const UPDATE_PROCEDURE = "UPDATE_PROCEDURE";

interface GetProceduresActions {
  type: typeof GET_PROCEDURES;
  payload: Procedure[];
}

interface ClearProceduresActions {
  type: typeof CLEAR_PROCEDURES;
  payload: undefined;
}

interface UpdateProcedureActions {
  type: typeof UPDATE_PROCEDURE;
  payload: Procedure;
}

export type ProcedureActionsTypes =
  | GetProceduresActions
  | ClearProceduresActions
  | UpdateProcedureActions;
