const INITIAL_STATE = { list: [] }

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ASSISTANCE_NURSING_FETCHING':
      return { ...state, list: action.payload }
    case 'ASSISTANCE_NURSING_CLEAR':
      return { ...state, list: [] }
    default:
      return state
  }
}
