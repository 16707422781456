import React, { useState } from "react";
import { IonIcon } from "@ionic/react";
import { refresh } from "ionicons/icons";
import {
  IonCard,
  IonCardContent,
  IonText,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonButton,
} from "@ionic/react";
import api from "../../../services/api";

import If from "../../../helpers/Legacy/if";
import dateToString from "../../../helpers/Legacy/dateToString";
import Input from "../../../components/Legacy/Form/Input";
import TimeInput from "../../../components/Legacy/Form/TimeInput";
import TechnicianSearch from "../../../components/Legacy/Search/TechnicianOld";

function AssistanceNursingCard(props) {
  const { update } = props;
  const { d = [] } = props;

  const [local, setLocal] = useState(d.quarto || "CTI");
  const [situation, setSituation] = useState(d.situation || "");
  const [bed, setBed] = useState(d.bed || "");
  const [technician1, setTechnician1] = useState(
    d.technician1 ? { value: d.technician1, label: d.technician1_name } : ""
  );
  const [technician2, setTechnician2] = useState(
    d.technician2 ? { value: d.technician2, label: d.technician2_name } : ""
  );
  const [technician3, setTechnician3] = useState(
    d.technician3 ? { value: d.technician3, label: d.technician3_name } : ""
  );
  const [tec1start, setTec1start] = useState(d.tec1start || null);
  const [tec2start, setTec2start] = useState(d.tec2start || null);
  const [tec3start, setTec3start] = useState(d.tec3start || null);
  const [simultaneo, setSimultaneo] = useState(
    d.id_simultaneo ? d.id_simultaneo : ""
  );
  const [simultaneous, setSimultaneous] = useState([]);

  function handleLocal(value) {
    setLocal(value);
    update(d.id, { local: value });
  }

  function handleBed(value) {
    setBed(value);
    update(d.id, { bed: value });
  }

  function handleTechnician1(value) {
    api
      .put(`assistance_nursing/${d.id}`, { technician: value.value })
      .then((resp) => {
        setTechnician1(value);
        setSituation(resp.data.situation);
        if (tec1start !== null) {
          setSituation("AGENDADO");
        }
      });
  }

  function handleTechnician2(value) {
    api
      .put(`assistance_nursing/${d.id}`, { technician2: value.value })
      .then((resp) => {
        setTechnician2(value);
        setSituation(resp.data.situation);
      });
  }

  function handleTechnician3(value) {
    api
      .put(`assistance_nursing/${d.id}`, { technician3: value.value })
      .then((resp) => {
        setTechnician3(value);
        setSituation(resp.data.situation);
      });
  }

  function handleStartTime1(value) {
    api
      .put(`assistance_nursing/${d.id}`, { start: value, tec1start: value })
      .then((resp) => {
        setTec1start(value);
        if (resp.data.situation === "AGENDADO") {
          setSituation("AGENDADO");
        }
      });
  }

  function handleStartTime2(value) {
    api.put(`assistance_nursing/${d.id}`, { tec2start: value }).then((resp) => {
      setTec2start(value);
      if (resp.data.situation === "AGENDADO") {
        setSituation("AGENDADO");
      }
    });
  }

  function handleStartTime3(value) {
    api.put(`assistance_nursing/${d.id}`, { tec3start: value }).then((resp) => {
      setTec3start(value);
      if (resp.data.situation === "AGENDADO") {
        setSituation("AGENDADO");
      }
    });
  }

  function handleSimultaneous(value) {
    update(d.id, { simultaneous: value });
    setSimultaneo(value);
  }

  async function loadSimultaneous(hospital, date, tec, id) {
    const request = await api.get(`assistance_nursing/simultaneous`, {
      params: { hospital, date, tec, id },
    });

    setSimultaneous(request.data || []);
  }

  function renderSimultaneous() {
    return simultaneous.map((d) => (
      <IonSelectOption value={d.id} key={d.id}>
        {d.id} - {d.name}
      </IonSelectOption>
    ));
  }

  function handleSituation() {
    api
      .put(`assistance_nursing/${d.id}`, { situation: "SEMRH" })
      .then((resp) => {
        setSituation(resp.data.situation);
      });
  }

  return (
    <IonCard
      className={`${
        technician1 === "" ? "card-danger" : `card-${situation.toLowerCase()}`
      } ${
        d.urgent && (situation === "PENDENTE" || situation === "AGENDADO")
          ? "urgent"
          : ""
      }`}
    >
      <IonCardContent>
        <IonRow>
          <IonCol size="12">
            <IonLabel>Data: </IonLabel>
            <IonText>{dateToString(d.dias)}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Id: </IonLabel>
            <IonText>{d.id}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Proc: </IonLabel>
            <IonText>{d.proc}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Situação: </IonLabel>
            <IonText>{situation}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Hospital: </IonLabel>
            <IonText>{d.hospital}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Paciente: </IonLabel>
            <IonText>{d.patient}</IonText>
          </IonCol>
        </IonRow>
        <If test={situation !== "REALIZADO"}>
          <IonRow>
            <IonCol size="12">
              <IonLabel>Localização:</IonLabel>
              <IonSelect
                placeholder="Selecione..."
                interface="action-sheet"
                cancelText="Cancelar"
                value={local}
                onIonChange={(e) => handleLocal(e.target.value)}
              >
                <IonSelectOption value="CTI">CTI</IonSelectOption>
                <IonSelectOption value="ENFERMARIA">ENFERMARIA</IonSelectOption>
                <IonSelectOption value="QUARTO">QUARTO</IonSelectOption>
                <IonSelectOption value="POI">POI</IonSelectOption>
                <IonSelectOption value="PÓS">PÓS</IonSelectOption>
                <IonSelectOption value="UCO">UCO</IonSelectOption>
                <IonSelectOption value="UCI">UCI</IonSelectOption>
                <IonSelectOption value="UCIC">UCIC</IonSelectOption>
                <IonSelectOption value="OUTROS">OUTROS</IonSelectOption>
              </IonSelect>
            </IonCol>

            <Input
              label="Leito:"
              input={{ value: bed, onChange: (value) => handleBed(value) }}
            />
          </IonRow>
        </If>
        <IonRow>
          <TechnicianSearch
            label="Técnico 1:"
            input={{
              value: technician1,
              onChange: (value) => handleTechnician1(value),
            }}
            required
          />
          <If test={technician1 !== ""}>
            <TimeInput
              size="12"
              label="Hora de Início do Técnico 1: "
              input={{
                value: tec1start,
                onChange: (value) => handleStartTime1(value.substr(11, 8)),
              }}
              required
            />
          </If>
          <If test={tec1start !== null}>
            <TechnicianSearch
              label="Técnico 2:"
              input={{
                value: technician2,
                onChange: (value) => handleTechnician2(value),
              }}
              required
            />
            <If test={technician2 !== ""}>
              <TimeInput
                size="12"
                label="Hora de Início do Técnico 2: "
                input={{
                  value: tec2start,
                  onChange: (value) => handleStartTime2(value.substr(11, 8)),
                }}
                required
              />
            </If>
          </If>
          <If test={tec2start !== null}>
            <TechnicianSearch
              label="Técnico 3:"
              input={{
                value: technician3,
                onChange: (value) => handleTechnician3(value),
              }}
              required
            />
            <If test={technician3 !== ""}>
              <TimeInput
                size="12"
                label="Hora de Início do Técnico 3: "
                input={{
                  value: tec3start,
                  onChange: (value) => handleStartTime3(value.substr(11, 8)),
                }}
                required
              />
            </If>
          </If>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            {!technician1.value ? (
              <>
                <IonLabel>Simultâneo</IonLabel>
                <p>Atribua um técnico primeiro!</p>
              </>
            ) : situation === "REALIZADO" ? (
              <>
                <IonLabel>Simultâneo</IonLabel>
                {d.id_simultaneo ? (
                  <p>
                    {d.id_simultaneo} - {d.patient_simultaneo}
                  </p>
                ) : (
                  <p>Não houve!</p>
                )}
              </>
            ) : (
              <>
                <IonLabel>Simultâneo</IonLabel>
                <IonButton
                  color="secondary"
                  type="button"
                  size="small"
                  onClick={() =>
                    loadSimultaneous(d.id_hospital, d.dias, d.tec, d.id)
                  }
                >
                  <IonIcon icon={refresh} size="small" />
                </IonButton>
                <IonSelect
                  placeholder="Selecione..."
                  interface="action-sheet"
                  cancelText="Cancelar"
                  value={simultaneo}
                  onIonChange={(e) => handleSimultaneous(e.target.value)}
                >
                  {renderSimultaneous()}
                </IonSelect>
              </>
            )}
          </IonCol>
        </IonRow>
        <If
          test={
            situation !== "ANDAMENTO" &&
            situation !== "REALIZADO" &&
            situation !== "SEMRH"
          }
        >
          <IonRow className="ion-margin-top">
            <IonCol size="12">
              <IonButton
                type="button"
                color="danger"
                expand="block"
                onClick={() => handleSituation()}
              >
                Colocar como SEM RH
              </IonButton>
            </IonCol>
          </IonRow>
        </If>
      </IonCardContent>
    </IonCard>
  );
}

export default AssistanceNursingCard;
