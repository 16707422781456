import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { useLocation } from "react-router-dom";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonText,
} from "@ionic/react";

import { RootState } from "../../store";
import {
  getList,
  clearList,
} from "../../store/administrative/assistances_progress/actions";
import {
  Progress,
  ProgressForm,
} from "../../store/administrative/assistances_progress/types";

import Main from "../../components/Main";
import HospitalSearch from "../../components/Legacy/Search/Hospital";
import If from "../../helpers/Legacy/if";
import dateToString from "../../helpers/Legacy/dateToString";

interface StateProps {
  list: Progress[];
}

interface DispatchProps {
  getList(values: ProgressForm): void;
  clearList(): void;
}

type Props = StateProps & DispatchProps;

const AdministrativeProgress: React.FC<
  Props & InjectedFormProps<ProgressForm, Props>
> = ({ handleSubmit, getList, clearList, list }) => {
  const location = useLocation();

  useEffect(() => {
    clearList();
  }, [location, clearList]);

  return (
    <Main title="Em Andamento">
      <IonCard>
        <IonCardContent>
          <form onSubmit={handleSubmit(getList)}>
            <IonGrid>
              <IonRow>
                <Field name="hospital" component={HospitalSearch} required />
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol>
                  <IonButton type="submit" color="primary" expand="block">
                    Consultar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
      {list.map((values) => {
        return (
          <IonCard
            key={values.id}
            className={`card-${values.situation.toLowerCase()}`}
          >
            <IonCardHeader>
              <IonCardTitle>Situação: {values.situation}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonRow>
                <IonCol size="12">
                  <IonLabel>Id: </IonLabel>
                  <IonText>{values.id}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Data: </IonLabel>
                  <IonText>{dateToString(values.date)}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Hospital: </IonLabel>
                  <IonText>{values.hospital}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Paciente: </IonLabel>
                  <IonText>{values.patient}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Novo: </IonLabel>
                  <IonText>
                    {values.new_patient === "S" ? "Sim" : "Não"}
                  </IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Localização: </IonLabel>
                  <IonText>{values.location}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Leito: </IonLabel>
                  <IonText>{values.bed}</IonText>
                </IonCol>
                <If test={values.technician1 ? true : false}>
                  <IonCol size="12">
                    <IonLabel>Técnico 1: </IonLabel>
                    <IonText>{values.technician1}</IonText>
                  </IonCol>
                </If>
                <If test={values.technician2 ? true : false}>
                  <IonCol size="12">
                    <IonLabel>Técnico 2: </IonLabel>
                    <IonText>{values.technician2}</IonText>
                  </IonCol>
                </If>
                <If test={values.technician3 ? true : false}>
                  <IonCol size="12">
                    <IonLabel>Técnico 3: </IonLabel>
                    <IonText>{values.technician3}</IonText>
                  </IonCol>
                </If>
                <IonCol size="12">
                  <IonLabel>Destino: </IonLabel>
                  <IonText>{values.destination}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Evento: </IonLabel>
                  <IonText>{values.event}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Procedimento: </IonLabel>
                  <IonText>{values.proc}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Tempo (Horas): </IonLabel>
                  <IonText>{values.time}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Início: </IonLabel>
                  <IonText>{values.start}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Fim: </IonLabel>
                  <IonText>{values.end}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Simultâneo: </IonLabel>
                  <IonText>{values.simultaneous_id}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Acesso: </IonLabel>
                  <IonText>{values.access}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Sitio: </IonLabel>
                  <IonText>{values.site}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Maq.: </IonLabel>
                  <IonText>{values.machine}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>OR: </IonLabel>
                  <IonText>{values.osmosis}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Home Choice: </IonLabel>
                  <IonText>{values.home_choice}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Médico: </IonLabel>
                  <IonText>{values.doctor}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Convênio: </IonLabel>
                  <IonText>{values.agreement}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Matrícula: </IonLabel>
                  <IonText>{values.registration}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Observação: </IonLabel>
                  <IonText>{values.note}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Válidado: </IonLabel>
                  <IonText>{values.validated === "S" ? "Sim" : "Não"}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Folha: </IonLabel>
                  <IonText>{values.report}</IonText>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
        );
      })}
    </Main>
  );
};

const sessionKey = `${process.env.REACT_APP_SESSION_NAME}`;

const AdministrativeProgressForm = reduxForm<ProgressForm, Props>({
  form: "administrative_progress",
  initialValues: JSON.parse(
    `${localStorage.getItem(`${sessionKey}_administrative_progress`)}`
  ),
})(AdministrativeProgress);

const mapStateToProps = (state: RootState) => ({
  list: state.administrative_progress.list,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getList, clearList }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdministrativeProgressForm);
