import React from "react";
import { IonCol, IonLabel } from "@ionic/react";
import InputMask from "react-input-mask";

interface Props {
  input: {
    name: string;
    value: string;
    onChange(e: string | null | undefined): void;
    onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
    onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
  };
  type:
    | "date"
    | "datetime-local"
    | "email"
    | "month"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
  label: string;
  mask: string;
  max?: string;
  min?: string;
  readOnly?: boolean;
  required?: boolean;
  size?: string;
}

const Input: React.FC<Props> = (props) => {
  return (
    <IonCol size={props.size || "12"}>
      <IonLabel position="stacked" style={{ color: "#000", fontWeight: 600 }}>
        {props.label}
      </IonLabel>
      <InputMask
        name={props.input.name}
        type={props.type}
        value={props.input.value}
        onChange={(e) => props.input.onChange(e.target.value)}
        onBlur={(e) => !!props.input.onBlur && props.input.onBlur(e)}
        onFocus={(e) => !!props.input.onFocus && props.input.onFocus(e)}
        mask={props.mask}
        className="mask"
        max={props.max}
        min={props.min}
        disabled={props.readOnly ? true : false}
        required={props.required ? true : false}
        style={{
          marginTop: "5px",
          height: "42px",
          width: "100%",
          padding: "6px 12px",
          color: "#555555",
          backgroundColor: "#FFFFFF",
          backgroundImage: "none",
          borderColor: "hsl(0, 0%, 80%)",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "1px",
          lineHeight: 2,
        }}
      />
    </IonCol>
  );
};

export default Input;
