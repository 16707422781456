import React, { useEffect, useState } from "react";
import { IonCol, IonLabel } from "@ionic/react";
import Select from "react-select";

import api from "../../../services/api";

interface Props {
  input: {
    value: any;
  };
  label: string;
  size?: string;
}

const SearchInput: React.FC = (props: any) => {
  const [homeCare, setHomeCare] = useState([]);

  useEffect(() => {
    searchHomeCare();
  }, []);

  function searchHomeCare(value?: string | null) {
    api
      .get<any>("search/homecare", { params: { search: value } })
      .then((resp) => {
        setHomeCare(resp.data);
      });
  }

  return (
    <IonCol size={props.size || "12"}>
      <IonLabel position="stacked">{props.label}</IonLabel>
      <Select
        style={{ width: "100%" }}
        placeholder=""
        classNamePrefix="select"
        value={props.input.value ? props.input.value : ""}
        onChange={(value) => {
          if (value) {
            props.input.onChange(value);
          } else {
            props.input.onChange("");
          }
        }}
        onInputChange={(value) => searchHomeCare(value)}
        noOptionsMessage={() => "Nenhuma opção"}
        simpleValue
        isDisabled={props.readOnly}
        isClearable={props.isClearable}
        required={props.required}
        options={homeCare}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </IonCol>
  );
};

export default SearchInput;
