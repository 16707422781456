interface Props {
  value?: "N" | "S";
  upper?: Boolean;
}

export default function (props: Props) {
  if (props.value === "N") {
    if (props.upper === true) {
      return "NORMAL";
    } else {
      return "Normal";
    }
  } else if (props.value === "S") {
    if (props.upper === true) {
      return "THENKOFF";
    } else {
      return "Thenkoff";
    }
  } else {
    return "";
  }
}
