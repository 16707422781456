import {
  MachineOrders,
  MachineOrderActionsTypes,
  GET_MACHINE_ORDERS,
} from "./types";

const initialState: MachineOrders = {
  list: [],
};

export function machineOrderReducer(
  state = initialState,
  action: MachineOrderActionsTypes
): MachineOrders {
  switch (action.type) {
    case GET_MACHINE_ORDERS:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
}
