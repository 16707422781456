import {
  Prescription,
  PrescriptionActionsTypes,
  GET_PRESCRIPTION,
  CLEAR_PRESCRIPTION,
} from "./types";

const initialState: Prescription = {};

export function prescriptionReducer(
  state = initialState,
  action: PrescriptionActionsTypes
): Prescription {
  switch (action.type) {
    case GET_PRESCRIPTION:
      return {
        ...(state = action.payload),
      };
    case CLEAR_PRESCRIPTION:
      return {
        ...(state = initialState),
      };
    default:
      return state;
  }
}
