import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IonToast } from "@ionic/react";

import { RootState } from "../../store";
import { Toast as ToastType } from "../../store/toast/types";
import { hideToast } from "../../store/toast/actions";

interface StateProps {
  toast: ToastType;
}

interface DispatchProps {
  hideToast(): void;
}

type Props = StateProps & DispatchProps;

const Toast: React.FC<Props> = ({ toast, hideToast }) => {
  return (
    <IonToast
      isOpen={toast.isOpen}
      onDidDismiss={hideToast}
      message={toast.message}
      color={toast.color}
      duration={3000}
      header={toast.header}
      position="bottom"
      buttons={[
        {
          text: "Ok",
          role: "cancel",
        },
      ]}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  toast: state.toast,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ hideToast }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
