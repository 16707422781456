import { Toast, ToastActionsTypes, SHOW_TOAST, HIDE_TOAST } from "./types";

const initialState: Toast = {
  isOpen: false,
  message: "",
};

export function toastReducer(
  state = initialState,
  action: ToastActionsTypes
): Toast {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        ...action.payload,
      };
    case HIDE_TOAST:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}
