import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import { toastReducer } from "./toast/reducers";

import { doctorProcedureReducer } from "./doctor/procedure/reducers";
import { doctorPrescriptionReducer } from "./doctor/prescription/reducers";
import { doctorTreatmentReducer } from "./doctor/treatment/reducers";
import { doctorAssistanceReducer } from "./doctor/assistance/reducers";

import { technicianAssistanceReducer } from "./technician/assistance/reducers";
import { technicianTakeOnReducer } from "./technician/assistance_take_on/reducers";

import nurseAssistanceReducer from "./nurse/assistance";
import nurseScheduledReducer from "./nurse/scheduled";

import { administrativeAssistanceReducer } from "./administrative/assistances/reducers";
import { administrativeProgressReducer } from "./administrative/assistances_progress/reducers";

import { driverMachineReducer } from "./driver/machines/reducers";

import { prescriptionReducer } from "./prescription/reducers";
import { machineOrderReducer } from "./machine_order/reducers";

const rootReducer = combineReducers({
  form: formReducer,
  toast: toastReducer,
  doctorPrescription: doctorPrescriptionReducer,
  doctorProcedure: doctorProcedureReducer,
  doctorTreatment: doctorTreatmentReducer,
  doctorAssistance: doctorAssistanceReducer,
  technicianAssistance: technicianAssistanceReducer,
  technicianTakeOn: technicianTakeOnReducer,
  nurseAssistance: nurseAssistanceReducer,
  nurseScheduled: nurseScheduledReducer,
  administrative_assistance: administrativeAssistanceReducer,
  administrative_progress: administrativeProgressReducer,
  driver_machine: driverMachineReducer,
  prescription: prescriptionReducer,
  machineOrder: machineOrderReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
