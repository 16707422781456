interface Props {
  value?: string;
  upper?: Boolean;
}

export default function MachineStatusConvert(props: Props) {
  if (props.value === "A") {
    if (props.upper === true) {
      return "ABERTO";
    } else {
      return "Aberto";
    }
  } else if (props.value === "M") {
    if (props.upper === true) {
      return "MANUTENÇÃO";
    } else {
      return "Manutenção";
    }
  } else if (props.value === "S") {
    if (props.upper === true) {
      return "SOLUCIONADO";
    } else {
      return "Solucionado";
    }
  } else {
    return "";
  }
}
