import {
  Prescription,
  Prescriptions,
  DoctorPrescriptionActionsTypes,
  GET_DOCTOR_PRESCRIPTIONS,
  SET_DOCTOR_PRESCRIPTION_PAGE,
} from "./types";
import { ThunkDispatch } from "redux-thunk";
import { initialize } from "redux-form";
import { showToast } from "../../../store/toast/actions";
import { ToastActionsTypes } from "../../../store/toast/types";
import ErrorHandler from "../../../helpers/Legacy/errorsHandler";
import api from "../../../services/api";

export function getList() {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      DoctorPrescriptionActionsTypes | ToastActionsTypes
    >
  ) => {
    await api
      .get<any>("doctor/prescription")
      .then((response) => {
        dispatch({ type: GET_DOCTOR_PRESCRIPTIONS, payload: response.data });
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function show(id: number) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(initialize("prescription", {}));
    await api
      .get(`doctor/prescription/${id}`)
      .then((response) => {
        dispatch(initialize("prescription", response.data));
        dispatch(setPage(1));
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function getPatient(id: number) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .get(`doctor/patient/${id}`)
      .then((response) => {
        dispatch(initialize("prescription", response.data));
        dispatch(setPage(1));
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function createOrUpdate(values: Prescription) {
  if (values.id) {
    return update(values);
  } else {
    return create(values);
  }
}

export function create(values: Prescription) {
  return async (dispatch: ThunkDispatch<{}, {}, ToastActionsTypes>) => {
    await api
      .post("doctor/prescription", values)
      .then((response) => {
        dispatch(showToast("Dados salvos com sucesso", "success"));
        dispatch(init());
        dispatch(getList());
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function update(values: Prescription) {
  return async (dispatch: ThunkDispatch<{}, {}, ToastActionsTypes>) => {
    await api
      .put(`doctor/prescription/${values.id}`, values)
      .then((response) => {
        dispatch(showToast("Dados salvos com sucesso", "success"));
        dispatch(init());
        dispatch(getList());
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function init() {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(initialize("prescription", {}));
    dispatch(setPage(1));
  };
}

export function setPage(page: Prescriptions["page"]) {
  return (dispatch: ThunkDispatch<{}, {}, DoctorPrescriptionActionsTypes>) => {
    dispatch({ type: SET_DOCTOR_PRESCRIPTION_PAGE, payload: page });
  };
}
