import { Patient } from "./types";
import { ThunkDispatch } from "redux-thunk";
import { initialize } from "redux-form";
import { showToast } from "../../store/toast/actions";
import ErrorHandler from "../../helpers/Legacy/errorsHandler";
import api from "../../services/api";

export function create(values: Patient) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .post("doctor/patient", values)
      .then((response) => {
        dispatch(showToast("Dados salvos com sucesso", "success"));
        dispatch(init());
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

function init() {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(initialize("patient", {}));
  };
}
