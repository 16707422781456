import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonDatetime,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonRow,
  IonText,
  useIonViewDidEnter,
} from "@ionic/react";
import { refresh } from "ionicons/icons";
import { format } from "date-fns";
import { Props as SelectProps } from "react-select";

import { useAuth } from "../../hooks/auth";
import { useValidationErrors } from "../../hooks/errors";
import { useNotifications } from "../../hooks/notifications";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";

import Main from "../../components/Main";
import SearchMachineByNumber from "../../components/Legacy/Search/MachineByNumber";

import { getType } from "../../shared/Procedures";

interface ScheduledAssists {
  id: number;
  patient_name: string;
  hospital_name: string;
  location: string;
  bed: string;
  date: string;
  start: string;
  doctor_name: string;
  technician1_id: number;
  technician2_id: number;
  technician3_id: number;
  technician4_id: number;
  technician1_name: string;
  technician2_name: string;
  technician3_name: string;
  technician4_name: string;
  tec1start: string;
  tec2start: string;
  tec3start: string;
  tec4start: string;
  tec1end: string;
  tec2end: string;
  tec3end: string;
  tec4end: string;
  proc: string;
  tec1open: 0 | 1;
  tec2open: 0 | 1;
  tec3open: 0 | 1;
  tec1close: 0 | 1;
  tec2close: 0 | 1;
  tec3close: 0 | 1;
}

const ScheduledAssistsCard: React.FC<{
  values: ScheduledAssists;
  getList(): void;
}> = ({ values, getList }) => {
  const { user } = useAuth();
  const { getValidationErrors } = useValidationErrors();
  const { addToast, successToast } = useToast();
  const { getCountAssistances } = useNotifications();
  const history = useHistory();

  const [tec1start, setTec1Start] = useState(values.tec1start || "00:00:00");
  const [machine, setMachine] = useState<SelectProps>({} as SelectProps);
  const [reverseOsmosis, setReverseOsmosis] = useState<SelectProps>(
    {} as SelectProps
  );
  const [homeChoice, setHomeChoice] = useState<SelectProps>({} as SelectProps);
  const [showAlert1, setShowAlert1] = useState(false);

  const handleTech1Submit = async () => {
    await api
      .put(`technician/scheduled_assists/${values.id}`, {
        technician1_id: values.technician1_id,
        tec1start: tec1start,
        machine: machine.value,
        reverse_osmosis: reverseOsmosis.value,
        home_choice: homeChoice.value,
      })
      .then((_response) => {
        successToast();
        getList();
        getCountAssistances();
        history.push("/technician/assists_in_progress");
      })
      .catch((error) => {
        getValidationErrors(error.response);
      });
  };

  const handleTech2Submit = async () => {
    await api
      .put(`technician/scheduled_assists/${values.id}`, {
        technician2_id: values.technician2_id,
        tec2start: values.tec2start,
      })
      .then((_response) => {
        successToast();
        getList();
        getCountAssistances();
        history.push("/technician/assists_in_progress");
      })
      .catch((error) => {
        getValidationErrors(error.response);
      });
  };

  const handleTech3Submit = async () => {
    await api
      .put(`technician/scheduled_assists/${values.id}`, {
        technician3_id: values.technician3_id,
        tec3start: values.tec3start,
      })
      .then((_response) => {
        successToast();
        getList();
        getCountAssistances();
        history.push("/technician/assists_in_progress");
      })
      .catch((error) => {
        getValidationErrors(error.response);
      });
  };

  const handleTech4Submit = async () => {
    await api
      .put(`technician/scheduled_assists/${values.id}`, {
        technician4_id: values.technician4_id,
        tec4start: values.tec4start,
      })
      .then((_response) => {
        successToast();
        getList();
        getCountAssistances();
        history.push("/technician/assists_in_progress");
      })
      .catch((error) => {
        getValidationErrors(error.response);
      });
  };

  return (
    <IonCard className="card-agendado">
      <IonCardHeader>
        <IonCardTitle>Paciente: {values.patient_name}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonRow className="ion-align-items-end">
          <IonCol size="12">
            <IonLabel>Hospital: </IonLabel>
            <IonText>{values.hospital_name}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Localização: </IonLabel>
            <IonText>{values.location}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Leito: </IonLabel>
            <IonText>{values.bed}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Data: </IonLabel>
            <IonText>
              {!!values.date &&
                format(new Date(values.date + "T00:00:00"), "dd/MM/yyyy") + " "}
              {!!values.start && values.start.substr(0, 5) + " "}
              <strong>
                {values.technician2_id === user.tech_id && !!values.tec2start
                  ? `(${values.tec2start.substr(0, 5)})`
                  : values.technician3_id === user.tech_id && !!values.tec3start
                  ? `(${values.tec3start.substr(0, 5)})`
                  : values.technician4_id === user.tech_id && !!values.tec4start
                  ? `(${values.tec4start.substr(0, 5)})`
                  : ""}
              </strong>
            </IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Médico: </IonLabel>
            <IonText>{values.doctor_name}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonRow className="ion-align-items-end ion-margin-top">
              <IonCol size="6">
                <IonButton
                  type="button"
                  routerLink={`/prescription/${values.id}`}
                  color="primary"
                  expand="block"
                >
                  Prescrição
                </IonButton>
              </IonCol>
            </IonRow>
          </IonCol>
          {values.technician1_id === user.tech_id &&
            values.tec1open === 0 &&
            values.tec1close === 0 && (
              <IonCol size="12" className="ion-margin-top">
                <IonItemDivider>
                  <IonLabel
                    color="dark"
                    style={{
                      whiteSpace: "pre-line",
                      paddingRight: 5,
                    }}
                  >
                    TÉCNICO 1
                  </IonLabel>
                </IonItemDivider>
                <IonRow className="ion-align-items-end ion-margin-top">
                  <IonCol size="12">
                    <IonLabel>Nome: </IonLabel>
                    <IonText>{values.technician1_name}</IonText>
                  </IonCol>
                  <IonCol size="6">
                    <IonLabel position="stacked">Hora de Início</IonLabel>
                    <IonDatetime
                      value={tec1start}
                      onIonChange={(event) =>
                        setTec1Start(event.detail.value || "")
                      }
                      cancelText="Cancelar"
                      doneText="Ok"
                      pickerFormat="HH:mm"
                      displayFormat="HH:mm"
                    />
                  </IonCol>
                  {getType(values.proc) === "DP" ? (
                    <SearchMachineByNumber
                      size="6"
                      label="Home Choice"
                      value={homeChoice}
                      onChange={(value) => setHomeChoice(value)}
                      type="C"
                    />
                  ) : (
                    <>
                      <SearchMachineByNumber
                        size="6"
                        label="Máquina"
                        value={machine}
                        onChange={(value) => setMachine(value)}
                        type="H"
                      />
                      <SearchMachineByNumber
                        size="6"
                        label="Osmose"
                        value={reverseOsmosis}
                        onChange={(value) => setReverseOsmosis(value)}
                        type="T"
                      />
                    </>
                  )}
                  <IonCol size="12">
                    <IonLabel>Hora de Término: </IonLabel>
                    <IonText>
                      {!!values.tec1end && values.tec1end.substr(0, 5)}
                    </IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonButton
                      type="button"
                      color="primary"
                      expand="block"
                      onClick={() => {
                        if (
                          getType(values.proc) !== "DP" &&
                          (!machine.value || !reverseOsmosis.value)
                        ) {
                          addToast({
                            message: "Selecione as máquinas",
                            color: "warning",
                          });
                        } else {
                          setShowAlert1(true);
                        }
                      }}
                    >
                      Iniciar
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            )}
          {values.technician2_id === user.tech_id &&
            values.tec2open === 0 &&
            values.tec2close === 0 && (
              <IonCol size="12" className="ion-margin-top">
                <IonItemDivider>
                  <IonLabel
                    color="dark"
                    style={{
                      whiteSpace: "pre-line",
                      paddingRight: 5,
                    }}
                  >
                    TÉCNICO 2
                  </IonLabel>
                </IonItemDivider>
                <IonRow className="ion-align-items-end ion-margin-top">
                  <IonCol size="12">
                    <IonLabel>Nome: </IonLabel>
                    <IonText>{values.technician2_name}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Hora de Início: </IonLabel>
                    <IonText>
                      {!!values.tec2start && values.tec2start.substr(0, 5)}
                    </IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Hora de Término: </IonLabel>
                    <IonText>
                      {!!values.tec2end && values.tec2end.substr(0, 5)}
                    </IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonButton
                      type="button"
                      color="primary"
                      expand="block"
                      disabled={values.tec1close === 0}
                      onClick={() => handleTech2Submit()}
                    >
                      Iniciar
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            )}
          {values.technician3_id === user.tech_id &&
            values.tec3open === 0 &&
            values.tec3close === 0 && (
              <IonCol size="12" className="ion-margin-top">
                <IonItemDivider>
                  <IonLabel
                    color="dark"
                    style={{
                      whiteSpace: "pre-line",
                      paddingRight: 5,
                    }}
                  >
                    TÉCNICO 3
                  </IonLabel>
                </IonItemDivider>
                <IonRow className="ion-align-items-end ion-margin-top">
                  <IonCol size="12">
                    <IonLabel>Nome: </IonLabel>
                    <IonText>{values.technician3_name}</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Hora de Início: </IonLabel>
                    <IonText>
                      {!!values.tec3start && values.tec3start.substr(0, 5)}
                    </IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel>Hora de Término: </IonLabel>
                    <IonText>
                      {!!values.tec3end && values.tec3end.substr(0, 5)}
                    </IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonButton
                      type="button"
                      color="primary"
                      expand="block"
                      disabled={values.tec2close === 0}
                      onClick={() => handleTech3Submit()}
                    >
                      Iniciar
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            )}
          {values.technician4_id === user.tech_id && (
            <IonCol size="12" className="ion-margin-top">
              <IonItemDivider>
                <IonLabel
                  color="dark"
                  style={{
                    whiteSpace: "pre-line",
                    paddingRight: 5,
                  }}
                >
                  TÉCNICO 4
                </IonLabel>
              </IonItemDivider>
              <IonRow className="ion-align-items-end ion-margin-top">
                <IonCol size="12">
                  <IonLabel>Nome: </IonLabel>
                  <IonText>{values.technician4_name}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Hora de Início: </IonLabel>
                  <IonText>
                    {!!values.tec4start && values.tec4start.substr(0, 5)}
                  </IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Hora de Término: </IonLabel>
                  <IonText>
                    {!!values.tec4end && values.tec4end.substr(0, 5)}
                  </IonText>
                </IonCol>
                <IonCol size="12">
                  <IonButton
                    type="button"
                    color="primary"
                    expand="block"
                    disabled={values.tec3close === 0}
                    onClick={() => handleTech4Submit()}
                  >
                    Iniciar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCol>
          )}
        </IonRow>
      </IonCardContent>
      <IonAlert
        isOpen={showAlert1}
        onDidDismiss={() => setShowAlert1(false)}
        message={
          "Confirmo que realizei a conferência dos insumos e equipamentos e estes se encontram em perfeita ordem para início e conclusão deste procedimento dialítico"
        }
        buttons={[
          {
            text: "Sim",
            handler: () => handleTech1Submit(),
          },
        ]}
      />
    </IonCard>
  );
};

const TechnicianScheduledAssists: React.FC = () => {
  const { getCountAssistances } = useNotifications();
  const [showLoading, setShowLoading] = useState(false);
  const [data, setData] = useState<ScheduledAssists[]>([]);

  const getList = async () => {
    setShowLoading(true);
    await api.get<any>(`technician/scheduled_assists`).then((response) => {
      setData(response.data);
    });
    setShowLoading(false);
    getCountAssistances();
  };

  useIonViewDidEnter(() => {
    getList();
  });

  const RefreshIcon = () => (
    <IonItem lines="none">
      <IonIcon
        icon={refresh}
        size="large"
        color="light"
        onClick={() => getList()}
      />
    </IonItem>
  );

  return (
    <Main title="Agendados" end={<RefreshIcon />}>
      {data.map((values) => (
        <ScheduledAssistsCard
          key={values.id}
          values={values}
          getList={getList}
        />
      ))}
      <IonLoading
        mode="ios"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Carregando..."}
        duration={5000}
      />
    </Main>
  );
};

export default TechnicianScheduledAssists;
