import React from "react";
import { IonCol, IonLabel, IonInput } from "@ionic/react";

interface Props {
  input: {
    name: string;
    value: string;
    onChange(e: string | null | undefined): void;
  };
  type:
    | "date"
    | "datetime-local"
    | "email"
    | "month"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
  label: string;
  autoCapitalize?: "on" | "off";
  autoComplete?:
    | "on"
    | "off"
    | "name"
    | "honorific-prefix"
    | "given-name"
    | "additional-name"
    | "family-name"
    | "honorific-suffix"
    | "nickname"
    | "email"
    | "username"
    | "new-password"
    | "current-password"
    | "one-time-code"
    | "organization-title"
    | "organization"
    | "street-address"
    | "address-line1"
    | "address-line2"
    | "address-line3"
    | "address-level4"
    | "address-level3"
    | "address-level2"
    | "address-level1"
    | "country"
    | "country-name"
    | "postal-code"
    | "cc-name"
    | "cc-given-name"
    | "cc-additional-name"
    | "cc-family-name"
    | "cc-number"
    | "cc-exp"
    | "cc-exp-month"
    | "cc-exp-year"
    | "cc-csc"
    | "cc-type"
    | "transaction-currency"
    | "transaction-amount"
    | "language"
    | "bday"
    | "bday-day"
    | "bday-month"
    | "bday-year"
    | "sex"
    | "tel"
    | "tel-country-code"
    | "tel-national"
    | "tel-area-code"
    | "tel-local"
    | "tel-extension"
    | "impp"
    | "url"
    | "photo";
  autoCorrect?: "on" | "off";
  autoFocus?: boolean;
  clearInput?: boolean;
  clearOnEdit?: boolean;
  inputMode:
    | "decimal"
    | "email"
    | "none"
    | "numeric"
    | "search"
    | "tel"
    | "text"
    | "url";
  max?: string;
  maxLength?: number;
  min?: string;
  minLength?: number;
  step?: string;
  readOnly?: boolean;
  required?: boolean;
  spellCheck?: boolean;
  size?: string;
  pattern?: string;
  className?: string;
}

const Input: React.FC<Props> = (props) => {
  return (
    <IonCol size={props.size || "12"}>
      <IonLabel position="stacked" style={{ color: "#000", fontWeight: 600 }}>
        {props.label}
      </IonLabel>
      <IonInput
        name={props.input.name}
        type={props.type}
        value={props.input.value}
        onIonChange={(e) => props.input.onChange(e.detail.value)}
        className={props.className}
        autocapitalize={props.autoCapitalize ? props.autoCapitalize : "off"}
        autocomplete={props.autoComplete ? props.autoComplete : "off"}
        autocorrect={props.autoCorrect ? props.autoCorrect : "off"}
        autofocus={props.autoFocus ? true : false}
        clearInput={props.clearInput ? true : false}
        clearOnEdit={props.clearOnEdit ? true : false}
        inputmode={props.inputMode}
        max={props.max}
        maxlength={props.maxLength}
        min={props.min}
        minlength={props.minLength}
        step={props.step}
        disabled={props.readOnly ? true : false}
        required={props.required ? true : false}
        spellcheck={props.spellCheck ? true : false}
        pattern={props.pattern}
        style={{
          marginTop: "5px",
          height: "42px",
          width: "100%",
          padding: "6px 12px",
          color: "#555555",
          backgroundColor: "#FFFFFF",
          backgroundImage: "none",
          borderColor: "hsl(0, 0%, 80%)",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "1px",
          lineHeight: 2,
        }}
      />
    </IonCol>
  );
};

export default Input;
