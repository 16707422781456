import {
  MachineOrder,
  MachineOrderActionsTypes,
  GET_MACHINE_ORDERS,
} from "./types";
import { ThunkDispatch } from "redux-thunk";
import { initialize } from "redux-form";
import { showToast } from "../../store/toast/actions";
import ErrorHandler from "../../helpers/Legacy/errorsHandler";
import { ToastActionsTypes } from "../toast/types";
import machine_orders_schema from "../../schemas/Legacy/machine_orders_schema";
import api from "../../services/api";

export function getList() {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      MachineOrderActionsTypes | ToastActionsTypes
    >
  ) => {
    await api
      .get<any>("machine/orders")
      .then((response) => {
        dispatch({ type: GET_MACHINE_ORDERS, payload: response.data });
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function getMachine(id: number) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .get(`machine/orders/machine/${id}`)
      .then((response) => {
        dispatch(initialize("machine_so", response.data));
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function create(values: MachineOrder) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    machine_orders_schema
      .validate(values)
      .then(async () => {
        await api
          .post("machine/orders", values)
          .then((response) => {
            dispatch(showToast("Dados salvos com sucesso", "success"));
            dispatch(init());
            dispatch(getList());
          })
          .catch((error) => {
            dispatch(ErrorHandler(error));
          });
      })
      .catch((err) => {
        dispatch(showToast(`${err.errors}`, "warning"));
      });
  };
}

function init() {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(initialize("machine_so", {}));
  };
}
