import {
  Procedures,
  Procedure,
  ProcedureActionsTypes,
  GET_PROCEDURES,
  CLEAR_PROCEDURES,
  UPDATE_PROCEDURE,
} from "./types";

const initialState: Procedures = {
  procedures: [],
};

export function doctorProcedureReducer(
  state = initialState,
  action: ProcedureActionsTypes
): Procedures {
  switch (action.type) {
    case GET_PROCEDURES:
      return {
        ...state,
        procedures: action.payload,
      };
    case CLEAR_PROCEDURES:
      return {
        ...state,
        ...initialState,
      };
    case UPDATE_PROCEDURE:
      return {
        ...state,
        procedures: state.procedures.map((procedure: Procedure) =>
          procedure.id === action.payload.id ? action.payload : procedure
        ),
      };
    default:
      return state;
  }
}
