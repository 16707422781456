import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  formValueSelector,
} from "redux-form";
import { useLocation } from "react-router-dom";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonText,
} from "@ionic/react";
import { caretBack, caretForward } from "ionicons/icons";

import { RootState } from "../../store";
import { getList, clearList } from "../../store/technician/assistance/actions";
import {
  Assistance as AssistanceType,
  TechnicianAssistanceForm as FormType,
} from "../../store/technician/assistance/types";

import Main from "../../components/Main";
import DateInput from "../../components/Legacy/Form/DateInput";
import If from "../../helpers/Legacy/if";
import dateToString from "../../helpers/Legacy/dateToString";

interface StateProps {
  assistances: AssistanceType[];
  formDate: FormType["date"];
}

interface DispatchProps {
  getList(values: FormType): void;
  clearList(): void;
}

type Props = StateProps & DispatchProps;

const TechnicianAssistances: React.FC<
  Props & InjectedFormProps<FormType, Props>
> = ({ handleSubmit, getList, clearList, assistances, formDate, change }) => {
  const location = useLocation();

  useEffect(() => {
    clearList();
  }, [location, clearList]);

  function handlePrevious() {
    let yesterday = new Date(formDate);
    yesterday.setDate(yesterday.getDate() - 1);
    change("date", yesterday.toDateString());
    formDate = yesterday.toDateString();
    getList({ date: formDate });
  }

  function handleNext() {
    let tomorrow = new Date(formDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    change("date", tomorrow.toDateString());
    formDate = tomorrow.toDateString();
    getList({ date: formDate });
  }

  return (
    <Main title="Atendimentos">
      <IonCard>
        <IonCardContent>
          <form onSubmit={handleSubmit(getList)}>
            <IonGrid>
              <IonRow>
                <Field name="date" component={DateInput} label="Data" />
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="3">
                  <IonButton
                    type="button"
                    onClick={handlePrevious}
                    color="primary"
                    expand="block"
                    disabled={!!formDate ? false : true}
                  >
                    <IonIcon size="small" icon={caretBack} />
                  </IonButton>
                </IonCol>
                <IonCol size="6">
                  <IonButton type="submit" color="primary" expand="block">
                    Consultar
                  </IonButton>
                </IonCol>
                <IonCol size="3">
                  <IonButton
                    type="button"
                    onClick={handleNext}
                    color="primary"
                    expand="block"
                    disabled={!!formDate ? false : true}
                  >
                    <IonIcon size="small" icon={caretForward} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
      {assistances.map((assistance) => {
        return (
          <IonCard
            key={assistance.id}
            className={`card-${assistance.situation?.toLowerCase()}`}
          >
            <IonCardHeader>
              <IonCardTitle>Situação: {assistance.situation}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonRow>
                <IonCol size="12">
                  <IonLabel>Data: </IonLabel>
                  <IonText>{dateToString(assistance.date!)}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Hospital: </IonLabel>
                  <IonText>{assistance.hospital}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Paciente: </IonLabel>
                  <IonText>{assistance.patient}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Médico: </IonLabel>
                  <IonText>{assistance.doctor}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="6">
                  <IonButton
                    type="button"
                    routerLink={`/prescription/${assistance.id}`}
                    color="primary"
                    expand="block"
                  >
                    Prescrição
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <If
                  test={
                    assistance.situation === "REALIZADO" ||
                    assistance.situation === "ANDAMENTO"
                  }
                >
                  <If
                    test={
                      assistance.annotations && assistance.annotations > 0
                        ? true
                        : false
                    }
                  >
                    <IonCol size="6">
                      <IonButton
                        type="button"
                        routerLink={`/assists_annotations/${assistance.id}/${assistance.proc}`}
                        color="secondary"
                        expand="block"
                      >
                        Anotações
                      </IonButton>
                    </IonCol>
                  </If>
                  <If
                    test={
                      assistance.evolution && assistance.evolution > 0
                        ? true
                        : false
                    }
                  >
                    <IonCol size="6">
                      <IonButton
                        type="button"
                        routerLink={`/assists_evolutions/${assistance.id}`}
                        color="medium"
                        expand="block"
                      >
                        Evolução
                      </IonButton>
                    </IonCol>
                  </If>
                </If>
              </IonRow>
            </IonCardContent>
          </IonCard>
        );
      })}
    </Main>
  );
};

const sessionKey = `${process.env.REACT_APP_SESSION_NAME}`;

const TechnicianAssistancesForm = reduxForm<FormType, Props>({
  form: "technician_assistances",
  initialValues: JSON.parse(
    `${localStorage.getItem(`${sessionKey}_technician_assistances`)}`
  ),
})(TechnicianAssistances);

const selector = formValueSelector("technician_assistances");

const mapStateToProps = (state: RootState) => ({
  assistances: state.technicianAssistance.assistances,
  formDate: selector(state, "date"),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getList, clearList }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TechnicianAssistancesForm);
