import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { IonCol, IonLabel, IonInput } from "@ionic/react";

interface Props {
  name: string;
  label?: string;
  size?: string;
  readOnly?: boolean;
  type:
    | "date"
    | "datetime-local"
    | "email"
    | "month"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
  autoCapitalize?: "on" | "off";
  autoComplete?:
    | "on"
    | "off"
    | "name"
    | "honorific-prefix"
    | "given-name"
    | "additional-name"
    | "family-name"
    | "honorific-suffix"
    | "nickname"
    | "email"
    | "username"
    | "new-password"
    | "current-password"
    | "one-time-code"
    | "organization-title"
    | "organization"
    | "street-address"
    | "address-line1"
    | "address-line2"
    | "address-line3"
    | "address-level4"
    | "address-level3"
    | "address-level2"
    | "address-level1"
    | "country"
    | "country-name"
    | "postal-code"
    | "cc-name"
    | "cc-given-name"
    | "cc-additional-name"
    | "cc-family-name"
    | "cc-number"
    | "cc-exp"
    | "cc-exp-month"
    | "cc-exp-year"
    | "cc-csc"
    | "cc-type"
    | "transaction-currency"
    | "transaction-amount"
    | "language"
    | "bday"
    | "bday-day"
    | "bday-month"
    | "bday-year"
    | "sex"
    | "tel"
    | "tel-country-code"
    | "tel-national"
    | "tel-area-code"
    | "tel-local"
    | "tel-extension"
    | "impp"
    | "url"
    | "photo";
  autoCorrect?: "on" | "off";
  autoFocus?: boolean;
  clearInput?: boolean;
  clearOnEdit?: boolean;
  inputMode:
    | "decimal"
    | "email"
    | "none"
    | "numeric"
    | "search"
    | "tel"
    | "text"
    | "url";
  max?: string;
  maxLength?: number;
  min?: string;
  minLength?: number;
  step?: string;
  spellCheck?: boolean;
}

const Input: React.FC<Props> = ({
  name,
  label,
  size,
  readOnly,
  type,
  autoCapitalize,
  autoComplete,
  autoCorrect,
  autoFocus,
  clearInput,
  clearOnEdit,
  inputMode,
  max,
  maxLength,
  min,
  minLength,
  step,
  spellCheck,
  ...rest
}) => {
  const inputRef = useRef<HTMLIonInputElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        if (ref.current.value !== "" && ref.current.value !== null) {
          return ref.current.value;
        }
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <IonCol size={size || "12"}>
      <IonLabel position="stacked">{label}</IonLabel>
      <IonInput
        ref={inputRef}
        name={name}
        type={type}
        defaultValue={defaultValue}
        autocapitalize={autoCapitalize ? autoCapitalize : "off"}
        autocomplete={autoComplete ? autoComplete : "off"}
        autocorrect={autoCorrect ? autoCorrect : "off"}
        autofocus={autoFocus ? true : false}
        clearInput={clearInput ? true : false}
        clearOnEdit={clearOnEdit ? true : false}
        inputmode={inputMode}
        max={max}
        maxlength={maxLength}
        min={min}
        minlength={minLength}
        step={step}
        disabled={readOnly ? true : false}
        spellcheck={spellCheck ? true : false}
        {...rest}
        style={{
          marginTop: "5px",
          height: "42px",
          width: "100%",
          padding: "6px 12px",
          color: "#555555",
          backgroundColor: "#FFFFFF",
          backgroundImage: "none",
          borderColor: "hsl(0, 0%, 80%)",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "1px",
          lineHeight: 2,
        }}
      />
    </IonCol>
  );
};

export default Input;
