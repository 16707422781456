import React, { useEffect, useState } from "react";
import { IonCol, IonLabel } from "@ionic/react";
import Select from "react-select";

import api from "../../../services/api";

const SearchInput: React.FC = (props: any) => {
  const [agreements, setAgreements] = useState([]);

  useEffect(() => {
    searchAgreements();
  }, []);

  function searchAgreements(value?: string | null) {
    api
      .get<any>("search/agreement", { params: { search: value } })
      .then((resp) => {
        setAgreements(resp.data);
      });
  }

  return (
    <IonCol size={props.size || "12"}>
      <IonLabel position="stacked" style={{ color: "#000", fontWeight: 600 }}>
        Convênio
      </IonLabel>
      <Select
        placeholder=""
        classNamePrefix="select"
        value={props.input.value ? props.input.value : ""}
        onChange={(value) => {
          if (value) {
            props.input.onChange(value);
          } else {
            props.input.onChange("");
          }
        }}
        onInputChange={(value) => searchAgreements(value)}
        noOptionsMessage={() => "Nenhuma opção"}
        simpleValue
        isDisabled={props.readOnly}
        isClearable={props.isClearable}
        required={props.required}
        options={agreements}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </IonCol>
  );
};

export default SearchInput;
