import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { useLocation } from "react-router-dom";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonText,
  IonSelectOption,
} from "@ionic/react";

import { RootState } from "../../store";
import {
  getList,
  clearList,
  update,
  getPatient,
} from "../../store/doctor/treatment/actions";
import { Treatment, TreatmentForm } from "../../store/doctor/treatment/types";

import Main from "../../components/Main";
import HospitalSearch from "../../components/Legacy/Search/Hospital";
import Select from "../../components/Legacy/Form/Select";
import dateToString from "../../helpers/Legacy/dateToString";

interface StateProps {
  treatments: Treatment[];
}

interface DispatchProps {
  getList(values: TreatmentForm): void;
  clearList(): void;
  update(values: Treatment): void;
  getPatient(id: number): void;
}

type Props = StateProps & DispatchProps;

const Treatments: React.FC<Props & InjectedFormProps<TreatmentForm, Props>> = ({
  handleSubmit,
  getList,
  clearList,
  update,
  getPatient,
  treatments,
}) => {
  const location = useLocation();

  useEffect(() => {
    clearList();
  }, [location, clearList]);

  return (
    <Main title="Em Tratamento">
      <IonCard>
        <IonCardContent>
          <form onSubmit={handleSubmit(getList)}>
            <IonGrid>
              <IonRow>
                <Field name="hospital" component={HospitalSearch} required />
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="6">
                  <IonButton type="submit" color="primary" expand="block">
                    Consultar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
      {treatments.map((treatment) => {
        return (
          <IonCard key={treatment.id}>
            <IonCardHeader>
              <IonCardTitle>Data: {dateToString(treatment.date)}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonRow>
                <IonCol size="12">
                  <IonLabel>Paciente: </IonLabel>
                  <IonText>{treatment.patient}</IonText>
                </IonCol>
                <Select
                  label="Destino"
                  input={{
                    name: "destination",
                    value: treatment.destination,
                    onChange: (e: string) =>
                      update({ ...treatment, destination: e }),
                  }}
                >
                  <IonSelectOption value="Em tratamento">
                    Em tratamento
                  </IonSelectOption>
                  <IonSelectOption value="Alta Nefro">
                    Alta Nefro
                  </IonSelectOption>
                  <IonSelectOption value="Alta Hospitalar">
                    Alta Hospitalar
                  </IonSelectOption>
                  <IonSelectOption value="Alta encaminhado para o programa">
                    Alta encaminhado para o programa
                  </IonSelectOption>
                  <IonSelectOption value="Transferência">
                    Transferência
                  </IonSelectOption>
                  <IonSelectOption value="Óbito">Óbito</IonSelectOption>
                </Select>
                <Select
                  label="Evento"
                  input={{
                    name: "event",
                    value: treatment.event,
                    onChange: (e: string) => update({ ...treatment, event: e }),
                  }}
                >
                  <IonSelectOption value="Suspenso">Suspenso</IonSelectOption>
                  <IonSelectOption value="em Avaliação">
                    em Avaliação
                  </IonSelectOption>
                  <IonSelectOption value="Dias Alternados">
                    Dias Alternados
                  </IonSelectOption>
                  <IonSelectOption value="Diário">Diário</IonSelectOption>
                  <IonSelectOption value="Conservador">
                    Conservador
                  </IonSelectOption>
                </Select>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="6">
                  <IonButton
                    type="button"
                    onClick={() => getPatient(treatment.patient_id)}
                    color="primary"
                    expand="block"
                  >
                    Prescrever
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
        );
      })}
    </Main>
  );
};

const sessionKey = `${process.env.REACT_APP_SESSION_NAME}`;

const TreatmentsForm = reduxForm<TreatmentForm, Props>({
  form: "treatments",
  initialValues: JSON.parse(
    `${localStorage.getItem(`${sessionKey}_doctor_treatments`)}`
  ),
})(Treatments);

const mapStateToProps = (state: RootState) => ({
  treatments: state.doctorTreatment.treatments,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getList, clearList, update, getPatient }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentsForm);
