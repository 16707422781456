import {
  MachineList,
  MachinesActionsTypes,
  GET_DRIVER_MACHINES,
  CLEAR_DRIVER_MACHINES
} from './types'

const initialState: MachineList = {
  list: []
}

export function driverMachineReducer(
  state = initialState,
  action: MachinesActionsTypes
): MachineList {
  switch (action.type) {
    case GET_DRIVER_MACHINES:
      return {
        ...state,
        list: action.payload
      }
    case CLEAR_DRIVER_MACHINES:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}
