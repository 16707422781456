export interface Assistance {
  id: number
  situation: string
  date: string
  hospital: string
  patient: string
  site: string
  proc: string
  agreement: string
  diagnosis: string
  catheter: 'N' | 'S'
  catheter_type: 'N' | 'T'
  thenkoff: 'N' | 'S'
  catheter_reason: string
  doctor?: number | null
  confirmed?: 'S' | 'N' | 'NE'
}

export interface Assistances {
  assistances: Assistance[]
}

export interface AssistanceForm {
  from: string
  to: string
}

export const GET_ASSISTANCES = 'GET_ASSISTANCES'
export const CLEAR_ASSISTANCES = 'CLEAR_ASSISTANCES'
export const UPDATE_ASSISTANCE = 'UPDATE_ASSISTANCE'
export const DELETE_ASSISTANCE = 'DELETE_ASSISTANCE'

interface GetAssistancesActions {
  type: typeof GET_ASSISTANCES
  payload: Assistance[]
}

interface ClearAssistancesActions {
  type: typeof CLEAR_ASSISTANCES
  payload: undefined
}

interface UpdateAssistanceActions {
  type: typeof UPDATE_ASSISTANCE
  payload: Assistance
}

interface DeleteAssistanceActions {
  type: typeof DELETE_ASSISTANCE
  payload: Assistance
}

export type AssistanceActionsTypes =
  | GetAssistancesActions
  | ClearAssistancesActions
  | UpdateAssistanceActions
  | DeleteAssistanceActions
