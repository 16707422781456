import {
  Treatment,
  TreatmentForm,
  TreatmentActionsTypes,
  GET_TREATMENTS,
  CLEAR_TREATMENTS,
  UPDATE_TREATMENT,
} from "./types";
import { ThunkDispatch } from "redux-thunk";
import { initialize } from "redux-form";
import { showToast } from "../../../store/toast/actions";
import ErrorHandler from "../../../helpers/Legacy/errorsHandler";
import api from "../../../services/api";

import { history } from "../../../App";

const sessionKey = `${process.env.REACT_APP_SESSION_NAME}`;
export function getList(values: TreatmentForm) {
  localStorage.setItem(
    `${sessionKey}_doctor_treatments`,
    JSON.stringify(values)
  );
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .get("doctor/treatments", {
        params: {
          hospital: values.hospital?.value,
        },
      })
      .then((response) => {
        dispatch({ type: GET_TREATMENTS, payload: response.data });
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function clearList(): TreatmentActionsTypes {
  return {
    type: CLEAR_TREATMENTS,
    payload: undefined,
  };
}

export function update(values: Treatment) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .put(`doctor/treatments/${values.id}`, values)
      .then((response) => {
        dispatch({ type: UPDATE_TREATMENT, payload: response.data });
        dispatch(showToast("Dados salvos com sucesso", "success"));
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function getPatient(id: number) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .get(`doctor/patient/${id}`)
      .then((response) => {
        dispatch(initialize("prescription", response.data));
        dispatch({ type: "SET_DOCTOR_PRESCRIPTION_PAGE", payload: 1 });
        history.push("/doctor/prescription");
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}
