export interface AssistanceTakeOn {
  id: number;
  situation: string;
  patient: string;
  hospital: string;
  date: string;
  start: string;
  doctor: string;
  mar?: string;
  or?: string;
  home_choice?: string;
  time: number;
}

export interface AssistancesTakeOn {
  assistances: AssistanceTakeOn[];
}

export interface TechnicianTakeOnForm {
  hospital: {
    value: number;
    label: string;
  };
}

export const GET_TECHNICIAN_TAKE_ON = "GET_TECHNICIAN_TAKE_ON";
export const CLEAR_TECHNICIAN_TAKE_ON = "CLEAR_TECHNICIAN_TAKE_ON";

interface GetTechnicianTakeOnActions {
  type: typeof GET_TECHNICIAN_TAKE_ON;
  payload: AssistanceTakeOn[];
}

interface ClearTechnicianTakeOnActions {
  type: typeof CLEAR_TECHNICIAN_TAKE_ON;
  payload: undefined;
}

export type TechnicianTakeOnActionsTypes =
  | GetTechnicianTakeOnActions
  | ClearTechnicianTakeOnActions;
