import React, { useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonList,
  IonRow,
  IonText,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import Main from "../../components/Main";
import Card from "../../components/Card";
import Item from "../../components/Item";
import Label from "../../components/Label";
import FormLabel from "../../components/Form/FormLabel";
import ProductsInHospitalSearch from "../../components/Form/ProductsInHospitalSearch";
import ProductsBatchesInHospitalSearch from "../../components/Form/ProductsBatchesInHospitalSearch";
import Input from "../../components/Form/Input";

import api from "../../services/api";
import { useLoading } from "../../hooks/loading";
import { useToast } from "../../hooks/toast";
import { useValidationErrors } from "../../hooks/errors";

interface ParamsTypes {
  assistanceId: string;
  patientName: string;
}

interface AssistancesStocksFormData {
  product_id: number;
  product_batch: number;
  amount: number;
}

interface AssistancesStocksCardData {
  id: number;
  assistance_id: number;
  product: string;
  product_batch: string;
  amount: number;
}

const TechnicianAssistancesStocks: React.FC = () => {
  const { assistanceId, patientName } = useParams<ParamsTypes>();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);
  const { showLoading, hideLoading } = useLoading();
  const { successToast } = useToast();
  const { getValidationErrors } = useValidationErrors();

  const [scroll, setScroll] = useState(new Date());
  const [productId, setProductId] = useState(0);
  const [cards, setCards] = useState<AssistancesStocksCardData[]>([]);

  const getList = async (assistanceId: string) => {
    showLoading();
    await api
      .get<AssistancesStocksCardData[]>("technician/assistances_stock", {
        params: { assistance_id: assistanceId },
      })
      .then((response) => {
        setCards(response.data);
      });
    hideLoading();
  };

  const handleReset = () => {
    formRef?.current?.reset();
    setProductId(0);
    setScroll(new Date());
  };

  useIonViewDidEnter(() => {
    handleReset();
    getList(assistanceId);
  }, [assistanceId]);

  useIonViewWillLeave(() => {
    handleReset();
    setCards([]);
  }, [assistanceId]);

  const handleSubmit: SubmitHandler = async (
    values: AssistancesStocksFormData
  ) => {
    showLoading();
    await api
      .post(`technician/assistances_stock`, {
        assistance_id: assistanceId,
        ...values,
      })
      .then(() => {
        successToast();
        handleReset();
        getList(assistanceId);
      })
      .catch((error) => {
        getValidationErrors(error.response);
      });
    hideLoading();
  };

  const handleDelete = async (id: number) => {
    showLoading();
    await api
      .delete(`technician/assistances_stock/${id}`)
      .then(() => {
        successToast();
        getList(assistanceId);
      })
      .catch((error) => {
        getValidationErrors(error.response);
      });
    hideLoading();
  };

  return (
    <Main title="Estoque" scroll={scroll} back>
      <Card>
        <IonCardContent>
          <IonGrid>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <IonRow>
                <ProductsInHospitalSearch
                  label="Produto:"
                  name="product_id"
                  assistanceId={assistanceId}
                  simpleValue
                  onOptionChange={(value) => setProductId(value)}
                />
                {productId > 0 && (
                  <>
                    <ProductsBatchesInHospitalSearch
                      label="Lote:"
                      name="product_batch"
                      assistanceId={assistanceId}
                      productId={productId}
                      simpleValue
                    />
                    <Input
                      label="Quantidade:"
                      name="amount"
                      type="number"
                      inputMode="numeric"
                    />
                  </>
                )}
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="12">
                  <FormLabel>Paciente: </FormLabel>
                  <IonText>{patientName}</IonText>
                </IonCol>
                <IonCol size="6" className="ion-margin-top">
                  <IonButton type="submit" color="primary" expand="block">
                    SALVAR
                  </IonButton>
                </IonCol>
                <IonCol size="6" className="ion-margin-top">
                  <IonButton
                    type="button"
                    onClick={() => history.goBack()}
                    color="dark"
                    expand="block"
                  >
                    VOLTAR
                  </IonButton>
                </IonCol>
              </IonRow>
            </Form>
          </IonGrid>
        </IonCardContent>
      </Card>
      {cards.length > 0 &&
        cards.map((card) => (
          <IonCard key={card.id}>
            <IonCardHeader>
              <IonList>
                <Item>
                  <Label>Id do atendimento:</Label>
                  <IonText>{card.assistance_id}</IonText>
                </Item>
                <Item>
                  <Label>Produto:</Label>
                  <IonText>{card.product}</IonText>
                </Item>
                <Item>
                  <Label>Lote:</Label>
                  <IonText>{card.product_batch}</IonText>
                </Item>
                <Item>
                  <Label>Quantidade:</Label>
                  <IonText>{card.amount}</IonText>
                </Item>
              </IonList>
            </IonCardHeader>
            <IonCardContent style={{ textAlign: "right" }}>
              <IonButton color="danger" onClick={() => handleDelete(card.id)}>
                ESTORNAR
              </IonButton>
            </IonCardContent>
          </IonCard>
        ))}
    </Main>
  );
};

export default TechnicianAssistancesStocks;
