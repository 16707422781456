import React from "react";
import { IonRouterOutlet } from "@ionic/react";

import Route from "./Route";

import Spinner from "../pages/Spinner";

// Doctor Routes

import DoctorData from "../pages/Doctor/User";
import DoctorPrescription from "../pages/Doctor/Prescription";
import DoctorPatient from "../pages/Doctor/Patient";
import DoctorProcedures from "../pages/Doctor/Procedures";
import DoctorTreatment from "../pages/Doctor/Treatment";
import DoctorAssistances from "../pages/Doctor/Assistances";
import DoctorCatheter from "../pages/Doctor/Catheter";
import DoctorPatientsControl from "../pages/Doctor/PatientsControl";

// Nurse Routes

import NurseAssistances from "../pages/Nurse/Assistances";
import NurseScheduled from "../pages/Nurse/Scheduled";

// Technician Routes

import TechnicianScheduledAssists from "../pages/Technician/ScheduledAssists";
import TechnicianAssistsInProgress from "../pages/Technician/AssistsInProgress";
import TechnicianAssistsIntercurrences from "../pages/Technician/AssistsIntercurrences";
import TechnicianAssistsAnnotations from "../pages/Technician/AssistsAnnotations";
import TechnicianAssistsEvolutions from "../pages/Technician/AssistsEvolutions";
import TechnicianAssistancesStock from "../pages/Technician/AssistancesStock";
import TechnicianAssistances from "../pages/Technician/Assistances";
import TechnicianScore from "../pages/Technician/Score";
import TechnicianAssistancesTakeOn from "../pages/Technician/Assistances_Take_On";

// Administrative Routes

import AdministrativeAssistance from "../pages/Administrative/Assistance";
import AdministrativeAssistanceProgress from "../pages/Administrative/Assistance_Progress";
import AdministrativeDialysisIndicators from "../pages/Administrative/DialysisIndicators";
import AdministrativeIndicatorsByHospital from "../pages/Administrative/IndicatorsByHospital";

// Driver Routes

import DriverMachines from "../pages/Driver/Machines";
import DriverMachineChecklist from "../pages/Driver/Machine_Checklist";

// General Routes

import Prescription from "../pages/Prescription";
import GeneralAssistsAnnotations from "../pages/AssistsAnnotations";
import GeneralAssistsEvolution from "../pages/AssistsEvolutions";
import MachineOrders from "../pages/MachineOrders";
import UserData from "../pages/UserData";
import Password from "../pages/Password";

const Routes: React.FC = () => {
  return (
    <IonRouterOutlet id="main">
      <Route path="/" component={Spinner} exact />
      {/* Doctor Routes */}
      <Route path="/doctor" component={DoctorData} exact isDoctor />
      <Route
        path="/doctor/prescription"
        component={DoctorPrescription}
        isDoctor
      />
      <Route path="/doctor/patient" component={DoctorPatient} isDoctor />
      <Route path="/doctor/procedures" component={DoctorProcedures} isDoctor />
      <Route path="/doctor/treatment" component={DoctorTreatment} isDoctor />
      <Route
        path="/doctor/patients_control"
        component={DoctorPatientsControl}
        isDoctor
      />
      <Route
        path="/doctor/assistances"
        component={DoctorAssistances}
        isDoctor
      />
      <Route path="/doctor/catheter" component={DoctorCatheter} isDoctor />

      {/* Nurse Routes */}
      <Route path="/nurse/assistances" component={NurseAssistances} isNurse />
      <Route path="/nurse/scheduled" component={NurseScheduled} isNurse />

      {/* Technician Routes */}
      <Route
        path="/technician/scheduled_assists"
        component={TechnicianScheduledAssists}
        isTechnician
      />
      <Route
        path="/technician/assists_in_progress"
        component={TechnicianAssistsInProgress}
        isTechnician
      />
      <Route
        path="/technician/assistances"
        component={TechnicianAssistances}
        isTechnician
      />
      <Route
        path="/technician/score"
        component={TechnicianScore}
        isTechnician
      />
      <Route
        path="/technician/assistances_take_on"
        component={TechnicianAssistancesTakeOn}
        isTechnician
      />
      <Route
        path="/technician/assists_intercurrences/:assistanceId/:patientName"
        component={TechnicianAssistsIntercurrences}
        isTechnician
      />
      <Route
        path="/technician/assists_annotations/:assistanceId/:patientName/:procName"
        component={TechnicianAssistsAnnotations}
        isTechnician
      />
      <Route
        path="/technician/assists_evolutions/:assistanceId/:patientName"
        component={TechnicianAssistsEvolutions}
        isTechnician
      />
      <Route
        path="/technician/assistances_stock/:assistanceId/:patientName"
        component={TechnicianAssistancesStock}
        isTechnician
      />

      {/* Administrative Routes  */}
      <Route
        path="/administrative/assistances"
        component={AdministrativeAssistance}
        exact
        isAdministrative
      />
      <Route
        path="/administrative/assistances/progress"
        component={AdministrativeAssistanceProgress}
        isAdministrative
      />
      <Route
        path="/administrative/indicators"
        component={AdministrativeDialysisIndicators}
        exact
        isAdministrative
      />
      <Route
        path="/administrative/indicators/hospital"
        component={AdministrativeIndicatorsByHospital}
        exact
        isAdministrative
      />

      {/* Driver Routes */}
      <Route path="/driver/machines" component={DriverMachines} isDriver />
      <Route
        path="/driver/machine/checklist"
        component={DriverMachineChecklist}
        isDriver
      />

      {/* General Routes */}
      <Route
        path="/prescription/:id"
        component={Prescription}
        isDoctor
        isNurse
        isTechnician
      />
      <Route
        path="/assists_annotations/:assistanceId/:procName"
        component={GeneralAssistsAnnotations}
        isDoctor
        isNurse
        isTechnician
      />
      <Route
        path="/assists_evolutions/:assistanceId"
        component={GeneralAssistsEvolution}
        isDoctor
        isNurse
        isTechnician
      />
      <Route
        path="/machine_orders"
        component={MachineOrders}
        isDoctor
        isNurse
        isTechnician
      />
      <Route
        path="/user"
        component={UserData}
        isNurse
        isAdministrative
        isDriver
      />
      <Route
        path="/password"
        component={Password}
        isDoctor
        isNurse
        isTechnician
        isAdministrative
        isDriver
      />
    </IonRouterOutlet>
  );
};
export default Routes;
