import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { AuthProvider } from "./auth";
import { LoadingProvider } from "./loading";
import { ToastProvider } from "./toast";
import { NotificationsProvider } from "./notifications";
import { ValidationErrorsProvider } from "./errors";

import rootReducer from "../store";
export const store = applyMiddleware(thunk)(createStore)(rootReducer);

const AppProvider: React.FC = ({ children }) => (
  <Provider store={store}>
    <AuthProvider>
      <LoadingProvider>
        <ToastProvider>
          <ValidationErrorsProvider>
            <NotificationsProvider>{children}</NotificationsProvider>
          </ValidationErrorsProvider>
        </ToastProvider>
      </LoadingProvider>
    </AuthProvider>
  </Provider>
);

export default AppProvider;
