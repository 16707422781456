interface ContinuousMode {
  value: string;
  label: string;
}

interface ContinuousKit {
  value: string;
  label: string;
}

const modes: ContinuousMode[] = [
  { value: "CCVH", label: "CCVH" },
  { value: "CCVHD", label: "CCVHD" },
  { value: "CVVHF", label: "CVVHF" },
  { value: "SCUF", label: "SCUF" },
];

const kits: ContinuousKit[] = [
  { value: "M100", label: "M100" },
  { value: "Oxiris", label: "Oxiris" },
  { value: "Omni 1.6", label: "Omni 1.6" },
  { value: "Diapact", label: "Diapact" },
];

const capillaries: ContinuousKit[] = [
  {
    value: "Capilar  Hi Ps 15 Uso Único",
    label: "Capilar  Hi Ps 15 Uso Único",
  },
  {
    value: "Capilar Alto Fluxo Fx 60  Classix Uso Único",
    label: "Capilar Alto Fluxo Fx 60  Classix Uso Único",
  },
  {
    value: "Capilar Alto Fluxo Fx 80  Classix Uso Único",
    label: "Capilar Alto Fluxo Fx 80  Classix Uso Único",
  },
  {
    value: "Capilar Diacap HI PS 18 - Reuso / Alto Fluxo",
    label: "Capilar Diacap HI PS 18 - Reuso / Alto Fluxo",
  },
  {
    value: "Capilar Helixone  Fx Paed Uso Único",
    label: "Capilar Helixone  Fx Paed Uso Único",
  },
  {
    value: "Capilar Helixone Fx 100  Uso Único",
    label: "Capilar Helixone Fx 100  Uso Único",
  },
  {
    value: "Capilar Hemoflow F04 HPS Reuso",
    label: "Capilar Hemoflow F04 HPS Reuso",
  },
];

const lines: ContinuousKit[] = [
  {
    value: "Kit linhas arterial/venosa - Uso único",
    label: "Kit linhas arterial/venosa - Uso único",
  },
  { value: "Linha Arterial Neonatal", label: "Linha Arterial Neonatal" },
  { value: "Linha Arterial Pediátrica", label: "Linha Arterial Pediátrica" },
  {
    value: "Linha de Cálcio (Prismaflex)",
    label: "Linha de Cálcio (Prismaflex)",
  },
  {
    value: "Linha Venosa Com Catabolha e com extensor",
    label: "Linha Venosa Com Catabolha e com extensor",
  },
  { value: "Linha Venosa Neonatal", label: "Linha Venosa Neonatal" },
  { value: "Linha Venosa Pediátrica", label: "Linha Venosa Pediátrica" },
];

export { modes, kits, capillaries, lines };
