interface Props {
  value?: "N" | "S";
  upper?: Boolean;
}

export default function (props: Props) {
  if (props.value === "S") {
    if (props.upper === true) {
      return "SIM";
    } else {
      return "Sim";
    }
  } else {
    if (props.upper === true) {
      return "NÃO";
    } else {
      return "Não";
    }
  }
}
