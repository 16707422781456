import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { IonCol, IonLabel, IonTextarea } from "@ionic/react";

interface Props {
  name: string;
  label?: string;
  rows?: number;
  size?: string;
  readOnly?: boolean;
}

const Textarea: React.FC<Props> = ({
  name,
  label,
  rows,
  size,
  readOnly,
  ...rest
}) => {
  const inputRef = useRef<HTMLIonTextareaElement>(null);

  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref, value) => {
        if (value) {
          ref.current.value = value;
        } else {
          ref.current.value = "";
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <IonCol size={size || "12"}>
      <IonLabel position="stacked">{label}</IonLabel>
      <IonTextarea
        ref={inputRef}
        name={name}
        defaultValue={defaultValue}
        rows={rows}
        readonly={readOnly ? true : false}
        {...rest}
        style={{
          marginTop: "5px",
          width: "100%",
          color: "#555555",
          backgroundColor: "#FFFFFF",
          backgroundImage: "none",
          borderColor: "hsl(0, 0%, 80%)",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "1px",
          lineJeight: 1.5,
          padding: "1px 6px",
        }}
      />
    </IonCol>
  );
};

export default Textarea;
