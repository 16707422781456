import React from "react";
import { IonLoading } from "@ionic/react";
import {} from "../hooks/loading";

interface LoadingProps {
  isLoading: boolean;
  setIsLoading(value: boolean): void;
}

const Loading: React.FC<LoadingProps> = ({ isLoading, setIsLoading }) => {
  return (
    <IonLoading
      mode="ios"
      isOpen={isLoading}
      onDidDismiss={() => setIsLoading(false)}
      message={"Carregando..."}
      duration={5000}
    />
  );
};

export default Loading;
