import {
  Procedure,
  ProcedureForm,
  ProcedureActionsTypes,
  GET_PROCEDURES,
  CLEAR_PROCEDURES,
  UPDATE_PROCEDURE,
} from "./types";
import { ThunkDispatch } from "redux-thunk";
import { initialize } from "redux-form";
import { showToast } from "../../../store/toast/actions";
import ErrorHandler from "../../../helpers/Legacy/errorsHandler";
import api from "../../../services/api";

import { history } from "../../../App";

const sessionKey = `${process.env.REACT_APP_SESSION_NAME}`;
export function getList(values: ProcedureForm) {
  localStorage.setItem(
    `${sessionKey}_doctor_procedures`,
    JSON.stringify(values)
  );
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .get("doctor/procedures", {
        params: {
          hospital: values.hospital?.value,
          all: values.all,
          situation: values.situation,
          date: values.date,
        },
      })
      .then((response) => {
        dispatch({ type: GET_PROCEDURES, payload: response.data });
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function clearList(): ProcedureActionsTypes {
  return {
    type: CLEAR_PROCEDURES,
    payload: undefined,
  };
}

export function update(values: Procedure) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .put(`doctor/procedures/${values.id}`, values)
      .then((response) => {
        dispatch({ type: UPDATE_PROCEDURE, payload: response.data });
        dispatch(showToast("Dados salvos com sucesso", "success"));
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function show(id: number) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .get(`doctor/procedures/${id}`)
      .then((response) => {
        dispatch(initialize("prescription", response.data));
        dispatch({ type: "SET_DOCTOR_PRESCRIPTION_PAGE", payload: 1 });
        history.push("/doctor/prescription");
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function suspend(id: number) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .put(`doctor/procedures/suspend/${id}`)
      .then((response) => {
        dispatch({ type: UPDATE_PROCEDURE, payload: response.data });
        dispatch(showToast("Dados salvos com sucesso", "success"));
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function assume(id: number) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .put(`doctor/procedures/assume/${id}`)
      .then((response) => {
        dispatch({ type: UPDATE_PROCEDURE, payload: response.data });
        dispatch(showToast("Dados salvos com sucesso", "success"));
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function repeat(id: number) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .get(`doctor/procedures/repeat/${id}`)
      .then((response) => {
        dispatch(initialize("prescription", response.data));
        dispatch({ type: "SET_DOCTOR_PRESCRIPTION_PAGE", payload: 1 });
        history.push("/doctor/prescription");
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}
