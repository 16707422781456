import React from "react";
import { IonCol, IonLabel, IonTextarea } from "@ionic/react";

interface Props {
  input: {
    name: string;
    value: string;
    onChange(e: string | null | undefined): void;
  };
  label?: string;
  autoGrow: boolean;
  autoCapitalize?: "on" | "off";
  autoFocus?: boolean;
  cols: number;
  rows?: number;
  maxlength?: number;
  minlength?: number;
  readOnly?: boolean;
  required?: boolean;
  spellCheck?: boolean;
  size?: string;
}

const Input: React.FC<Props> = (props) => {
  return (
    <IonCol size={props.size || "12"}>
      <IonLabel position="stacked" style={{ color: "#000", fontWeight: 600 }}>
        {props.label}
      </IonLabel>
      <IonTextarea
        name={props.input.name}
        value={props.input.value}
        onIonChange={(e) => props.input.onChange(e.detail.value)}
        autoGrow={props.autoGrow}
        autocapitalize={props.autoCapitalize ? props.autoCapitalize : "off"}
        autofocus={props.autoFocus ? true : false}
        cols={props.cols}
        rows={props.rows}
        maxlength={props.maxlength}
        minlength={props.minlength}
        readonly={props.readOnly ? true : false}
        required={props.required ? true : false}
        spellcheck={props.spellCheck ? true : false}
        style={{
          marginTop: "5px",
          width: "100%",
          color: "#555555",
          backgroundColor: "#FFFFFF",
          backgroundImage: "none",
          borderColor: "hsl(0, 0%, 80%)",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "1px",
          lineJeight: 1.5,
          padding: "1px 6px",
        }}
      />
    </IonCol>
  );
};

export default Input;
