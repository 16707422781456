import React, { useState } from "react";
import { format, compareAsc } from "date-fns";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLabel,
  IonDatetime,
  IonList,
  IonItem,
  IonItemGroup,
  IonItemDivider,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";

import api from "../../services/api";
import { useToast } from "../../hooks/toast";
import { useValidationErrors } from "../../hooks/errors";

import Main from "../../components/Main";

const sessionName = process.env.REACT_APP_SESSION_NAME;

interface HospitalIndicator {
  id: number;
  name: string;
  type: string;
  total: number;
  percentage: number;
}

interface Indicators {
  hospitals: HospitalIndicator[];
  total: number;
}

const IndicatorsByHospital: React.FC = () => {
  const { addToast } = useToast();
  const { getValidationErrors } = useValidationErrors();
  const [from, setFrom] = useState<string | null | undefined>(
    localStorage.getItem(`@${sessionName}:from`) || null
  );
  const [to, setTo] = useState<string | null | undefined>(
    localStorage.getItem(`@${sessionName}:to`) || null
  );

  const [values, setValues] = useState<Indicators | null>(null);

  const getList = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (from && to) {
      localStorage.setItem(`@${sessionName}:from`, from);
      localStorage.setItem(`@${sessionName}:to`, to);

      if (
        compareAsc(new Date(from.substr(0, 10)), new Date(to.substr(0, 10))) <=
        0
      ) {
        await api
          .get<any>("administrative/indicators/hospital", {
            params: { from, to },
          })
          .then((response) => {
            setValues(response.data);
          })
          .catch((error) => {
            getValidationErrors(error.response);
          });
      } else {
        addToast({
          message: "A primeira data deve ser maior que a segunda",
          color: "warning",
        });
      }
    }
  };

  return (
    <Main title="Indicadores p/ Hospital">
      <IonCard>
        <IonCardContent>
          <form onSubmit={getList}>
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonLabel position="stacked">De</IonLabel>
                  <IonDatetime
                    value={from}
                    onIonChange={(e) => setFrom(e.detail.value)}
                    cancelText="Cancelar"
                    doneText="Ok"
                    displayFormat="DD/MM/YYYY"
                    max={format(new Date(), "yyyy-MM-dd")}
                    style={{
                      marginTop: "5px",
                      height: "42px",
                      width: "100%",
                      padding: "6px 12px",
                      color: "#555555",
                      backgroundColor: "#FFFFFF",
                      backgroundImage: "none",
                      borderColor: "hsl(0, 0%, 80%)",
                      borderRadius: "4px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      lineHeight: 2,
                    }}
                  />
                </IonCol>
                <IonCol size="12">
                  <IonLabel position="stacked">Até</IonLabel>
                  <IonDatetime
                    value={to}
                    onIonChange={(e) => setTo(e.detail.value)}
                    cancelText="Cancelar"
                    doneText="Ok"
                    displayFormat="DD/MM/YYYY"
                    max={format(new Date(), "yyyy-MM-dd")}
                    style={{
                      marginTop: "5px",
                      height: "42px",
                      width: "100%",
                      padding: "6px 12px",
                      color: "#555555",
                      backgroundColor: "#FFFFFF",
                      backgroundImage: "none",
                      borderColor: "hsl(0, 0%, 80%)",
                      borderRadius: "4px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      lineHeight: 2,
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="6">
                  <IonButton type="submit" color="primary" expand="block">
                    Consultar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
      {!!values && !!values.hospitals && (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Procedimentos: {values.total}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              {values.hospitals.map((hospital) => {
                return (
                  <IonItemGroup key={hospital.id}>
                    <IonItemDivider mode="ios">
                      <IonLabel mode="ios">{hospital.name}</IonLabel>
                    </IonItemDivider>
                    <IonItem>
                      <IonLabel>{hospital.type}</IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel>
                        Procedimentos: {hospital.total} ({hospital.percentage}%)
                      </IonLabel>
                    </IonItem>
                  </IonItemGroup>
                );
              })}
            </IonList>
          </IonCardContent>
        </IonCard>
      )}
    </Main>
  );
};

export default IndicatorsByHospital;
