import {
  Prescriptions,
  DoctorPrescriptionActionsTypes,
  GET_DOCTOR_PRESCRIPTIONS,
  SET_DOCTOR_PRESCRIPTION_PAGE,
} from "./types";

const initialState: Prescriptions = {
  prescriptions: [],
  page: 1,
};

export function doctorPrescriptionReducer(
  state = initialState,
  action: DoctorPrescriptionActionsTypes
): Prescriptions {
  switch (action.type) {
    case GET_DOCTOR_PRESCRIPTIONS:
      return {
        ...state,
        prescriptions: action.payload,
      };
    case SET_DOCTOR_PRESCRIPTION_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
}
