import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { IonCol, IonLabel, IonSelect } from "@ionic/react";

interface Props {
  name: string;
  label?: string;
  size?: string;
  readOnly?: boolean;
}

const Select: React.FC<Props> = ({
  name,
  label,
  size,
  readOnly,
  children,
  ...rest
}) => {
  const inputRef = useRef<HTMLIonSelectElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref, value) => {
        if (value) {
          ref.current.value = value;
        } else {
          ref.current.value = "";
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <IonCol size={size || "12"}>
      <IonLabel position="stacked">{label}</IonLabel>
      <IonSelect
        ref={inputRef}
        name={name}
        defaultValue={defaultValue}
        cancelText="Cancelar"
        okText="Ok"
        disabled={readOnly ? true : false}
        interface="action-sheet"
        {...rest}
        style={{
          marginTop: "5px",
          height: "42px",
          width: "100%",
          padding: "6px 12px",
          color: "#555555",
          backgroundColor: "#FFFFFF",
          backgroundImage: "none",
          borderColor: "hsl(0, 0%, 80%)",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "1px",
          lineHeight: 2,
        }}
      >
        {children}
      </IonSelect>
    </IonCol>
  );
};

export default Select;
