import {
  Assistance,
  AssistanceForm,
  AssistanceActionsTypes,
  GET_ASSISTANCES,
  CLEAR_ASSISTANCES,
  UPDATE_ASSISTANCE,
  DELETE_ASSISTANCE,
} from "./types";
import { ThunkDispatch } from "redux-thunk";
import { showToast } from "../../../store/toast/actions";
import ErrorHandler from "../../../helpers/Legacy/errorsHandler";
import api from "../../../services/api";

const sessionKey = `${process.env.REACT_APP_SESSION_NAME}`;
export function getList(values: AssistanceForm) {
  localStorage.setItem(
    `${sessionKey}_doctor_assistances`,
    JSON.stringify(values)
  );
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .get("doctor/assistances", {
        params: {
          from: values.from,
          to: values.to,
        },
      })
      .then((response) => {
        dispatch({ type: GET_ASSISTANCES, payload: response.data });
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function clearList(): AssistanceActionsTypes {
  return {
    type: CLEAR_ASSISTANCES,
    payload: undefined,
  };
}

export function update(values: Assistance) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .put<any>(`doctor/assistances/${values.id}`, values)
      .then((response) => {
        if (response.data?.confirmed === "N" && response.data?.doctor) {
          dispatch({ type: UPDATE_ASSISTANCE, payload: response.data });
        } else {
          dispatch({ type: DELETE_ASSISTANCE, payload: response.data });
        }
        dispatch(showToast("Dados salvos com sucesso", "success"));
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}
