import React, { createContext, useContext, useCallback, useState } from "react";
import Toast from "../components/Toast";

export interface ToastMessage {
  id: number;
  message: string;
  color:
    | "primary"
    | "secondary"
    | "tertiary"
    | "success"
    | "warning"
    | "danger"
    | "light"
    | "medium"
    | "dark";
}

interface ToastContextData {
  addToast(message: Omit<ToastMessage, "id">): void;
  removeToast(id: number): void;
  successToast(): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback(
    ({ message, color }: Omit<ToastMessage, "id">) => {
      const id = Date.now();

      const toast = {
        id,
        message,
        color,
      };

      setMessages((state) => [...state, toast]);
    },
    []
  );

  const removeToast = useCallback((id: number) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  const successToast = useCallback(() => {
    addToast({
      message: "Operação realiza com sucesso",
      color: "success",
    });
  }, [addToast]);

  return (
    <ToastContext.Provider value={{ addToast, removeToast, successToast }}>
      {children}
      <Toast messages={messages} />
    </ToastContext.Provider>
  );
};

function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }

  return context;
}

export { ToastContext, ToastProvider, useToast };
