export interface Machine {
  machine_id: number;
  date: string;
  number: string;
  serial: string;
  machine_type: string;
  car_plate: number;
  source?: {
    value: number;
    label: string;
  };
  destination?: {
    value: number;
    label: string;
  };
}

export interface MachineList {
  list: Machine[];
}

export const GET_DRIVER_MACHINES = "GET_DRIVER_MACHINES";
export const CLEAR_DRIVER_MACHINES = "CLEAR_DRIVER_MACHINES";

interface GetMachinesActions {
  type: typeof GET_DRIVER_MACHINES;
  payload: Machine[];
}

interface ClearMachinesActions {
  type: typeof CLEAR_DRIVER_MACHINES;
  payload: undefined;
}

export type MachinesActionsTypes = GetMachinesActions | ClearMachinesActions;
