import React, { useCallback, useEffect, useState } from "react";
import { IonCol } from "@ionic/react";
import { GroupedOptionsType } from "react-select";

import api from "../../services/api";

import FormLabel from "./FormLabel";
import SearchInput from "./SearchInput";

interface Props {
  size?: string;
  label: string;
  name: string;
  assistanceId: string | number;
  productId: number;
  simpleValue?: boolean;
  onOptionChange?(value: any): void;
}

const Select: React.FC<Props> = ({
  size,
  label,
  name,
  assistanceId,
  productId,
  simpleValue,
  onOptionChange,
  ...rest
}) => {
  const [values, setValues] = useState<
    GroupedOptionsType<{
      label: string;
      value: string;
    }>
  >([]);

  const search = useCallback(
    (searchValue: string) => {
      api
        .get<
          GroupedOptionsType<{
            label: string;
            value: string;
          }>
        >("search/products_batches_in_hospital", {
          params: {
            search: searchValue,
            assistance_id: assistanceId,
            product_id: productId,
          },
        })
        .then((resp) => {
          setValues(resp.data);
        });
    },
    [assistanceId, productId]
  );

  useEffect(() => {
    search("");
  }, [search]);

  return (
    <IonCol size={size || "12"}>
      <FormLabel>{label}</FormLabel>
      <SearchInput
        name={name}
        simpleValue={simpleValue}
        onOptionChange={onOptionChange}
        onInputChange={(value) => search(value)}
        options={values}
        {...rest}
      />
    </IonCol>
  );
};

export default Select;
