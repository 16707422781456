import React from "react";
import { IonSelectOption } from "@ionic/react";

interface ProceduresType {
  value: string;
  label: string;
}

interface LocationsType {
  value: string;
  label: string;
}

export const getProcedures = () => {
  let procedures: ProceduresType[] = [];

  procedures = [
    { value: "BURETA", label: "BURETA" },
    { value: "CRRT", label: "CRRT" },
    { value: "DPA", label: "DPA" },
    { value: "DPAC", label: "DPAC" },
    { value: "DPAP (pedriatria)", label: "DPAP (pedriatria)" },
    { value: "DPI", label: "DPI" },
    { value: "HDC", label: "HDC" },
    { value: "HDCP (pedriatria)", label: "HDCP (pedriatria)" },
    { value: "HDI", label: "HDI" },
    { value: "HDIP (pedriatria)", label: "HDIP (pedriatria)" },
    { value: "HDP", label: "HDP" },
    { value: "HDPP (pedriatria)", label: "HDPP (pedriatria)" },
    { value: "PARECER", label: "PARECER" },
    { value: "PLASM", label: "PLASM" },
    { value: "VISITA HOSPITALAR", label: "VISITA HOSPITALAR" },
  ];

  return procedures.map((procedure) => (
    <IonSelectOption key={procedure.value} value={procedure.value}>
      {procedure.label}
    </IonSelectOption>
  ));
};

export const getLocations = () => {
  let locations: LocationsType[] = [];

  locations = [
    { value: "ABDÔMEN", label: "ABDÔMEN" },
    { value: "CABECA E PESCOÇO", label: "CABECA E PESCOÇO" },
    { value: "CEMO", label: "CEMO" },
    { value: "CLÍNICA MÉDICA MISTA", label: "CLÍNICA MÉDICA MISTA" },
    { value: "CLÍNICA MÉDICA CIRÚRGICA", label: "CLÍNICA MÉDICA CIRÚRGICA" },
    { value: "CTI", label: "CTI" },
    { value: "CTI 1", label: "CTI 1" },
    { value: "CTI 2", label: "CTI 2" },
    { value: "CTI 3", label: "CTI 3" },
    { value: "CTI 4", label: "CTI 4" },
    { value: "CTI 5", label: "CTI 5" },
    { value: "CTI 6", label: "CTI 6" },
    { value: "CTI PEDIATRIA", label: "CTI PEDIATRIA" },
    { value: "ENFERMARIA", label: "ENFERMARIA" },
    { value: "ENFERMARIA FEMININA", label: "ENFERMARIA FEMININA" },
    { value: "ENFERMARIA MASCULINA", label: "ENFERMARIA MASCULINA" },
    { value: "GINECOLOGIA", label: "GINECOLOGIA" },
    { value: "HEMATOLOGIA", label: "HEMATOLOGIA" },
    { value: "INCA IV", label: "INCA IV" },
    { value: "MAMA", label: "MAMA" },
    { value: "NEUROLOGIA", label: "NEUROLOGIA" },
    { value: "ONCOLOGIA", label: "ONCOLOGIA" },
    { value: "PEDIATRIA", label: "PEDIATRIA" },
    { value: "PLÁSTICA", label: "PLÁSTICA" },
    { value: "POI", label: "POI" },
    { value: "POLITRAUMA I", label: "POLITRAUMA I" },
    { value: "POLITRAUMA II", label: "POLITRAUMA II" },
    { value: "PÓS", label: "PÓS" },
    { value: "PROCTOLOGIA", label: "PROCTOLOGIA" },
    { value: "SALA DE HD", label: "SALA DE HD" },
    { value: "SALA AMARELA", label: "SALA AMARELA" },
    { value: "SALA VERMELHA", label: "SALA VERMELHA" },
    { value: "QUARTO", label: "QUARTO" },
    { value: "TOC", label: "TOC" },
    { value: "TÓRAX", label: "TÓRAX" },
    { value: "UCO", label: "UCO" },
    { value: "UCI", label: "UCI" },
    { value: "UCIC", label: "UCIC" },
    { value: "UI", label: "UI" },
    { value: "UPO", label: "UPO" },
    { value: "UROLOGIA", label: "UROLOGIA" },
    { value: "OUTROS", label: "OUTROS" },
  ];

  return locations.map((location) => (
    <IonSelectOption key={location.value} value={location.value}>
      {location.label}
    </IonSelectOption>
  ));
};

export const getType = (proc: string) => {
  if (["CRRT"].includes(proc)) {
    return "CONT";
  } else if (["DPA", "DPAC", "DPAP (pedriatria)", "DPI"].includes(proc)) {
    return "DP";
  } else {
    return "DEFAULT";
  }
};
