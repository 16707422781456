import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IonCard, IonCardHeader, IonText, IonRow, IonCol } from '@ionic/react';
import { update } from '../../../store/nurse/assistance/actions';

import Card from './card';

import './styles.css';

let List = function (props) {
  const { data = [], accomplished = 0, allocated = 0, to_allocate = 0 } = props.list || [];
  const { update } = props;

  function renderRows() {
    return data.map(d => (
      <Card d={d} key={d.id} update={update} />
    ));
  }

  return (
    <>
      {data.length > 0 ?
        <IonCard>
          <IonCardHeader>
            <IonRow>
              <IonCol size="4">
                <IonText>Realizado: {accomplished}</IonText>
              </IonCol>
              <IonCol size="4">
                <IonText>Alocado: {allocated}</IonText>
              </IonCol>
              <IonCol size="4">
                <IonText>Alocar: {to_allocate}</IonText>
              </IonCol>
            </IonRow>
          </IonCardHeader>
        </IonCard>
        :
        <></>
      }
      {renderRows()}
    </>
  );
}

const mapStateToProps = state => ({ list: state.nurseAssistance.list })
const mapDispatchToProps = dispatch => bindActionCreators({ update }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(List)
