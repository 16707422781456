export interface Assistance {
  id: number;
  date: string;
  hospital: string;
  patient: string;
  new_patient: string;
  location: string;
  bed: string;
  technician1: string;
  technician2: string;
  technician3: string;
  technician4: string;
  destination: string;
  event: string;
  proc: string;
  time: string;
  start: string;
  end: string;
  simultaneous_id: number;
  access: string;
  site: string;
  precaution: boolean;
  machine: number;
  osmosis: number;
  home_choice: number;
  doctor: string;
  agreement: string;
  registration: string;
  note: string;
  situation: string;
  validated: string;
  report: number;
  prescription: number;
}

export interface AssistanceList {
  list: Assistance[];
}

export interface AssistanceForm {
  hospital: {
    value: number;
    label: string;
  };
  date: string;
}

export const GET_ADMINISTRATIVE_ASSISTANCES = "GET_ADMINISTRATIVE_ASSISTANCES";
export const CLEAR_ADMINISTRATIVE_ASSISTANCES =
  "CLEAR_ADMINISTRATIVE_ASSISTANCES";

interface GetAssistancesActions {
  type: typeof GET_ADMINISTRATIVE_ASSISTANCES;
  payload: Assistance[];
}

interface ClearAssistancesActions {
  type: typeof CLEAR_ADMINISTRATIVE_ASSISTANCES;
  payload: undefined;
}

export type AssistancesActionsTypes =
  | GetAssistancesActions
  | ClearAssistancesActions;
