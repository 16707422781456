export interface Progress {
  id: number;
  date: string;
  hospital: string;
  patient: string;
  new_patient: string;
  location: string;
  bed: string;
  technician1: string;
  technician2: string;
  technician3: string;
  technician4: string;
  destination: string;
  event: string;
  proc: string;
  time: string;
  start: string;
  end: string;
  simultaneous_id: number;
  access: string;
  site: string;
  precaution: boolean;
  machine: number;
  osmosis: number;
  home_choice: number;
  doctor: string;
  agreement: string;
  registration: string;
  note: string;
  situation: string;
  validated: string;
  report: number;
  prescription: number;
}

export interface ProgressList {
  list: Progress[];
}

export interface ProgressForm {
  hospital: {
    value: number;
    label: string;
  };
}

export const GET_ADMINISTRATIVE_PROGRESS = "GET_ADMINISTRATIVE_PROGRESS";
export const CLEAR_ADMINISTRATIVE_PROGRESS = "CLEAR_ADMINISTRATIVE_PROGRESS";

interface GetProgressActions {
  type: typeof GET_ADMINISTRATIVE_PROGRESS;
  payload: Progress[];
}

interface ClearProgressActions {
  type: typeof CLEAR_ADMINISTRATIVE_PROGRESS;
  payload: undefined;
}

export type ProgressActionsTypes = GetProgressActions | ClearProgressActions;
