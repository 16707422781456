import React, { useEffect, useRef } from "react";
import ReactSelect, {
  GroupedOptionsType,
  InputActionMeta,
  OptionsType,
} from "react-select";
import { useField } from "@unform/core";

interface Props {
  name: string;
  simpleValue?: boolean;
  //onChange(...event: any[]): void;
  onOptionChange?(value: any): void;
  onInputChange:
    | ((newValue: string, actionMeta: InputActionMeta) => void)
    | undefined;
  options: any;
  // options:
  //   | GroupedOptionsType<{
  //       label: string;
  //       value: string;
  //     }>
  //   | OptionsType<{
  //       label: string;
  //       value: string;
  //     }>
  //   | undefined;
}

const SearchInput: React.FC<Props> = (
  {
    name,
    simpleValue,
    onOptionChange,
    //onChange,
    onInputChange,
    options,
    ...rest
  },
  ref
) => {
  const reactSelectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: reactSelectRef.current,
      getValue: (ref) => {
        if (simpleValue) {
          if (!!ref.state?.value?.value) {
            return ref.state.value.value;
          }
        } else {
          if (!!ref.state?.value) {
            return ref.state.value;
          }
        }
      },
      setValue: (ref, value) => {
        return (ref.state.value = value);
      },
      clearValue: (ref) => {
        ref.state.value = "";
      },
    });
  }, [fieldName, name, registerField, simpleValue]);

  return (
    <ReactSelect
      ref={reactSelectRef}
      defaultInputValue={defaultValue}
      style={{ width: "100%" }}
      placeholder=""
      classNamePrefix="select"
      onChange={(option) => {
        if (!!option) {
          if (!!onOptionChange) onOptionChange(option.value);
        }
      }}
      onInputChange={onInputChange}
      noOptionsMessage={() => "Nenhuma opção"}
      simpleValue
      options={options}
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        control: (provided) => ({
          ...provided,
          marginTop: "5px",
          height: "42px",
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? "#FFF" : "#000",
          background: state.isSelected ? "#000" : "#FFF",
        }),
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
      {...rest}
    />
  );
};

export default SearchInput;
