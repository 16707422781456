interface Intercurrence {
  value: string;
  label: string;
}

const getIntercurrences: Intercurrence[] = [
  {
    value: "Hipotensão",
    label: "Hipotensão",
  },
  {
    value: "Cãibras",
    label: "Cãibras",
  },
  {
    value: "Náuseas e Vômitos",
    label: "Náuseas e Vômitos",
  },
  {
    value: "Cefaleia",
    label: "Cefaleia",
  },
  {
    value: "Dor Torácica",
    label: "Dor Torácica",
  },
  {
    value: "Dorsalgia",
    label: "Dorsalgia",
  },
  {
    value: "Prurido",
    label: "Prurido",
  },
  {
    value: "Febre e Calafrios",
    label: "Febre e Calafrios",
  },
];

export { getIntercurrences };
