import * as Yup from "yup";

/* eslint-disable */
Yup.setLocale({
  mixed: {
    notType: "O campo ${label} é inválido",
    required: "O campo ${label} é obrigatório",
  },
  number: {
    min: "O campo ${label} deve ser maior que ${min}",
  },
});
/* eslint-enable */

export default Yup;
