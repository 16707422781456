import React from "react";
import { IonApp, IonSpinner } from "@ionic/react";

const Spinner: React.FC = () => (
  <IonApp className="ion-justify-content-center ion-align-items-center">
    <IonSpinner
      color="primary"
      name="lines"
      style={{ width: "100vw", height: "50px" }}
    />
  </IonApp>
);

export default Spinner;
