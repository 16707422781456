export interface Assistance {
  id: number;
  situation: string;
  proc: string;
  date: string;
  hospital: string;
  patient: string;
  doctor: string;
  prescription: number;
  annotations: number;
  evolution: number;
}

export interface Assistances {
  assistances: Assistance[];
}

export interface TechnicianAssistanceForm {
  date: string;
}

export const GET_TECHNICIAN_ASSISTANCES = "GET_TECHNICIAN_ASSISTANCES";
export const CLEAR_TECHNICIAN_ASSISTANCES = "CLEAR_TECHNICIAN_ASSISTANCES";

interface GetTechnicianAssistancesActions {
  type: typeof GET_TECHNICIAN_ASSISTANCES;
  payload: Assistance[];
}

interface ClearTechnicianAssistancesActions {
  type: typeof CLEAR_TECHNICIAN_ASSISTANCES;
  payload: undefined;
}

export type TechnicianAssistanceActionsTypes =
  | GetTechnicianAssistancesActions
  | ClearTechnicianAssistancesActions;
