import React from "react";
import { IonCol, IonLabel, IonText } from "@ionic/react";
import If from "./if";

interface Props {
  size: string;
  value: any;
  label: string;
}

const IfCol: React.FC<Props> = ({ size, value, label }) => {
  return (
    <If test={value}>
      <IonCol size={size}>
        <IonLabel>{label}: </IonLabel>
        <IonText>{value}</IonText>
      </IonCol>
    </If>
  );
};

export default IfCol;
