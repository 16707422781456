import React, { useState } from "react";
import { useParams } from "react-router";
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonItemDivider,
  IonLabel,
  IonRow,
  IonText,
  useIonViewDidEnter,
} from "@ionic/react";
import { format } from "date-fns";

import api from "../services/api";

import Main from "../components/Main";
import { getType } from "../shared/Procedures";

interface ParamTypes {
  assistanceId: string;
  procName: string;
}

interface AssistisAnnotation {
  id: number;
  time: string;
  pa: string;
  fc: number;
  tax: number;
  heparin: number;
  arterial_pressure: number;
  venous_pressure: number;
  serum: number;
  blood_flow: number;
  dialysate_flow: number;
  sf: number;
  citrate: number;
  calcium: number;
  pbe: number;
  pv: number;
  tf: number;
  tmp: number;
  partial_balance: number;
  created_at: string;
  updated_at: string;
}

const GeneralAssistsAnnotations: React.FC = () => {
  const { assistanceId, procName } = useParams<ParamTypes>();
  const [data, setData] = useState<AssistisAnnotation[]>([]);

  useIonViewDidEnter(() => {
    api.get<any>(`assists_annotations/${assistanceId}`).then((response) => {
      setData(response.data);
    });
  });

  return (
    <Main title="Anotações" back>
      {data.map((values) => {
        return (
          <IonCard key={values.id}>
            <IonCardContent>
              <IonRow>
                <IonCol size="12">
                  <IonLabel>Hora: </IonLabel>
                  <IonText>{values.time.substr(0, 5)}</IonText>
                </IonCol>
                {getType(procName) === "CONT" ? (
                  <>
                    <IonCol size="12">
                      <IonLabel>PA(mmHg): </IonLabel>
                      <IonText>{values.pa}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>FC(bpm): </IonLabel>
                      <IonText>{values.fc}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>Tax(ºC): </IonLabel>
                      <IonText>{values.tax}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>Solução de Heparina(ml/h): </IonLabel>
                      <IonText>{values.heparin}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>P.Art(mmHg): </IonLabel>
                      <IonText>{values.arterial_pressure}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>P.Ven(mmHg): </IonLabel>
                      <IonText>{values.venous_pressure}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>Soro(ml): </IonLabel>
                      <IonText>{values.serum}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>Fluxo Sanguíneo(ml/min): </IonLabel>
                      <IonText>{values.blood_flow}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>Fluxo Dialisato(ml/min): </IonLabel>
                      <IonText>{values.dialysate_flow}</IonText>
                    </IonCol>
                  </>
                ) : (
                  <>
                    <IonCol size="12">
                      <IonLabel>Balanço Parcial: </IonLabel>
                      <IonText>{values.partial_balance}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonItemDivider color="light">
                        <IonLabel
                          color="dark"
                          style={{
                            whiteSpace: "pre-line",
                            paddingRight: 5,
                          }}
                        >
                          Anticoagulação
                        </IonLabel>
                      </IonItemDivider>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>SF 0,9%: </IonLabel>
                      <IonText>{values.sf}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>Heparina: </IonLabel>
                      <IonText>{values.heparin}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>Citrato: </IonLabel>
                      <IonText>{values.citrate}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>Cálcio: </IonLabel>
                      <IonText>{values.calcium}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonItemDivider color="light">
                        <IonLabel
                          color="dark"
                          style={{
                            whiteSpace: "pre-line",
                            paddingRight: 5,
                          }}
                        >
                          Pressões
                        </IonLabel>
                      </IonItemDivider>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>PA(mmHg): </IonLabel>
                      <IonText>{values.pa}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>PBE: </IonLabel>
                      <IonText>{values.pbe}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>PV: </IonLabel>
                      <IonText>{values.pv}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>TF: </IonLabel>
                      <IonText>{values.tf}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>TMP: </IonLabel>
                      <IonText>{values.tmp}</IonText>
                    </IonCol>
                  </>
                )}
                <IonCol size="12">
                  {!!values.created_at && (
                    <>
                      <IonLabel>Data: </IonLabel>
                      <IonText>
                        {format(
                          new Date(values.created_at.replace(" ", "T")),
                          "dd/MM/yyyy HH:mm"
                        )}
                      </IonText>
                    </>
                  )}
                </IonCol>
                <IonCol size="12">
                  {!!values.updated_at &&
                    values.updated_at !== values.created_at && (
                      <>
                        <IonLabel>Última Edição: </IonLabel>
                        <IonText>
                          {format(
                            new Date(values.updated_at.replace(" ", "T")),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </IonText>
                      </>
                    )}
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
        );
      })}
    </Main>
  );
};

export default GeneralAssistsAnnotations;
