import React, { createContext, useContext, useCallback, useState } from "react";
import Loading from "../components/Loading";

interface LoadingContextData {
  isLoading: boolean;
  showLoading(): void;
  hideLoading(): void;
}

const LoadingContext = createContext<LoadingContextData>(
  {} as LoadingContextData
);

const LoadingProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  const hideLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>
      {children}
      <Loading isLoading={isLoading} setIsLoading={setIsLoading} />
    </LoadingContext.Provider>
  );
};

function useLoading(): LoadingContextData {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }

  return context;
}

export { LoadingContext, LoadingProvider, useLoading };
