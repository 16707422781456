const INITIAL_STATE = { list: [] };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ASSISTANCE_SCHEDULED_FETCHING":
      return { ...state, list: action.payload };
    case "ASSISTANCE_SCHEDULED_CLEAR":
      return { ...state, list: [] };
    default:
      return state;
  }
};
