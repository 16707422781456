import React, { useEffect, useRef, useState } from "react";
import {
  IonAlert,
  IonApp,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonLoading,
  IonRow,
  IonText,
  isPlatform,
} from "@ionic/react";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import SignaturePad from "signature_pad";

import { useAuth } from "../hooks/auth";
import { useToast } from "../hooks/toast";
import { useValidationErrors } from "../hooks/errors";

import api from "../services/api";
// import getValidationErrors from '../helpers/getValidationErrors'

const scaleDataURL = (dataURL: string, maxWidth: number, maxHeight: number) => {
  return new Promise((done) => {
    var img = new Image();
    img.onload = () => {
      var scale, newWidth, newHeight, canvas, ctx;
      if (img.width < maxWidth) {
        scale = maxWidth / img.width;
      } else {
        scale = maxHeight / img.height;
      }
      newWidth = img.width * scale;
      newHeight = img.height * scale;
      canvas = document.createElement("canvas");
      canvas.height = newHeight;
      canvas.width = newWidth;
      ctx = canvas.getContext("2d");
      ctx?.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        newWidth,
        newHeight
      );
      done(canvas.toDataURL());
    };
    img.src = dataURL;
  });
};

interface UpdateSignatureResponseData {
  signature: boolean;
}

const Signature: React.FC = () => {
  const { user, signOut, updateSignature } = useAuth();
  const { addToast, successToast } = useToast();
  const { getValidationErrors } = useValidationErrors();

  const [signaturePad, setSignaturePad] = useState<any>(null);
  const [showLoading, setShowLoading] = useState(true);
  const [, setCount] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const contentRef = useRef<HTMLIonContentElement>(null);
  const signatureRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const platform = isPlatform("mobileweb") || isPlatform("desktop");
    if (!platform) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);
    }

    if (!!signatureRef.current) {
      setSignaturePad(new SignaturePad(signatureRef.current));
    }
  }, []);

  useEffect(() => {
    const setSignatureSize = () => {
      if (
        !!signaturePad &&
        !!signatureRef.current &&
        !!contentRef.current &&
        ScreenOrientation.type.substr(0, 9) === "landscape"
      ) {
        const contentWidth = Math.floor(contentRef.current.offsetWidth * 0.8);
        const canvasHeight = Math.floor((contentWidth / 400) * 100);

        signatureRef.current.width = contentWidth;
        signatureRef.current.height = canvasHeight;

        signaturePad.clear();

        if (contentWidth > 0 && canvasHeight > 0) {
          signaturePad.on();
          setShowLoading(false);
          clearInterval(interval);
        } else {
          signaturePad.off();
          setShowLoading(true);
        }
      }
      if (ScreenOrientation.type.substr(0, 9) === "landscape") {
        setCount((state) => {
          if (state > 5) {
            clearInterval(interval);
            return state;
          } else {
            return state + 1;
          }
        });
      }
    };
    const interval = setInterval(function () {
      setSignatureSize();
    }, 2000);

    setSignatureSize();

    return () => clearInterval(interval);
  }, [setCount, signaturePad]);

  const handleSubmit = () => {
    if (signaturePad.isEmpty()) {
      addToast({
        message: "A assinatura não pode estar em branco",
        color: "warning",
      });
    } else {
      if (!!signatureRef.current) {
        scaleDataURL(signatureRef.current.toDataURL(), 400, 100).then(
          async (imageBase64) => {
            await api
              .put<UpdateSignatureResponseData>(`${user.profile}/signature`, {
                signature: imageBase64,
              })
              .then((response) => {
                if (!!response.data) {
                  updateSignature(response.data.signature);
                }
                handleClear();
                successToast();
              })
              .catch((error) => {
                getValidationErrors(error.response);
              });
          }
        );
      }
    }
    setConfirm(false);
  };

  const handleClear = () => {
    if (!!signaturePad && !!signatureRef.current && !!contentRef.current) {
      const contentWidth = Math.floor(contentRef.current.offsetWidth * 0.8);
      const canvasHeight = Math.floor((contentWidth / 400) * 100);

      signatureRef.current.width = contentWidth;
      signatureRef.current.height = canvasHeight;

      signaturePad.clear();
    }
  };

  return (
    <IonApp>
      <IonContent ref={contentRef} scrollX={false} scrollY={false}>
        {!showLoading && (
          <IonGrid style={{ marginLeft: "7%", marginRight: "7%" }}>
            <IonRow>
              <IonCol size="12">
                <IonText>
                  Atenção: Cadastre sua assinatura digital , ela será usada para
                  validar as prescrições. Qualquer duvida entre em contato com o
                  Suporte.
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        <canvas
          ref={signatureRef}
          style={
            showLoading
              ? {}
              : {
                  marginTop: "5%",
                  marginLeft: "10%",
                  marginRight: "10%",
                  border: "2px solid #000",
                }
          }
        />
        {!showLoading && (
          <IonGrid>
            <IonRow>
              <IonCol size="4" className="ion-padding">
                <IonButton
                  type="submit"
                  color="primary"
                  expand="block"
                  onClick={() => setConfirm(true)}
                >
                  Enviar
                </IonButton>
              </IonCol>
              <IonCol size="4" className="ion-padding">
                <IonButton
                  type="submit"
                  color="secondary"
                  expand="block"
                  onClick={() => handleClear()}
                >
                  Limpar
                </IonButton>
              </IonCol>
              <IonCol size="4" className="ion-padding">
                <IonButton
                  type="submit"
                  color="danger"
                  expand="block"
                  onClick={() => signOut()}
                >
                  Sair
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        <IonAlert
          isOpen={confirm}
          onDidDismiss={() => setConfirm(false)}
          message={`Deseja realmente enviar essa assinatura?`}
          buttons={[
            {
              text: "Sim",
              handler: () => handleSubmit(),
            },
            {
              text: "Não",
              handler: () => setConfirm(false),
            },
          ]}
        />
      </IonContent>
      <IonLoading
        mode="ios"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Carregando..."}
        duration={10000}
      />
    </IonApp>
  );
};

export default Signature;
