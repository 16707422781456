import {
  AssistanceForm,
  AssistancesActionsTypes,
  GET_ADMINISTRATIVE_ASSISTANCES,
  CLEAR_ADMINISTRATIVE_ASSISTANCES,
} from "./types";
import { ThunkDispatch } from "redux-thunk";
import ErrorHandler from "../../../helpers/Legacy/errorsHandler";
import api from "../../../services/api";

const sessionKey = `${process.env.REACT_APP_SESSION_NAME}`;
export function getList(values: AssistanceForm) {
  localStorage.setItem(
    `${sessionKey}_administrative_assistances`,
    JSON.stringify(values)
  );
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .get("administrative/assistances", {
        params: {
          hospital: values.hospital?.value,
          date: values.date,
        },
      })
      .then((response) => {
        dispatch({
          type: GET_ADMINISTRATIVE_ASSISTANCES,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function clearList(): AssistancesActionsTypes {
  return {
    type: CLEAR_ADMINISTRATIVE_ASSISTANCES,
    payload: undefined,
  };
}
