import {
  AssistanceList,
  AssistancesActionsTypes,
  GET_ADMINISTRATIVE_ASSISTANCES,
  CLEAR_ADMINISTRATIVE_ASSISTANCES,
} from "./types";

const initialState: AssistanceList = {
  list: [],
};

export function administrativeAssistanceReducer(
  state = initialState,
  action: AssistancesActionsTypes
): AssistanceList {
  switch (action.type) {
    case GET_ADMINISTRATIVE_ASSISTANCES:
      return {
        ...state,
        list: action.payload,
      };
    case CLEAR_ADMINISTRATIVE_ASSISTANCES:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
