interface Props {
  value?: boolean;
  upper?: boolean;
}

export default function (props: Props) {
  if (props.value) {
    if (props.upper === true) {
      return "SIM";
    } else {
      return "Sim";
    }
  } else {
    if (props.upper === true) {
      return "NÃO";
    } else {
      return "Não";
    }
  }
}
