import { Toast, ToastActionsTypes, SHOW_TOAST, HIDE_TOAST } from "./types";

export function showToast(
  message: Toast["message"],
  color?: Toast["color"],
  header?: Toast["header"]
): ToastActionsTypes {
  return {
    type: SHOW_TOAST,
    payload: {
      isOpen: true,
      message,
      color,
      header,
    },
  };
}

export function hideToast(): ToastActionsTypes {
  return {
    type: HIDE_TOAST,
    payload: undefined,
  };
}
