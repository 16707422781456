import React, { createContext, useCallback, useState, useContext } from "react";
import { isPlatform } from "@ionic/react";
import api from "../services/api";

export interface UserData {
  user_id: number;
  tech_id: number;
  name: string;
  profile: string;
  reset: boolean;
  signature: boolean;
  is_admin: boolean;
}

interface AuthState {
  user: UserData;
  token: string;
}

interface ValidateResponseData {
  valid: boolean;
  app_version: string;
  android_version: string;
  ios_version: string;
}

export interface AuthContextData {
  user: UserData;
  appUpdated: boolean;
  signIn(user: UserData, token: string): void;
  signOut(): void;
  validateToken(): void;
  updateUserName(name: string): void;
  updateSignature(signature: boolean): void;
  passwordChange(reset: boolean): void;
}

const sessionName = process.env.REACT_APP_SESSION_NAME;
const appVersion = process.env.REACT_APP_VERSION;

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const userDecrypt = (userEncrypted: string) => {
  let userDecrypted = userEncrypted.split("").reverse().join("");
  userDecrypted = atob(userDecrypted);

  return JSON.parse(userDecrypted);
};

export const userEncrypt = (userDecrypted: UserData) => {
  let userEncrypted = JSON.stringify(userDecrypted);
  userEncrypted = btoa(userEncrypted);

  return userEncrypted.split("").reverse().join("").replace("==", "");
};

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const userEncrypted = localStorage.getItem(`@${sessionName}:user`);
    const token = localStorage.getItem(`@${sessionName}:token`);

    if (userEncrypted && token) {
      try {
        const user = userDecrypt(userEncrypted);

        return {
          user,
          token,
        };
      } catch {
        localStorage.removeItem(`@${sessionName}:user`);
        localStorage.removeItem(`@${sessionName}:token`);

        return {} as AuthState;
      }
    }

    return {} as AuthState;
  });
  const [appUpdated, setAppUpdated] = useState(true);

  const signIn = useCallback((userEncrypted, token) => {
    localStorage.setItem(`@${sessionName}:user`, userEncrypted);
    localStorage.setItem(`@${sessionName}:token`, token);

    setData({
      user: userDecrypt(userEncrypted),
      token,
    });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem(`@${sessionName}:user`);
    localStorage.removeItem(`@${sessionName}:token`);

    setData({} as AuthState);
  }, []);

  const validateToken = useCallback(async () => {
    if (!!data.token) {
      await api
        .post<ValidateResponseData>("validate", {
          token: data.token,
          version: appVersion,
        })
        .then((response) => {
          const { valid, app_version, android_version, ios_version } =
            response.data;

          if (isPlatform("ios")) {
            setAppUpdated(ios_version === appVersion);
          } else if (isPlatform("android")) {
            setAppUpdated(android_version === appVersion);
          } else {
            setAppUpdated(app_version === appVersion);
          }

          if (!valid) {
            signOut();
          }
        });
    } else {
      signOut();
    }
  }, [data.token, signOut]);

  const updateUserName = useCallback((name) => {
    setData((state) => {
      let newState = { ...state };

      newState.user = {
        ...state.user,
        name,
      };

      localStorage.setItem(
        `@${sessionName}:user`,
        userEncrypt({ ...state.user, name })
      );

      return newState;
    });
  }, []);

  const updateSignature = (signature: boolean) => {
    setData((state) => {
      let newState = { ...state };

      newState.user = {
        ...state.user,
        signature,
      };

      localStorage.setItem(
        `@${sessionName}:user`,
        userEncrypt({ ...state.user, signature })
      );

      return newState;
    });
  };

  const passwordChange = (reset: boolean) => {
    setData((state) => {
      let newState = { ...state };

      newState.user = {
        ...state.user,
        reset,
      };

      localStorage.setItem(
        `@${sessionName}:user`,
        userEncrypt({ ...state.user, reset })
      );

      return newState;
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        appUpdated,
        signIn,
        signOut,
        validateToken,
        updateUserName,
        updateSignature,
        passwordChange,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
