import React from "react";
import { IonCol, IonLabel, IonDatetime } from "@ionic/react";

interface Props {
  input: {
    name: string;
    value: string;
    onChange(e: string | null | undefined): void;
  };
  label: string;
  readonly?: boolean;
  size?: string;
}

const Input: React.FC<Props> = (props) => {
  return (
    <IonCol size={props.size || "12"}>
      <IonLabel position="stacked" style={{ color: "#000", fontWeight: 600 }}>
        {props.label}
      </IonLabel>
      <IonDatetime
        name={props.input.name}
        value={props.input.value}
        onIonChange={(e) => props.input.onChange(e.detail.value)}
        cancelText="Cancelar"
        doneText="Ok"
        displayFormat="HH:mm"
        readonly={props.readonly ? true : false}
        style={{
          marginTop: "5px",
          height: "42px",
          width: "100%",
          padding: "6px 12px",
          color: "#555555",
          backgroundColor: "#FFFFFF",
          backgroundImage: "none",
          borderColor: "hsl(0, 0%, 80%)",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "1px",
          lineHeight: 2,
        }}
      />
    </IonCol>
  );
};

export default Input;
