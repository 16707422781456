import React, { useEffect, useRef } from "react";
import { IonCol, IonSelect } from "@ionic/react";
import { useField } from "@unform/core";

import FormLabel from "./FormLabel";

interface Props {
  size?: string;
  style?: {
    [key: string]: any;
  };
  label: string;
  name: string;
  readOnly?: boolean;
}

const Input: React.FC<Props> = ({
  size,
  style,
  label,
  name,
  readOnly,
  children,
  ...rest
}) => {
  const selectRef = useRef<HTMLIonSelectElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref, value) => {
        if (value) {
          ref.current.value = value;
        } else {
          ref.current.value = "";
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <IonCol size={size || "12"} style={style}>
      <FormLabel>{label}</FormLabel>
      <IonSelect
        mode="md"
        ref={selectRef}
        defaultValue={defaultValue}
        name={name}
        interface="action-sheet"
        okText="Ok"
        cancelText="Cancelar"
        disabled={readOnly}
        style={{
          marginTop: "5px",
          height: "42px",
          padding: "6px 12px",
          color: "#000",
          backgroundColor: "#FFF",
          backgroundImage: "none",
          borderColor: "hsl(0, 0%, 80%)",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "1px",
        }}
        {...rest}
      >
        {children}
      </IonSelect>
    </IonCol>
  );
};

export default Input;
