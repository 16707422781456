interface Props {
  value?: string;
  upper?: Boolean;
}

export default function (props: Props) {
  if (props.value === "IN") {
    if (props.upper === true) {
      return "INFECÇÃO";
    } else {
      return "Infecção";
    }
  } else if (props.value === "OB") {
    if (props.upper === true) {
      return "OBSTRUÇÃO";
    } else {
      return "Obstrução";
    }
  } else if (props.value === "SA") {
    if (props.upper === true) {
      return "SANGRAMENTO";
    } else {
      return "Sangramento";
    }
  } else if (props.value === "BA") {
    if (props.upper === true) {
      return "BAIXO FLUXO";
    } else {
      return "Baixo Fluxo";
    }
  } else if (props.value === "RO") {
    if (props.upper === true) {
      return "ROTINA HOSPITALAR";
    } else {
      return "Rotina Hospitalar";
    }
  } else if (props.value === "OU") {
    if (props.upper === true) {
      return "OUTROS";
    } else {
      return "Outros";
    }
  } else {
    return "";
  }
}
