import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSelectOption,
} from "@ionic/react";

import Main from "../../components/Main";
import HospitalSearch from "../../components/Legacy/Search/Hospital";
import PatientSearch from "../../components/Legacy/Search/Patient";
import DateInput from "../../components/Legacy/Form/DateInput";
import Select from "../../components/Legacy/Form/Select";
import { create } from "../../store/doctor/catheter/actions";
import { Catheter as CatheterType } from "../../store/doctor/catheter/types";

interface DispatchProps {
  create(values: CatheterType): void;
}

const Catheter: React.FC<
  DispatchProps & InjectedFormProps<CatheterType, DispatchProps>
> = ({ handleSubmit, create }) => {
  return (
    <Main title="Cateter (Avulso)">
      <IonCard>
        <IonCardContent>
          <form onSubmit={handleSubmit(create)}>
            <IonGrid>
              <IonRow>
                <Field
                  name="date"
                  component={DateInput}
                  label="Data"
                  required
                />
                <Field name="hospital" component={HospitalSearch} required />
                <Field
                  name="proc"
                  component={Select}
                  label="Procedimento"
                  required
                >
                  <IonSelectOption value="Normal">
                    Cateter Normal
                  </IonSelectOption>
                  <IonSelectOption value="Thenkoff">
                    Cateter Thenkoff
                  </IonSelectOption>
                </Field>
                <Field name="patient" component={PatientSearch} required />
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="6">
                  <IonButton type="submit" color="primary" expand="block">
                    Salvar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
    </Main>
  );
};

const CatheterForm = reduxForm<CatheterType, DispatchProps>({
  form: "catheter",
})(Catheter);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ create }, dispatch);

export default connect(null, mapDispatchToProps)(CatheterForm);
