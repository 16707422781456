import Yup from "../../services/Legacy/yup";

const machine_orders_schema = Yup.object().shape({
  fault: Yup.string().required().label("Defeito Apresentado"),
  how_tried: Yup.string()
    .nullable()
    .when("tried", {
      is: (tried: any) => tried === "S",
      then: Yup.string().required().label("Tentou Solucionar de que Forma?"),
    }),
  tried: Yup.string().required().label("Tentou Solucionar?"),
  urgency: Yup.number().required().label("Urgência"),
  type: Yup.string().required().label("Tipo"),
  machine: Yup.mixed().required().label("Máquina"),
});

export default machine_orders_schema;
