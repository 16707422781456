import {
  MachinesActionsTypes,
  GET_DRIVER_MACHINES,
  CLEAR_DRIVER_MACHINES,
} from "./types";
import { ThunkDispatch } from "redux-thunk";
import { initialize } from "redux-form";
import { showToast } from "../../../store/toast/actions";
import ErrorHandler from "../../../helpers/Legacy/errorsHandler";
import api from "../../../services/api";

import { history } from "../../../App";

const initialValues = {
  water_hose: "N",
  sewer_hose: "N",
  serum_support: "N",
  power_extension: "N",
  pump_crank: "N",
  bag_holder: "N",
  capillary_support: "N",
  clamp: "N",
  faucet_hose: "N",
  machine_hose: "N",
  drain_hose: "N",
  quick_connector: "N",
  disinfection_control: "N",
  clean_equipment: "N",
  external_damages: "N",
};

export function getList() {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .get("driver/machines")
      .then((response) => {
        dispatch({ type: GET_DRIVER_MACHINES, payload: response.data });
      })
      .catch((error) => {
        // dispatch(ErrorHandler(error));
      });
  };
}

export function clearList(): MachinesActionsTypes {
  return {
    type: CLEAR_DRIVER_MACHINES,
    payload: undefined,
  };
}

export function show(values: any) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(
      initialize("driver_machine_checklist", { ...initialValues, ...values })
    );
  };
}

export function create(values: any) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await api
      .post("driver/machines", values)
      .then((response) => {
        dispatch(showToast("Dados salvos com sucesso", "success"));
        dispatch(init());
        history.push("/driver/machines");
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

function init() {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(initialize("driver_machine_checklist", {}));
  };
}
