import React from "react";
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from "react-router-dom";

import { useAuth } from "../hooks/auth";

interface RouteProps extends ReactDOMRouteProps {
  isDoctor?: boolean;
  isTechnician?: boolean;
  isNurse?: boolean;
  isAdministrative?: boolean;
  isDriver?: boolean;
  component: any;
}

const Route: React.FC<RouteProps> = ({
  isDoctor = false,
  isTechnician = false,
  isNurse = false,
  isAdministrative = false,
  isDriver = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return !!user &&
          ((!!isDoctor &&
            isDoctor === (user?.profile === "doctor" ? true : false)) ||
            (!!isTechnician &&
              isTechnician ===
                (user?.profile === "technician" ? true : false)) ||
            (!!isNurse &&
              isNurse === (user?.profile === "nurse" ? true : false)) ||
            (!!isAdministrative &&
              isAdministrative ===
                (user?.profile === "administrative" ? true : false)) ||
            (!!isDriver &&
              isDriver === (user?.profile === "driver" ? true : false))) ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname:
                user?.profile === "doctor"
                  ? "/doctor/prescription"
                  : user?.profile === "technician"
                  ? "/technician/assists_in_progress"
                  : user?.profile === "nurse"
                  ? "/nurse/assistances"
                  : user?.profile === "administrative"
                  ? "/administrative/assistances"
                  : user?.profile === "driver"
                  ? "/driver/machines"
                  : "/",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
