import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { useLocation } from "react-router-dom";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/react";

import { RootState } from "../../../store";
import {
  getList,
  clearList,
} from "../../../store/technician/assistance_take_on/actions";
import {
  AssistanceTakeOn as AssistancesTakeOnType,
  TechnicianTakeOnForm as TechnicianTakeOnFormType,
} from "../../../store/technician/assistance_take_on/types";

import Main from "../../../components/Main";
import HospitalSearch from "../../../components/Legacy/Search/Hospital";

import AssistancesTakeOnCard from "./card";

interface StateProps {
  assistances: AssistancesTakeOnType[];
}

interface DispatchProps {
  getList(values: TechnicianTakeOnFormType): void;
  clearList(): void;
}

type Props = StateProps & DispatchProps;

const AssistancesTakeOn: React.FC<
  Props & InjectedFormProps<TechnicianTakeOnFormType, Props>
> = ({ assistances, handleSubmit, getList, clearList }) => {
  const location = useLocation();

  useEffect(() => {
    clearList();
  }, [location, clearList]);

  return (
    <Main title="Assumir Atendimentos">
      <IonCard>
        <IonCardContent>
          <form onSubmit={handleSubmit(getList)}>
            <IonGrid>
              <IonRow>
                <Field name="hospital" component={HospitalSearch} required />
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="6">
                  <IonButton type="submit" color="primary" expand="block">
                    Consultar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
      {assistances.map((assistance) => {
        return (
          <AssistancesTakeOnCard key={assistance.id} assistance={assistance} />
        );
      })}
    </Main>
  );
};

const sessionKey = `${process.env.REACT_APP_SESSION_NAME}`;

const AssistancesTakeOnForm = reduxForm<TechnicianTakeOnFormType, Props>({
  form: "technician_assistances_take_on",
  initialValues: JSON.parse(
    `${localStorage.getItem(`${sessionKey}_technician_assistances_take_on`)}`
  ),
})(AssistancesTakeOn);

const mapStateToProps = (state: RootState) => ({
  assistances: state.technicianTakeOn.assistances,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getList, clearList }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssistancesTakeOnForm);
