import {
  TechnicianAssistanceForm,
  TechnicianAssistanceActionsTypes,
  GET_TECHNICIAN_ASSISTANCES,
  CLEAR_TECHNICIAN_ASSISTANCES,
} from "./types";
import { ThunkDispatch } from "redux-thunk";
import { ToastActionsTypes } from "../../../store/toast/types";
import ErrorHandler from "../../../helpers/Legacy/errorsHandler";
import api from "../../../services/api";

const sessionKey = `${process.env.REACT_APP_SESSION_NAME}`;
export function getList(values: TechnicianAssistanceForm) {
  localStorage.setItem(
    `${sessionKey}_technician_assistances`,
    JSON.stringify(values)
  );
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      TechnicianAssistanceActionsTypes | ToastActionsTypes
    >
  ) => {
    await api
      .get<any>("technician/assistances", {
        params: {
          date: values.date,
        },
      })
      .then((response) => {
        dispatch({ type: GET_TECHNICIAN_ASSISTANCES, payload: response.data });
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function clearList(): TechnicianAssistanceActionsTypes {
  return {
    type: CLEAR_TECHNICIAN_ASSISTANCES,
    payload: undefined,
  };
}
