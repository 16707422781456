interface Props {
  value?: string;
  upper?: Boolean;
}

export default function MachineTypeConverter(props: Props) {
  if (props.value === "H") {
    if (props.upper === true) {
      return "HEMODIÁLISE";
    } else {
      return "Hemodiálise";
    }
  } else if (props.value === "T") {
    if (props.upper === true) {
      return "OSMOSE";
    } else {
      return "Osmose";
    }
  } else if (props.value === "C") {
    if (props.upper === true) {
      return "HOME CHOICE";
    } else {
      return "Home Choice";
    }
  }
}
