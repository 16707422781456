import React, { useEffect } from "react";
import { IonApp, IonSplitPane, isPlatform } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { createBrowserHistory } from "history";

import { useAuth } from "./hooks/auth";

import Menu from "./components/Menu";
import Routes from "./routes";
import Login from "./pages/Login";
import Password from "./pages/Password";
import Signature from "./pages/Signature";

import Toast from "./components/Legacy/Toast";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

export const history = createBrowserHistory({});

const App: React.FC = () => {
  const { user, validateToken } = useAuth();
  const platform = isPlatform("mobileweb") || isPlatform("desktop");

  useEffect(() => {
    validateToken();
  }, [validateToken]);

  if (!!user) {
    if (!!user.reset) {
      return <Password />;
    } else if (
      (user.profile === "doctor" || user.profile === "technician") &&
      !user.signature &&
      !platform
    ) {
      return <Signature />;
    } else {
      return (
        <IonApp>
          <IonReactRouter history={history}>
            <IonSplitPane contentId="main">
              <Menu />
              <Routes />
            </IonSplitPane>
          </IonReactRouter>
          <Toast />
        </IonApp>
      );
    }
  } else {
    return <Login />;
  }
};

export default App;
