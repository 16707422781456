import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { update } from '../../../store/nurse/assistance/actions';

import Card from './card';

let List = function (props) {
  const { list = [] } = props;

  function renderRows() {
    return list.map(d => (
      <Card d={d} key={d.id} update={update} />
    ));
  }

  return (
    <>
      {list.length > 0 ?
        renderRows()
        :
        <></>
      }
    </>
  );
}

const mapStateToProps = state => ({ list: state.nurseScheduled.list })
const mapDispatchToProps = dispatch => bindActionCreators({ update }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(List)
