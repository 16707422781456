import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  formValueSelector,
} from "redux-form";
import { Link, useLocation } from "react-router-dom";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonText,
  IonSelectOption,
  IonItemDivider,
  IonAlert,
  IonSelect,
} from "@ionic/react";
import { personAdd } from "ionicons/icons";
import { format, addDays } from "date-fns";
import prescription_schema from "../../schemas/Legacy/prescription_schema";

import { RootState } from "../../store";
import {
  getList,
  show,
  getPatient,
  createOrUpdate,
  init,
  setPage,
} from "../../store/doctor/prescription/actions";
import {
  Prescription as PrescriptionType,
  Prescriptions as PrescriptionsType,
} from "../../store/doctor/prescription/types";
import { showToast } from "../../store/toast/actions";
import { Toast as ToastType } from "../../store/toast/types";

import { useToast } from "../../hooks/toast";
import { useValidationErrors } from "../../hooks/errors";
import api from "../../services/api";
import patientsControlSchema from "../../schemas/Legacy/patients_control_schema";

import Main from "../../components/Main";
import HospitalSearch from "../../components/Legacy/Search/Hospital";
import PatientSearch from "../../components/Legacy/Search/Patient";
import DateInput from "../../components/Legacy/Form/DateInput";
import Select from "../../components/Legacy/Form/Select";
import Input from "../../components/Legacy/Form/Input";
import Textarea from "../../components/Legacy/Form/Textarea";
import If from "../../helpers/Legacy/if";
import dateToString from "../../helpers/Legacy/dateToString";
import { getLocations, getProcedures, getType } from "../../shared/Procedures";

import patientsControl from "../../shared/PatientsControl";
import { kits, capillaries, lines } from "../../shared/ContinuousProc";

interface StateProps {
  prescriptions: PrescriptionType[];
  page: PrescriptionsType["page"];
  prescriptionForm: PrescriptionType;
}

interface DispatchProps {
  getList(): void;
  show(id: number): void;
  getPatient(id: number): void;
  showToast(message: ToastType["message"], color?: ToastType["color"]): void;
  createOrUpdate(values: PrescriptionType): void;
  init(): void;
  setPage(page: PrescriptionsType["page"]): void;
}

type Props = StateProps & DispatchProps;

const Prescription: React.FC<
  Props & InjectedFormProps<PrescriptionType, Props>
> = ({
  handleSubmit,
  getList,
  show,
  getPatient,
  showToast,
  createOrUpdate,
  init,
  page,
  setPage,
  prescriptions,
  prescriptionForm,
  change,
}) => {
  const location = useLocation();
  const { successToast } = useToast();
  const { getValidationErrors } = useValidationErrors();
  const [access, setAccess] = useState(false);
  const [time, setTime] = useState(false);
  const [sessions, setSessions] = useState(false);
  const [onePage, setOnePage] = useState(false);
  const [formValues, setFormValues] = useState<PrescriptionType | null>(null);
  const [showAlert1, setShowAlert1] = useState(false);

  const [patientControl, setPatientControl] = useState<string>("None");

  useEffect(() => {
    getList();
  }, [location, getList]);

  useEffect(() => {
    if (prescriptionForm.proc) {
      setAccess(false);
      setTime(false);
      setSessions(
        parseInt(prescriptionForm.sessions?.toString()) === 0 ||
          (parseInt(prescriptionForm.sessions?.toString()) + 1) % 5 === 0
      );
      if (getType(prescriptionForm.proc) === "DP") {
        change("access", "TENCKHOFF");
        setAccess(true);
      } else if (getType(prescriptionForm.proc) === "CONT") {
        setSessions(true);
      }

      if (
        prescriptionForm.proc === "PARECER" ||
        prescriptionForm.proc === "VISITA HOSPITALAR"
      ) {
        setPage(1);
        setOnePage(true);
      } else {
        setOnePage(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriptionForm.proc, prescriptionForm.sessions]);

  useEffect(() => {
    if (formValues !== null) {
      setShowAlert1(true);
    }
  }, [formValues]);

  function handleCreate(values: PrescriptionType | null) {
    if (values !== null) {
      createOrUpdate(values);
    }
    setFormValues(null);
  }

  async function handleFormValidation(values: PrescriptionType) {
    if (patientControl !== "None") {
      const patient_id = values.patient as { value: number; label: string };
      const hospital_id = values.hospital as { value: number; label: string };

      const formData = {
        patient_id: patient_id.value,
        date: values.date,
        control: patientControl,
        hospital_id: hospital_id.value,
        location: values.location,
        bed: values.bed,
      };

      patientsControlSchema
        .validate(formData)
        .then(async () => {
          await api
            .post("doctor/patients_control", formData)
            .then((_response) => {
              successToast();
              init();
              setPatientControl("None");
            })
            .catch((error) => {
              getValidationErrors(error.response);
            });
        })
        .catch((error) => {
          getValidationErrors(error);
        });
    } else if (values.id) {
      handleCreate(values);
    } else {
      await api
        .post("doctor/prescription/validation", {
          patient: values.patient,
          date: values.date,
        })
        .then((resp) => {
          if (resp.data === true) {
            setFormValues(values);
          } else {
            handleCreate(values);
          }
        });
    }
  }

  function handleNextPage() {
    prescription_schema
      .validate(prescriptionForm)
      .then(() => {
        setPage(2);
      })
      .catch((err) => {
        showToast(`${err.errors}`, "warning");
      });
  }

  const PageButton = () => {
    if (patientControl === "None") {
      return (
        <IonCol size="6">
          {!onePage && (
            <>
              {page === 1 ? (
                <IonButton
                  type="button"
                  color="dark"
                  expand="block"
                  onClick={() => handleNextPage()}
                >
                  Próximo
                </IonButton>
              ) : (
                <IonButton
                  type="button"
                  color="dark"
                  expand="block"
                  onClick={() => setPage(1)}
                >
                  Anterior
                </IonButton>
              )}
            </>
          )}
        </IonCol>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Main
      title="Prescrição"
      scroll={(prescriptionForm.id ? prescriptionForm.id : 0) + page}
    >
      <IonCard>
        <IonCardHeader>
          <IonRow>
            <PageButton />
            <IonCol size="6">
              <Link to="/doctor/patient" style={{ textDecoration: "none" }}>
                <IonButton type="button" color="warning" expand="block">
                  <IonIcon slot="start" icon={personAdd} />
                  Paciente
                </IonButton>
              </Link>
            </IonCol>
          </IonRow>
        </IonCardHeader>
        <IonCardContent>
          <form onSubmit={handleSubmit(handleFormValidation)}>
            <IonGrid className="ion-align-items-center">
              <IonRow>
                <Field
                  name="patient"
                  onChange={(patient: any) => getPatient(patient?.value)}
                  component={PatientSearch}
                />
                <Field
                  name="date"
                  component={DateInput}
                  label="Data"
                  max={format(addDays(new Date(), 5), "yyyy-MM-dd")}
                  min={format(new Date(), "yyyy-MM-dd")}
                />
              </IonRow>
              {page === 1 ? (
                <>
                  <IonRow>
                    <IonCol size="12">
                      <IonLabel
                        position="stacked"
                        style={{ color: "#000", fontWeight: 600 }}
                      >
                        Controle de Paciente
                      </IonLabel>
                      <IonSelect
                        value={patientControl}
                        onIonChange={(e) =>
                          setPatientControl(e.detail.value || "")
                        }
                        cancelText="Cancelar"
                        okText="Ok"
                        interface="action-sheet"
                        style={{
                          marginTop: "5px",
                          height: "42px",
                          width: "100%",
                          padding: "6px 12px",
                          color: "#555555",
                          backgroundColor: "#FFFFFF",
                          backgroundImage: "none",
                          borderColor: "hsl(0, 0%, 80%)",
                          borderRadius: "4px",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          lineHeight: 2,
                        }}
                      >
                        <IonSelectOption value="None">Nenhum</IonSelectOption>
                        {patientsControl.map((patientControl) => (
                          <IonSelectOption
                            key={patientControl.value}
                            value={patientControl.value}
                          >
                            {patientControl.label}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonCol>

                    {patientControl === "None" && (
                      <Field
                        size="6"
                        name="new_patient"
                        component={Select}
                        label="Paciente Novo?"
                      >
                        <IonSelectOption value="N">Não</IonSelectOption>
                        <IonSelectOption value="S">Sim</IonSelectOption>
                      </Field>
                    )}
                    <Field name="hospital" component={HospitalSearch} />
                    <Field
                      name="location"
                      component={Select}
                      label="Localização"
                    >
                      {getLocations()}
                    </Field>
                    <Field
                      name="bed"
                      component={Input}
                      label="Leito"
                      type="text"
                    />
                    {patientControl === "None" && (
                      <>
                        <Field
                          size="7"
                          name="proc"
                          component={Select}
                          label="Procedimento"
                        >
                          {getProcedures()}
                        </Field>
                        <Field
                          size="5"
                          name="time"
                          component={Input}
                          label="Tempo (horas)"
                          readOnly={time}
                          type="number"
                        />
                        <Field
                          name="access"
                          component={Select}
                          label="Acesso"
                          readOnly={access}
                        >
                          <IonSelectOption value="CDL">CDL</IonSelectOption>
                          <IonSelectOption value="FAV">FAV</IonSelectOption>
                          <If test={getType(prescriptionForm.proc) === "DP"}>
                            <IonSelectOption value="TENCKHOFF">
                              TENCKHOFF
                            </IonSelectOption>
                          </If>
                          <IonSelectOption value="PERMCATH">
                            PERMCATH
                          </IonSelectOption>
                          <IonSelectOption value="CDL-T (troca)">
                            CDL-T troca
                          </IonSelectOption>
                          <IonSelectOption value="CTL">CTL</IonSelectOption>
                        </Field>
                        <If test={prescriptionForm.access === "FAV"}>
                          <Field name="site" component={Select} label="Sitio">
                            <IonSelectOption value="MSD (membro superior direito)">
                              MSD (membro superior direito)
                            </IonSelectOption>
                            <IonSelectOption value="MSE (membro superior esquerdo)">
                              MSE (membro superior esquerdo)
                            </IonSelectOption>
                            <IonSelectOption value="MID (membro inferior direito)">
                              MID (membro inferior direito)
                            </IonSelectOption>
                            <IonSelectOption value="MIE (membro inferior esquerdo)">
                              MIE (membro inferior esquerdo)
                            </IonSelectOption>
                          </Field>
                        </If>
                        <If
                          test={
                            (prescriptionForm.access ? true : false) &&
                            prescriptionForm.access !== "FAV" &&
                            prescriptionForm.access !== "TENCKHOFF"
                          }
                        >
                          <Field name="site" component={Select} label="Sitio">
                            <IonSelectOption value="VJID (veia jugular interna direita)">
                              VJID (veia jugular interna direita)
                            </IonSelectOption>
                            <IonSelectOption value="VJIE (veia jugular interna esquerda)">
                              VJIE (veia jugular interna esquerda)
                            </IonSelectOption>
                            <IonSelectOption value="VSCD (veia subclavia central direita)">
                              VSCD (veia subclavia central direita)
                            </IonSelectOption>
                            <IonSelectOption value="VSCE (veia subclavia central esquerda)">
                              VSCE (veia subclavia central esquerda)
                            </IonSelectOption>
                            <IonSelectOption value="VFD (veia femural direita)">
                              VFD (veia femural direita)
                            </IonSelectOption>
                            <IonSelectOption value="VFE (veia femural esquerda)">
                              VFE (veia femural esquerda)
                            </IonSelectOption>
                          </Field>
                        </If>
                        <Field
                          size="6"
                          name="catheter"
                          component={Select}
                          label="Implantou Cateter?"
                        >
                          <IonSelectOption value="N">Não</IonSelectOption>
                          <IonSelectOption value="S">Sim</IonSelectOption>
                        </Field>
                        <If test={prescriptionForm.catheter === "S"}>
                          <Field
                            size="6"
                            name="catheter_type"
                            component={Select}
                            label="Cateter Tipo"
                          >
                            <IonSelectOption value="N">Novo</IonSelectOption>
                            <IonSelectOption value="T">Troca</IonSelectOption>
                          </Field>
                          <If test={getType(prescriptionForm.proc) === "DP"}>
                            <Field
                              size="6"
                              name="thenkoff"
                              component={Select}
                              label="Thenkoff"
                            >
                              <IonSelectOption value="N">
                                Normal
                              </IonSelectOption>
                              <IonSelectOption value="S">
                                Thenkoff
                              </IonSelectOption>
                            </Field>
                          </If>
                          <If test={prescriptionForm.catheter_type === "T"}>
                            <Field
                              size="6"
                              name="catheter_reason"
                              component={Select}
                              label="Motivo da Troca"
                            >
                              <IonSelectOption value="IN">
                                Infecção
                              </IonSelectOption>
                              <IonSelectOption value="OB">
                                Obstrução
                              </IonSelectOption>
                              <IonSelectOption value="SA">
                                Sangramento
                              </IonSelectOption>
                              <IonSelectOption value="BA">
                                Baixo Fluxo
                              </IonSelectOption>
                              <IonSelectOption value="RO">
                                Rotina Hospitalar
                              </IonSelectOption>
                              <IonSelectOption value="OU">
                                Outros
                              </IonSelectOption>
                            </Field>
                          </If>
                        </If>
                        <Field
                          size="6"
                          name="precaution"
                          component={Select}
                          label="Precaução"
                        >
                          <IonSelectOption value={0}>Não</IonSelectOption>
                          <IonSelectOption value={1}>Sim</IonSelectOption>
                        </Field>
                        <Field
                          name="index"
                          component={Select}
                          label="Índice de Captação"
                        >
                          <IonSelectOption value="I">
                            Improvável para Diálise Crônica
                          </IonSelectOption>
                          <IonSelectOption value="P">
                            Provável para Diálise Crônica
                          </IonSelectOption>
                          <IonSelectOption value="M">
                            Já em Programa de Diálise Crônica
                          </IonSelectOption>
                          <IonSelectOption value="PP">
                            Provável para Consultório
                          </IonSelectOption>
                        </Field>
                        <Field
                          name="catheter_time"
                          component={Input}
                          label="Tempo de Permanência de Cateter"
                          type="number"
                          readOnly
                        />
                        <Field
                          name="sessions"
                          component={Input}
                          label="Nº de sessões de diálise"
                          type="number"
                          readOnly
                        />
                        <If test={sessions}>
                          <Field
                            name="medical_report"
                            component={Textarea}
                            label="Relatório Médico"
                            autoGrow
                          />
                        </If>
                      </>
                    )}
                  </IonRow>
                  {patientControl !== "None" && (
                    <IonRow className="ion-margin-top">
                      <IonCol size="6">
                        <IonButton type="submit" color="primary" expand="block">
                          Salvar
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  )}
                </>
              ) : (
                <IonRow className="ion-align-items-end">
                  <If test={getType(prescriptionForm.proc) === "DEFAULT"}>
                    <Field
                      size="6"
                      name="fluxo_sangue"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Fluxo de Sangue (ml/min)"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="fluxo_dialisato"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Fluxo Dialisato (ml/min)"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="ultrafiltracao_hora"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Ultrafiltração (ml/hora)"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="ultrafiltracao_min"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="UF Total"
                      type="number"
                    />
                    <IonItemDivider className="ion-margin-top" color="light">
                      <IonLabel>Anticoagulação</IonLabel>
                    </IonItemDivider>
                    <Field
                      size="6"
                      name="heparina"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Heparina (unidade)"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="soro_fisiológico"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Soro Fisiológico (ml/min)"
                      type="number"
                    />
                    <Field
                      name="selante_cateter"
                      component={Select}
                      label="Tipo Selante do Cateter"
                    >
                      <IonSelectOption value="Citrato 34% (Citralock)">
                        Citrato 34% (Citralock)
                      </IonSelectOption>
                      <IonSelectOption value="Citrato 4% + Heparina (Taurolock Epi 500)">
                        Citrato 4% + Heparina (Taurolock Epi 500)
                      </IonSelectOption>
                      <IonSelectOption value="EDTA + MINOCICLINA 9MG (CATHSAFE)">
                        EDTA + MINOCICLINA 9MG (CATHSAFE)
                      </IonSelectOption>
                      <IonSelectOption value="Citrato 4% (Taurolock TM)">
                        Citrato 4% (Taurolock TM)
                      </IonSelectOption>
                      <IonSelectOption value="Conector Valvulado (TEGO)">
                        Conector Valvulado (TEGO)
                      </IonSelectOption>
                      <IonSelectOption value="SOLUÇÃO HEPARINA">
                        SOLUÇÃO HEPARINA
                      </IonSelectOption>
                    </Field>
                    <Field
                      size="6"
                      name="kcl"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="KCL"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="fosfato_de_sodio"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Fosfato de Sódio (SACHE)"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="bicarbonato_prescricao"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Bicarbonato"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="sodio_prescricao"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Sódio"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="temperatura"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Temperatura"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="ultrafiltracao_min"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="UF"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="kdigo"
                      component={Select}
                      label="KDIGO"
                    >
                      <IonSelectOption value={1}>1</IonSelectOption>
                      <IonSelectOption value={2}>2</IonSelectOption>
                      <IonSelectOption value={3}>3</IonSelectOption>
                    </Field>
                  </If>
                  <If test={getType(prescriptionForm.proc) === "DP"}>
                    <Field
                      size="6"
                      name="uf_total"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="UF Total (ml/min)"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="volume_total"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Volume Total (ml)"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="volume_infusao"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Volume de Infusão (ml)"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="volume_infusao_ultima"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Volume da Última Infusão (ml)"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="tempo_permanencia"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Tempo de Permanência"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="heparina"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Heparina (unidade)"
                      type="number"
                    />
                    <Field
                      name="concetracao_bolsas"
                      component={Select}
                      label="Concentração das Bolsas"
                    >
                      <IonSelectOption value="1.5%">1.5%</IonSelectOption>
                      <IonSelectOption value="2,5%"> 2,5%</IonSelectOption>
                      <IonSelectOption value="4,25%">4,25%</IonSelectOption>
                    </Field>
                  </If>
                  <If test={getType(prescriptionForm.proc) === "CONT"}>
                    <Field size="6" name="kit" component={Select} label="Kit">
                      {kits.map((kit) => (
                        <IonSelectOption key={kit.value} value={kit.value}>
                          {kit.label}
                        </IonSelectOption>
                      ))}
                    </Field>
                    <Field
                      size="6"
                      name="capillary_type"
                      component={Select}
                      label="Tipo de Capilar"
                    >
                      {capillaries.map((capillary) => (
                        <IonSelectOption
                          key={capillary.value}
                          value={capillary.value}
                        >
                          {capillary.label}
                        </IonSelectOption>
                      ))}
                    </Field>
                    <Field
                      size="6"
                      name="line_type"
                      component={Select}
                      label="Tipo de Linha"
                    >
                      {lines.map((line) => (
                        <IonSelectOption key={line.value} value={line.value}>
                          {line.label}
                        </IonSelectOption>
                      ))}
                    </Field>
                    <Field
                      size="6"
                      name="ultrafiltracao_min"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="UF"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="fluxo_sangue"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Fluxo de Sangue (ml/min)"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="fluxo_dialisato"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Fluxo Dialisato (ml/h)"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="fluxo_de_reposicao"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Fluxo de Reposição (ml/h)"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="peso"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Peso (Kg)"
                      type="number"
                    />
                    <Field
                      size="6"
                      name="temperatura"
                      component={Input}
                      max="999999.99"
                      step="0.01"
                      label="Temperatura"
                      type="number"
                    />
                    <Field
                      name="anticoagulation"
                      component={Select}
                      label="Anticoagulação"
                    >
                      <IonSelectOption value="Citrato">Citrato</IonSelectOption>
                      <IonSelectOption value="Heparina (utilizar duosol)">
                        Heparina (utilizar duosol)
                      </IonSelectOption>
                      <IonSelectOption value="Nenhuma (utilizar duosol)">
                        Nenhuma (utilizar duosol)
                      </IonSelectOption>
                    </Field>
                    <Field
                      size="6"
                      name="initial_citrate"
                      component={Input}
                      label="Dose Inicial Citrato"
                      type="text"
                    />
                    <Field
                      size="6"
                      name="initial_calcium"
                      component={Input}
                      label="Dose Inicial Cálcio"
                      type="text"
                    />
                    {/* <If test={prescriptionForm.mode === 'CVVHDF'}>
                      <Field
                        size="6"
                        name="dose_dialise_prescrita"
                        component={Input}
                        max="999999.99"
                        step="0.01"
                        label="Dose de Diálise Prescrita"
                        type="number"
                        readOnly
                      />
                      <Field
                        size="6"
                        name="dose_de_citrato"
                        component={Input}
                        max="999999.99"
                        step="0.01"
                        label="Dose de citrato (3mmol/L)"
                        type="number"
                      />
                    </If> */}
                  </If>
                  <Field
                    name="diagnostico"
                    component={Select}
                    label="Diagnóstico"
                  >
                    <IonSelectOption value="IRA">IRA</IonSelectOption>
                    <IonSelectOption value="DRC 5D">DRC 5D</IonSelectOption>
                    <IonSelectOption value="DRC (A)">DRC (A)</IonSelectOption>
                  </Field>
                  <IonCol size="12">
                    <IonRow className="ion-align-items-end">
                      <Field
                        size="6"
                        name="aminasvasoativas"
                        component={Select}
                        label="Uso de aminasvasoativas"
                      >
                        <IonSelectOption value={0}>Não</IonSelectOption>
                        <IonSelectOption value={1}>Sim</IonSelectOption>
                      </Field>
                      <Field
                        size="6"
                        name="drogas_vasoativas"
                        component={Input}
                        max="50.00"
                        step="0.01"
                        label="Quantidade (ml/hora)"
                        type="number"
                      />
                    </IonRow>
                  </IonCol>
                  <Field
                    name="quadro_clinico"
                    component={Select}
                    label="Quadro Clínico"
                  >
                    <IonSelectOption value="Coma">Coma</IonSelectOption>
                    <IonSelectOption value="Acordado">Acordado</IonSelectOption>
                  </Field>
                  <Field
                    size="6"
                    name="ventilacao_mecanica"
                    component={Select}
                    label="Ventilação Mecânica"
                  >
                    <IonSelectOption value={0}>Não</IonSelectOption>
                    <IonSelectOption value={1}>Sim</IonSelectOption>
                  </Field>
                  <Field
                    size="6"
                    name="hemodicamente"
                    component={Select}
                    label="Hemodicamente"
                  >
                    <IonSelectOption value="Instável">Instável</IonSelectOption>
                    <IonSelectOption value="Estável">Estável</IonSelectOption>
                  </Field>
                  <Field
                    size="6"
                    name="diurese"
                    max="999999.99"
                    step="0.01"
                    component={Input}
                    label="Diurese (ml/24)"
                    type="number"
                  />
                  <IonCol size="12">
                    <IonRow className="ion-align-items-end">
                      <Field
                        size="6"
                        name="bh_sinal"
                        component={Select}
                        label="BH (ml/24)"
                      >
                        <IonSelectOption value="P">Positivo</IonSelectOption>
                        <IonSelectOption value="N">Negativo</IonSelectOption>
                      </Field>
                      <Field
                        size="6"
                        name="bh"
                        max="999999.99"
                        step="0.01"
                        component={Input}
                        label=""
                        type="number"
                      />
                    </IonRow>
                  </IonCol>
                  <Field
                    size="6"
                    name="creatinina"
                    max="999999.99"
                    step="0.01"
                    component={Input}
                    label="Creatinina (mg/dl)"
                    type="number"
                  />
                  <Field
                    size="6"
                    name="ureia"
                    max="999999.99"
                    step="0.01"
                    component={Input}
                    label="Ureia (mg/dl)"
                    type="number"
                  />
                  <Field
                    size="6"
                    name="potassio"
                    max="999999.99"
                    step="0.01"
                    component={Input}
                    label="Potássio (mEq/l)"
                    type="number"
                  />
                  <Field
                    size="6"
                    name="fosforo"
                    max="999999.99"
                    step="0.01"
                    component={Input}
                    label="Fósforo (mg/dl)"
                    type="number"
                  />
                  <Field
                    size="6"
                    name="calcio"
                    max="999999.99"
                    step="0.01"
                    component={Input}
                    label="Cálcio (mg/dl)"
                    type="number"
                  />
                  <Field
                    size="6"
                    name="pcr"
                    max="999999.99"
                    step="0.01"
                    component={Input}
                    label="PCR (Unidade)"
                    type="number"
                  />
                  <Field
                    size="6"
                    name="sodio"
                    max="999999.99"
                    step="0.01"
                    component={Input}
                    label="Sódio (mEq/l)"
                    type="number"
                  />
                  <Field
                    size="6"
                    name="cloro"
                    max="999999.99"
                    step="0.01"
                    component={Input}
                    label="Cloro (mEq/l)"
                    type="number"
                  />
                  <Field
                    size="6"
                    name="ph"
                    max="999999.99"
                    step="0.01"
                    component={Input}
                    label="Ph"
                    type="number"
                  />
                  <Field
                    size="6"
                    name="bicarbonato"
                    max="999999.99"
                    step="0.01"
                    component={Input}
                    label="Bicarbonato (mEq/l)"
                    type="number"
                  />
                  <Field
                    size="6"
                    name="be"
                    max="999999.99"
                    step="0.01"
                    component={Input}
                    label="BE (Unidade)"
                    type="number"
                  />
                  <Field
                    size="6"
                    name="hemoglobobina"
                    max="999999.99"
                    step="0.01"
                    component={Input}
                    label="Hemoglobobina (G/dl)"
                    type="number"
                  />
                  <Field
                    size="6"
                    name="ht"
                    max="999999.99"
                    step="0.01"
                    component={Input}
                    label="HT (%)"
                    type="number"
                  />
                  <Field size="6" name="covid" component={Select} label="Covid">
                    <IonSelectOption value="N">Não</IonSelectOption>
                    <IonSelectOption value="Y">Sim</IonSelectOption>
                  </Field>
                </IonRow>
              )}
              <IonRow className="ion-margin-top">
                <PageButton />
                {(page === 2 || !!onePage) && (
                  <IonCol size="6">
                    <IonButton type="submit" color="primary" expand="block">
                      Salvar
                    </IonButton>
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
        <IonAlert
          isOpen={showAlert1}
          onDidDismiss={() => setShowAlert1(false)}
          message={
            "Já tem uma diálise para este paciente agendada, deseja continuar ?"
          }
          buttons={[
            {
              text: "Não",
              role: "cancel",
              handler: () => setFormValues(null),
            },
            {
              text: "Sim",
              handler: () => handleCreate(formValues),
            },
          ]}
        />
      </IonCard>
      {prescriptions.map((prescription) => {
        return (
          <IonCard
            key={prescription.id}
            className={`card-${prescription.situation?.toLowerCase()}`}
          >
            <IonCardHeader>
              <IonCardTitle>
                Data: {dateToString(prescription.date)}
              </IonCardTitle>
              <IonCardTitle>Situação: {prescription.situation}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonRow>
                <IonCol size="12">
                  <IonLabel>Paciente: </IonLabel>
                  <IonText>{prescription.patient}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Procedimento: </IonLabel>
                  <IonText>{prescription.proc}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Tempo: </IonLabel>
                  <IonText>{prescription.time}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Precaução: </IonLabel>
                  <IonText>{prescription.precaution ? "Sim" : "Não"}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Médico: </IonLabel>
                  <IonText>{prescription.hospital}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Localização: </IonLabel>
                  <IonText>{prescription.location}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Leito: </IonLabel>
                  <IonText>{prescription.bed}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Sitio: </IonLabel>
                  <IonText>{prescription.site}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonLabel>Acesso: </IonLabel>
                  <IonText>{prescription.access}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="6">
                  <IonButton
                    type="button"
                    color="primary"
                    expand="block"
                    onClick={() => show(prescription.id)}
                  >
                    Editar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
        );
      })}
    </Main>
  );
};

const PrescriptionForm = reduxForm<PrescriptionType, Props>({
  form: "prescription",
  initialValues: {
    new_patient: "N",
    access: undefined,
    catheter: "N",
    catheter_type: "N",
    thenkoff: "N",
    precaution: false,
    catheter_time: 0,
    sessions: 0,
  },
})(Prescription);

const selector = formValueSelector("prescription");

const mapStateToProps = (state: RootState) => ({
  prescriptions: state.doctorPrescription.prescriptions,
  page: state.doctorPrescription.page,
  prescriptionForm: selector(
    state,
    "id",
    "patient",
    "new_patient",
    "date",
    "hospital",
    "location",
    "bed",
    "proc",
    "time",
    "access",
    "site",
    "catheter",
    "catheter_type",
    "thenkoff",
    "catheter_reason",
    "precaution",
    "index",
    "sessions",
    "medical_report",
    "peso",
    "fluxo_dialisato",
    "fluxo_de_reposicao",
    "mode"
  ),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getList, show, getPatient, showToast, createOrUpdate, init, setPage },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionForm);
