import ReactDOM from "react-dom";
import { setupConfig } from "@ionic/react";

import AppProvider from "./hooks";
import App from "./App";

setupConfig({
  mode: "md",
});

ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById("root")
);
