interface Destination {
  value: number | string;
  label: string;
}

const destinations: Destination[] = [
  { value: "Em tratamento", label: "Em tratamento" },
  { value: "Alta Nefro", label: "Alta Nefro" },
  { value: "Alta Hospitalar", label: "Alta Hospitalar" },
  {
    value: "Alta encaminhado para o programa",
    label: "Alta encaminhado para o programa",
  },
  { value: "Transferência", label: "Transferência" },
  { value: "Óbito", label: "Óbito" },
];

export default destinations;
