import React from "react";
import { IonLabel } from "@ionic/react";

const Label: React.FC = ({ children }) => {
  return (
    <IonLabel
      style={{
        color: "#000",
        fontWeight: 600,
        fontSize: "16px",
        overflow: "visible",
      }}
    >
      {children}
    </IonLabel>
  );
};

export default Label;
