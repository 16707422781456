import api from "../../../services/api";
import { showToast } from "../../toast/actions";
import ErrorHandler from "../../../helpers/Legacy/errorsHandler";

export function getList(hospital, my_hospitals, type, from, to) {
  return async (dispatch) => {
    await api
      .get("assistance_nursing", {
        params: { hospital: hospital.value, my_hospitals, type, from, to },
      })
      .then((response) => {
        dispatch({
          type: "ASSISTANCE_NURSING_FETCHING",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(ErrorHandler(error));
      });
  };
}

export function update(id, values) {
  if (typeof values.technician !== "undefined")
    values.technician = values.technician.value;
  return (dispatch) => {
    api
      .put(`assistance_nursing/${id}`, values)
      .then((resp) => {})
      .catch((error) => {
        dispatch(showToast("Não foi possível conectar ao servidor", "danger"));
      });
  };
}

export function clearList() {
  return (dispatch) => {
    dispatch({ type: "ASSISTANCE_NURSING_CLEAR", payload: false });
  };
}
