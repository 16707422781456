export interface Toast {
  isOpen: boolean;
  message: string;
  color?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "success"
    | "warning"
    | "danger"
    | "light"
    | "medium"
    | "dark";
  header?: string;
}

export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

interface ShowToastActions {
  type: typeof SHOW_TOAST;
  payload: Toast;
}

interface HideToastActions {
  type: typeof HIDE_TOAST;
  payload: undefined;
}

export type ToastActionsTypes = ShowToastActions | HideToastActions;
