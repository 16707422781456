import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  formValueSelector,
} from "redux-form";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonText,
  IonSelectOption,
} from "@ionic/react";

import { RootState } from "../store";
import { MachineOrder as MachineOrderType } from "../store/machine_order/types";
import { getList, getMachine, create } from "../store/machine_order/actions";

import Main from "../components/Main";
import If from "../helpers/Legacy/if";
import dateToString from "../helpers/Legacy/dateToString";
import machineStatusConverter from "../helpers/Legacy/machineStatusConverter";
import machineTypeConverter from "../helpers/Legacy/machineTypeConverter";
import MachineSearchById from "../components/Legacy/Search/MachineById";
import Select from "../components/Legacy/Form/Select";
import Textarea from "../components/Legacy/Form/Textarea";

interface StateProps {
  list: MachineOrderType[];
  tried?: string;
}

interface DispatchProps {
  getList(): void;
  getMachine(id: number): void;
  create(values: MachineOrderType): void;
}

type Props = StateProps & DispatchProps;

const MachineSO: React.FC<Props & InjectedFormProps<MachineOrderType, Props>> =
  ({ list, tried, getList, getMachine, create, handleSubmit }) => {
    useEffect(() => {
      getList();
    }, [getList]);

    return (
      <Main title="Abrir OS">
        <IonCard>
          <IonCardContent>
            <form onSubmit={handleSubmit(create)}>
              <IonGrid>
                <IonRow>
                  <Field
                    name="machine"
                    onChange={(machine: any) => getMachine(machine?.value)}
                    component={MachineSearchById}
                  />
                  <Field name="type" component={Select} label="Tipo" readOnly>
                    <IonSelectOption value="H">Hemodiálise</IonSelectOption>
                    <IonSelectOption value="T">Osmose</IonSelectOption>
                    <IonSelectOption value="C">Home Choice</IonSelectOption>
                  </Field>
                  <Field
                    size="6"
                    name="urgency"
                    component={Select}
                    label="Urgência"
                  >
                    <IonSelectOption value="0">0</IonSelectOption>
                    <IonSelectOption value="1">1</IonSelectOption>
                    <IonSelectOption value="2">2</IonSelectOption>
                    <IonSelectOption value="3">3</IonSelectOption>
                    <IonSelectOption value="4">4</IonSelectOption>
                  </Field>
                  <Field
                    size="6"
                    name="tried"
                    component={Select}
                    label="Tentou Solucionar?"
                  >
                    <IonSelectOption value="N">Não</IonSelectOption>
                    <IonSelectOption value="S">Sim</IonSelectOption>
                  </Field>
                  <If test={tried === "S" ? true : false}>
                    <Field
                      name="how_tried"
                      component={Textarea}
                      label="Tentou Solucionar de que Forma?"
                      rows={3}
                    />
                  </If>
                  <Field
                    name="fault"
                    component={Textarea}
                    label="Defeito Apresentado"
                    rows={3}
                  />
                  <Field
                    name="cause"
                    component={Textarea}
                    label="Causa Provável"
                    rows={3}
                  />
                </IonRow>
                <IonRow className="ion-margin-top">
                  <IonCol size="6">
                    <IonButton type="submit" color="primary" expand="block">
                      Salvar
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </IonCardContent>
        </IonCard>
        {list.map((values) => {
          return (
            <IonCard key={values.id}>
              <IonCardHeader>
                <IonCardTitle>Máquina: {values.number}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonGrid className="ion-align-items-center">
                  <IonRow>
                    <IonCol size="12">
                      <IonLabel>Data: </IonLabel>
                      <IonText>{dateToString(values.date)}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>Status: </IonLabel>
                      <IonText>
                        {machineStatusConverter({ value: values.status })}
                      </IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>Urgência: </IonLabel>
                      <IonText>{values.urgency}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>Tipo: </IonLabel>
                      <IonText>
                        {machineTypeConverter({ value: values.type })}
                      </IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>Tentou Solucionar? </IonLabel>
                      <IonText>{values.tried === "S" ? "Sim" : "Não"}</IonText>
                    </IonCol>
                    <If test={values.tried === "S" ? true : false}>
                      <IonCol size="12">
                        <IonLabel>Tentou Solucionar de que Forma? </IonLabel>
                        <IonText>{values.how_tried}</IonText>
                      </IonCol>
                    </If>
                    <IonCol size="12">
                      <IonLabel>Defeito Apresentado: </IonLabel>
                      <IonText>{values.fault}</IonText>
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>Causa Provável: </IonLabel>
                      <IonText>{values.cause}</IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
          );
        })}
      </Main>
    );
  };

const MachineSOForm = reduxForm<MachineOrderType, Props>({
  form: "machine_so",
})(MachineSO);

const selector = formValueSelector("machine_so");

const mapStateToProps = (state: RootState) => ({
  list: state.machineOrder.list,
  tried: selector(state, "tried"),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getList, getMachine, create }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MachineSOForm);
