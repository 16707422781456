interface Props {
  value?: "N" | "T";
  upper?: Boolean;
}

export default function (props: Props) {
  if (props.value === "N") {
    if (props.upper === true) {
      return "NOVO";
    } else {
      return "Novo";
    }
  } else if (props.value === "T") {
    if (props.upper === true) {
      return "TROCA";
    } else {
      return "Troca";
    }
  } else {
    return "";
  }
}
