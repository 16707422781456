import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import Moment from "moment";
import {
  IonCard,
  IonCardContent,
  IonRow,
  IonCol,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonText,
} from "@ionic/react";

import { takeOn } from "../../../store/technician/assistance_take_on/actions";
import { AssistanceTakeOn as AssistancesTakeOnType } from "../../../store/technician/assistance_take_on/types";

import dateToString from "../../../helpers/Legacy/dateToString";

interface DispatchProps {
  takeOn(id: AssistancesTakeOnType["id"]): void;
}

interface OwnProps {
  assistance: AssistancesTakeOnType;
}
type Props = DispatchProps & OwnProps;

const AssistancesTakeOnCard: React.FC<Props> = ({ takeOn, assistance }) => {
  const [time, setTime] = useState("00:00:00");

  useEffect(() => {
    function timeOut() {
      const endDateTime = Moment(`${assistance.date} ${assistance.start}`).add(
        assistance.time,
        "hours"
      );
      const nowDateTime = Moment();

      var pad = function (num: number, size: number) {
        return ("000" + num).slice(size * -1);
      };

      const time = endDateTime.diff(nowDateTime, "seconds");

      if (time > 0) {
        const hours = Math.floor(time / 60 / 60);
        const minutes = Math.floor(time / 60) % 60;
        const seconds = Math.floor(time - minutes * 60);

        setTime(`${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`);
      } else {
        setTime("00:00:00");
      }
    }
    timeOut();
    const interval = setInterval(timeOut, 10000);
    return () => clearInterval(interval);
  }, [assistance]);

  return (
    <IonCard className={`card-${assistance.situation?.toLowerCase()}`}>
      <IonCardHeader>
        <IonCardTitle>Tempo Restante: {time}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonRow>
          <IonCol size="12">
            <IonLabel>Situação: </IonLabel>
            <IonText>{assistance.situation}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Paciente: </IonLabel>
            <IonText>{assistance.patient}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Hospital: </IonLabel>
            <IonText>{assistance.hospital}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Data: </IonLabel>
            <IonText>{dateToString(assistance.date)}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Hora de Início: </IonLabel>
            <IonText>{assistance.start}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonLabel>Médico: </IonLabel>
            <IonText>{assistance.doctor}</IonText>
          </IonCol>
        </IonRow>
        <IonRow className="ion-margin-top">
          <IonCol size="12">
            <IonButton
              type="button"
              color="primary"
              expand="block"
              onClick={() => takeOn(assistance.id)}
            >
              Assumir
            </IonButton>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ takeOn }, dispatch);

export default connect(null, mapDispatchToProps)(AssistancesTakeOnCard);
