import axios, { AxiosRequestConfig } from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const sessionName = process.env.REACT_APP_SESSION_NAME;

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const token = localStorage.getItem(`@${sessionName}:token`);
  if (!!token) {
    config.headers!.Authorization = `${token}`;
  }

  return config;
});

export default api;
