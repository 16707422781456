import React, { useCallback, useEffect, useState } from "react";
import { IonCol, IonLabel } from "@ionic/react";
import Select, { Props as SelectProps } from "react-select";

import api from "../../../services/api";

interface Props {
  size?: string;
  label: string;
  type: string;
  value: SelectProps;
  onChange(value: SelectProps): void;
}

const SearchInput: React.FC<Props> = ({
  size,
  label,
  value,
  type,
  onChange,
  ...rest
}) => {
  const [machines, setMachines] = useState([]);

  const searchMachines = useCallback(
    (searchValue?: string | null) => {
      api
        .get<any>("search/machine_by_number", {
          params: { search: searchValue, type: type },
        })
        .then((resp) => {
          setMachines(resp.data);
        });
    },
    [type]
  );

  useEffect(() => {
    searchMachines();
  }, [searchMachines]);

  return (
    <IonCol size={size || "12"}>
      <IonLabel position="stacked">{label}</IonLabel>
      <Select
        style={{ width: "100%" }}
        placeholder=""
        classNamePrefix="select"
        value={value}
        onChange={(value) => {
          if (value) {
            onChange(value);
          } else {
            onChange({} as SelectProps);
          }
        }}
        onInputChange={(value) => searchMachines(value)}
        noOptionsMessage={() => "Nenhuma opção"}
        simpleValue
        options={machines}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        {...rest}
      />
    </IonCol>
  );
};

export default SearchInput;
