import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/react";
import { getList, clearList } from "../../../store/nurse/scheduled/actions";

import Main from "../../../components/Main";
import DateInput from "../../../components/Legacy/Form/DateInput";

import List from "./list";

function Assistance_Single(props) {
  const [from, setFrom] = useState(
    localStorage.getItem("di_app_as_from") || ""
  );
  const [to, setTo] = useState(localStorage.getItem("di_app_as_to") || "");

  useEffect(() => {
    const { clearList } = props;
    clearList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleConsult(e) {
    e.preventDefault();
    const { getList } = props;
    getList(from, to);
  }

  return (
    <Main title="Agendados">
      <IonCard>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <DateInput
                label="De"
                input={{
                  value: from,
                  onChange: (value) => {
                    setFrom(value.split("T")[0]);
                    localStorage.setItem("di_app_as_from", value.split("T")[0]);
                  },
                }}
              />
              <DateInput
                label="Até"
                input={{
                  value: to,
                  onChange: (value) => {
                    setTo(value.split("T")[0]);
                    localStorage.setItem("di_app_as_to", value.split("T")[0]);
                  },
                }}
              />
            </IonRow>
            <IonRow className="ion-margin-top">
              <IonCol size="6">
                <IonButton
                  type="button"
                  color="primary"
                  expand="block"
                  onClick={(e) => handleConsult(e)}
                >
                  Consultar
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>

      <List />
    </Main>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getList, clearList }, dispatch);
export default connect(null, mapDispatchToProps)(Assistance_Single);
